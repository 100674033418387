import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  File: any;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any;
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: any;
  /**
   * The `Time` scalar type represents a Time value as
   * specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Time: any;
  /**
   * Create scalar that ignores normal serialization/deserialization, since
   * that will be handled by the multipart request spec
   */
  Upload: any;
};

export type AccountsInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  cellphone?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export type AccountsUser = Node & {
  __typename?: 'AccountsUser';
  /** The ID of the object. */
  id: Scalars['ID'];
  userId?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
  allowedCompanies?: Maybe<Array<Maybe<Company>>>;
  allowedBrands?: Maybe<Array<Maybe<Brand>>>;
  allowedCompaniesByBrand?: Maybe<Array<Maybe<Company>>>;
};

export type AccountsUserAllowedCompaniesByBrandArgs = {
  brandId: Scalars['Int'];
};

export type AdditionalChargeParameter = Node & {
  __typename?: 'AdditionalChargeParameter';
  optional?: Maybe<Scalars['Boolean']>;
  orderTypeParameterId?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  additionalPriceType?: Maybe<AdditionalChargePriceTypeEnum>;
  additionalValue?: Maybe<Scalars['Float']>;
  additionalType?: Maybe<AdditionalChargeTypeEnum>;
  /** The ID of the object. */
  id: Scalars['ID'];
};

export enum AdditionalChargePriceTypeEnum {
  Fixed = 'FIXED',
  Percentage = 'PERCENTAGE',
}

export enum AdditionalChargeTypeEnum {
  Delivery = 'DELIVERY',
  Package = 'PACKAGE',
  Other = 'OTHER',
}

export type Address = Node & {
  __typename?: 'Address';
  /** The ID of the object. */
  id: Scalars['ID'];
  street?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  geoLat?: Maybe<Scalars['Float']>;
  geoLon?: Maybe<Scalars['Float']>;
};

export type AddressComponentInput = {
  long_name: Scalars['String'];
  short_name: Scalars['String'];
  types: Array<Scalars['String']>;
};

export type AddressFields = {
  __typename?: 'AddressFields';
  street: Scalars['String'];
  number: Scalars['String'];
  district: Scalars['String'];
  city: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type AddressFieldsInput = {
  street: Scalars['String'];
  number: Scalars['String'];
  district: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  zipCode?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  street: Scalars['String'];
  number: Scalars['String'];
  complement?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
  country: Scalars['String'];
  geoLat: Scalars['Float'];
  geoLon: Scalars['Float'];
};

export enum AggregatorFilterOption {
  DeliveryArea = 'DELIVERY_AREA',
}

export enum AuthUserType {
  Crm = 'CRM',
}

export type BackendMenuDeleteProduct = {
  __typename?: 'BackendMenuDeleteProduct';
  success?: Maybe<Scalars['Boolean']>;
};

export type BackendMenuDeleteProductPictures = {
  __typename?: 'BackendMenuDeleteProductPictures';
  success?: Maybe<Scalars['Boolean']>;
};

export type BackendMenuReorderProduct = {
  __typename?: 'BackendMenuReorderProduct';
  success?: Maybe<Scalars['Boolean']>;
};

export type BackendMenuUpsertChoosablesInput = {
  name: Scalars['String'];
  productId?: Maybe<Scalars['Int']>;
  minimumChoices: Scalars['Int'];
  maximumChoices: Scalars['Int'];
  simples?: Maybe<Array<Maybe<BackendMenuUpsertSimplesInput>>>;
};

export type BackendMenuUpsertItem = {
  __typename?: 'BackendMenuUpsertItem';
  category?: Maybe<Category>;
  product?: Maybe<Product>;
  productCompany?: Maybe<ProductCompany>;
};

export type BackendMenuUpsertItemInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  category: Scalars['String'];
  productId?: Maybe<Scalars['Int']>;
  categoryId?: Maybe<Scalars['Int']>;
  choosables?: Maybe<Array<Maybe<BackendMenuUpsertChoosablesInput>>>;
};

export type BackendMenuUpsertSimplesInput = {
  name: Scalars['String'];
  productId?: Maybe<Scalars['Int']>;
  minimumChoices: Scalars['Int'];
  maximumChoices: Scalars['Int'];
  price?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Int']>;
};

export type BackendSubproductRemoveItem = {
  __typename?: 'BackendSubproductRemoveItem';
  success?: Maybe<Scalars['Boolean']>;
};

export type Balance = {
  __typename?: 'Balance';
  brand?: Maybe<Brand>;
  points?: Maybe<Scalars['Int']>;
};

export type BalanceConnection = {
  __typename?: 'BalanceConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BalanceEdge>>;
};

/** A Relay edge containing a `Balance` and its cursor. */
export type BalanceEdge = {
  __typename?: 'BalanceEdge';
  /** The item at the end of the edge */
  node?: Maybe<Balance>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type BalanceUser = {
  __typename?: 'BalanceUser';
  id: Scalars['ID'];
  numericalId: Scalars['Int'];
  firstName: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['Int']>;
  validationCellphone?: Maybe<Scalars['String']>;
  document: Array<Maybe<BalanceUserDocument>>;
};

export type BalanceUserDocument = {
  __typename?: 'BalanceUserDocument';
  id: Scalars['ID'];
  numericalId: Scalars['Int'];
  documentType: Scalars['Int'];
  data: Scalars['String'];
};

export type Brand = Node & {
  __typename?: 'Brand';
  brandTheme?: Maybe<BrandThemeConnection>;
  category?: Maybe<CategoryConnection>;
  company?: Maybe<CompanySharedConnection>;
  /** The ID of the object. */
  id: Scalars['ID'];
  images?: Maybe<Array<Maybe<Image>>>;
  layout?: Maybe<Scalars['String']>;
  menuCategory?: Maybe<MenuCategoryConnection>;
  menuProduct?: Maybe<MenuProductConnection>;
  name?: Maybe<Scalars['String']>;
  numericalId?: Maybe<Scalars['Int']>;
  products?: Maybe<ProductConnection>;
  promotions?: Maybe<PromotionConnection>;
  reference?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  timeRule?: Maybe<TimeRuleConnection>;
};

export type BrandBrandThemeArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type BrandCategoryArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type BrandCompanyArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type BrandMenuCategoryArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type BrandMenuProductArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type BrandProductsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type BrandPromotionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type BrandTimeRuleArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type BrandBalance = {
  __typename?: 'BrandBalance';
  id: Scalars['ID'];
  expiringDate?: Maybe<Scalars['String']>;
  expiringPoints: Scalars['Int'];
  balancePoints: Scalars['Int'];
  user: BalanceUser;
  reference: Scalars['String'];
};

export type BrandConnection = {
  __typename?: 'BrandConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BrandEdge>>;
};

/** A Relay edge containing a `Brand` and its cursor. */
export type BrandEdge = {
  __typename?: 'BrandEdge';
  /** The item at the end of the edge */
  node?: Maybe<Brand>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum BrandSortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  LayoutAsc = 'LAYOUT_ASC',
  LayoutDesc = 'LAYOUT_DESC',
}

export type BrandTheme = Node & {
  __typename?: 'BrandTheme';
  /** The ID of the object. */
  id: Scalars['ID'];
  brandThemeColor?: Maybe<Array<Maybe<BrandThemeColor>>>;
  brandThemeMessage?: Maybe<Array<Maybe<BrandThemeMessage>>>;
  numericalId?: Maybe<Scalars['Int']>;
};

export type BrandThemeColor = Node & {
  __typename?: 'BrandThemeColor';
  /** The ID of the object. */
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type BrandThemeConnection = {
  __typename?: 'BrandThemeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BrandThemeEdge>>;
};

/** A Relay edge containing a `BrandTheme` and its cursor. */
export type BrandThemeEdge = {
  __typename?: 'BrandThemeEdge';
  /** The item at the end of the edge */
  node?: Maybe<BrandTheme>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type BrandThemeMessage = Node & {
  __typename?: 'BrandThemeMessage';
  /** The ID of the object. */
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CalendarTimeRule = Node & {
  __typename?: 'CalendarTimeRule';
  /** The ID of the object. */
  id: Scalars['ID'];
  timeRuleId?: Maybe<Scalars['Int']>;
  dayFrom?: Maybe<Scalars['String']>;
  dayTo?: Maybe<Scalars['String']>;
  timeFrom?: Maybe<Scalars['String']>;
  timeTo?: Maybe<Scalars['String']>;
  timeRule?: Maybe<TimeRule>;
};

export type CalendarTimeRuleConnection = {
  __typename?: 'CalendarTimeRuleConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CalendarTimeRuleEdge>>;
};

/** A Relay edge containing a `CalendarTimeRule` and its cursor. */
export type CalendarTimeRuleEdge = {
  __typename?: 'CalendarTimeRuleEdge';
  /** The item at the end of the edge */
  node?: Maybe<CalendarTimeRule>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Card = Node & {
  __typename?: 'Card';
  /** The ID of the object. */
  id: Scalars['ID'];
  printedName?: Maybe<Scalars['String']>;
  expiration?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  pinValidated?: Maybe<Scalars['Boolean']>;
  maskedNumber?: Maybe<Scalars['String']>;
  singleUse?: Maybe<Scalars['Boolean']>;
  cardBrandId?: Maybe<Scalars['Int']>;
  customerId?: Maybe<Scalars['Int']>;
  cardBrand?: Maybe<CardBrand>;
  customer?: Maybe<Customer>;
};

export type CardBrand = Node & {
  __typename?: 'CardBrand';
  /** The ID of the object. */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  card?: Maybe<CardConnection>;
};

export type CardBrandCardArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CardConnection = {
  __typename?: 'CardConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CardEdge>>;
};

/** A Relay edge containing a `Card` and its cursor. */
export type CardEdge = {
  __typename?: 'CardEdge';
  /** The item at the end of the edge */
  node?: Maybe<Card>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CartPaymentFlow = {
  __typename?: 'CartPaymentFlow';
  status: CartPaymentStatus;
  cardBrand?: Maybe<Scalars['String']>;
};

export enum CartPaymentStatus {
  RegisterCard = 'REGISTER_CARD',
  CartPayment = 'CART_PAYMENT',
  SelectPayment = 'SELECT_PAYMENT',
}

export type Category = Node & {
  __typename?: 'Category';
  /** The ID of the object. */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  menuCategory?: Maybe<MenuCategoryConnection>;
  products?: Maybe<ProductConnection>;
  numericalId?: Maybe<Scalars['Int']>;
};

export type CategoryMenuCategoryArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CategoryProductsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CategoryConnection = {
  __typename?: 'CategoryConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CategoryEdge>>;
};

/** A Relay edge containing a `Category` and its cursor. */
export type CategoryEdge = {
  __typename?: 'CategoryEdge';
  /** The item at the end of the edge */
  node?: Maybe<Category>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum CategorySortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  OrderAsc = 'ORDER_ASC',
  OrderDesc = 'ORDER_DESC',
}

export type ChangeProductQuantity = {
  productCacheId: Scalars['String'];
  parentCacheId?: Maybe<Scalars['String']>;
  menuItemCacheId?: Maybe<Scalars['String']>;
};

export type ChoosableInput = {
  productName: Scalars['String'];
  productPosName?: Maybe<Scalars['String']>;
  productPosReference?: Maybe<Scalars['String']>;
  productOperator: Scalars['String'];
  productSequence: Scalars['Int'];
  productCompanyPrice?: Maybe<Scalars['Float']>;
  productCompanyPosPrice?: Maybe<Scalars['Float']>;
  productShortDescription?: Maybe<Scalars['String']>;
  productFullDescription?: Maybe<Scalars['String']>;
  productCategoryId?: Maybe<Scalars['Int']>;
  productCategoryName?: Maybe<Scalars['String']>;
  productImageUrl?: Maybe<Scalars['String']>;
  productMinimumChoices: Scalars['Int'];
  productMaximumChoices: Scalars['Int'];
  simples: Array<Maybe<SimpleInput>>;
};

export type CompaniesUpdateOnlineOfflineBatch = {
  __typename?: 'CompaniesUpdateOnlineOfflineBatch';
  success?: Maybe<Scalars['Boolean']>;
};

export type CompaniesUpdateOnlineOfflineBatchInput = {
  id: Scalars['Int'];
  unavailable: Scalars['Boolean'];
};

export type Company = Node & {
  __typename?: 'Company';
  /** The ID of the object. */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  unavailable?: Maybe<Scalars['Boolean']>;
  brandId?: Maybe<Scalars['Int']>;
  documentId?: Maybe<Scalars['Int']>;
  addressId?: Maybe<Scalars['Int']>;
  corporateName?: Maybe<Scalars['String']>;
  menuId?: Maybe<Scalars['Int']>;
  isDigitalMenu?: Maybe<Scalars['Boolean']>;
  categoryId?: Maybe<Scalars['Int']>;
  isLgpdCompliance?: Maybe<Scalars['Boolean']>;
  isFullService?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  brand?: Maybe<Brand>;
  document?: Maybe<Document>;
  openingTimes?: Maybe<TimeRule>;
  address?: Maybe<Address>;
  category?: Maybe<CompanyCategory>;
  companyPhone?: Maybe<CompanyPhone>;
  menuProductCompany?: Maybe<MenuProductCompanyConnection>;
  productCompany?: Maybe<ProductCompanyConnection>;
  numericalId?: Maybe<Scalars['Int']>;
  onlineStatus?: Maybe<OnlineStatus>;
  highlight?: Maybe<CompanyHighlight>;
  images?: Maybe<Array<Maybe<Image>>>;
  parameter?: Maybe<Parameter>;
  preparationTime?: Maybe<PreparationTime>;
  aggregator?: Maybe<CompanyGroup>;
  shippingPrice?: Maybe<ShippingPrice>;
};

export type CompanyMenuProductCompanyArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CompanyProductCompanyArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CompanyShippingPriceArgs = {
  deliveryAddress: DeliveryAddressInput;
};

export type CompanyCategory = Node & {
  __typename?: 'CompanyCategory';
  /** The ID of the object. */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
};

export type CompanyConnection = {
  __typename?: 'CompanyConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CompanyEdge>>;
};

export enum CompanyDemandStatus {
  Unknown = 'UNKNOWN',
  Normal = 'NORMAL',
  High = 'HIGH',
  Blocked = 'BLOCKED',
}

/** A Relay edge containing a `Company` and its cursor. */
export type CompanyEdge = {
  __typename?: 'CompanyEdge';
  /** The item at the end of the edge */
  node?: Maybe<Company>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CompanyGroup = Node & {
  __typename?: 'CompanyGroup';
  /** The ID of the object. */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  addressId?: Maybe<Scalars['Int']>;
  isShoppingCenter?: Maybe<Scalars['Boolean']>;
  isEvent?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Address>;
  numericalId?: Maybe<Scalars['Int']>;
  companies?: Maybe<CompanySharedConnection>;
  images?: Maybe<Array<Maybe<Image>>>;
};

export type CompanyGroupCompaniesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CompanyGroupConnection = {
  __typename?: 'CompanyGroupConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CompanyGroupEdge>>;
};

/** A Relay edge containing a `CompanyGroup` and its cursor. */
export type CompanyGroupEdge = {
  __typename?: 'CompanyGroupEdge';
  /** The item at the end of the edge */
  node?: Maybe<CompanyGroup>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CompanyHighlight = {
  __typename?: 'CompanyHighlight';
  numericalId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  topLabel?: Maybe<Scalars['String']>;
  centerLabel?: Maybe<Scalars['String']>;
  bottomLabel?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
};

export type CompanyPhone = Node & {
  __typename?: 'CompanyPhone';
  /** The ID of the object. */
  id: Scalars['ID'];
  number: Scalars['String'];
};

export type CompanySharedConnection = {
  __typename?: 'CompanySharedConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CompanySharedEdge>>;
};

/** A Relay edge containing a `CompanyShared` and its cursor. */
export type CompanySharedEdge = {
  __typename?: 'CompanySharedEdge';
  /** The item at the end of the edge */
  node?: Maybe<Company>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum CompanySortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  DisplayNameAsc = 'DISPLAY_NAME_ASC',
  DisplayNameDesc = 'DISPLAY_NAME_DESC',
  HiddenAsc = 'HIDDEN_ASC',
  HiddenDesc = 'HIDDEN_DESC',
  UnavailableAsc = 'UNAVAILABLE_ASC',
  UnavailableDesc = 'UNAVAILABLE_DESC',
  BrandIdAsc = 'BRAND_ID_ASC',
  BrandIdDesc = 'BRAND_ID_DESC',
  DocumentIdAsc = 'DOCUMENT_ID_ASC',
  DocumentIdDesc = 'DOCUMENT_ID_DESC',
  AddressIdAsc = 'ADDRESS_ID_ASC',
  AddressIdDesc = 'ADDRESS_ID_DESC',
  CorporateNameAsc = 'CORPORATE_NAME_ASC',
  CorporateNameDesc = 'CORPORATE_NAME_DESC',
  MenuIdAsc = 'MENU_ID_ASC',
  MenuIdDesc = 'MENU_ID_DESC',
  IsDigitalMenuAsc = 'IS_DIGITAL_MENU_ASC',
  IsDigitalMenuDesc = 'IS_DIGITAL_MENU_DESC',
  CategoryIdAsc = 'CATEGORY_ID_ASC',
  CategoryIdDesc = 'CATEGORY_ID_DESC',
  IsLgpdComplianceAsc = 'IS_LGPD_COMPLIANCE_ASC',
  IsLgpdComplianceDesc = 'IS_LGPD_COMPLIANCE_DESC',
  IsFullServiceAsc = 'IS_FULL_SERVICE_ASC',
  IsFullServiceDesc = 'IS_FULL_SERVICE_DESC',
}

export type CompanyWeekdayTimeRules = {
  __typename?: 'CompanyWeekdayTimeRules';
  success?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['String']>;
};

export type CompanyWeekdayTimeRulesBatch = {
  __typename?: 'CompanyWeekdayTimeRulesBatch';
  success?: Maybe<Scalars['Boolean']>;
};

export type CompanyWeekdayTimeRulesInput = {
  timeFrom: Scalars['Time'];
  timeTo: Scalars['Time'];
  numericalId?: Maybe<Scalars['Int']>;
  day: Scalars['Int'];
  operation: CompanyWeekdayTimeRulesOperation;
};

export enum CompanyWeekdayTimeRulesOperation {
  Update = 'UPDATE',
  Create = 'CREATE',
  Delete = 'DELETE',
}

export type CompanyWeekdayTimeRulesScheduleInput = {
  companyId: Scalars['Int'];
  schedule: Array<Maybe<TimeRulesScheduleInput>>;
};

export type CostumerDocument = Node & {
  __typename?: 'CostumerDocument';
  /** The ID of the object. */
  id: Scalars['ID'];
  customerId?: Maybe<Scalars['Int']>;
  documentId?: Maybe<Scalars['Int']>;
  customer?: Maybe<Customer>;
  document?: Maybe<Document>;
};

export type CostumerDocumentConnection = {
  __typename?: 'CostumerDocumentConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CostumerDocumentEdge>>;
};

/** A Relay edge containing a `CostumerDocument` and its cursor. */
export type CostumerDocumentEdge = {
  __typename?: 'CostumerDocumentEdge';
  /** The item at the end of the edge */
  node?: Maybe<CostumerDocument>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Coupon = {
  __typename?: 'Coupon';
  code?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
  expiration?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  brand?: Maybe<Brand>;
};

export type CouponsList = {
  __typename?: 'CouponsList';
  telephoneValidated: Scalars['Boolean'];
  loading: Scalars['Boolean'];
  nodes?: Maybe<Array<Maybe<Coupon>>>;
};

export type CreateAccountsUser = {
  __typename?: 'CreateAccountsUser';
  data?: Maybe<Customer>;
};

export type CreateMenuBatch = {
  __typename?: 'CreateMenuBatch';
  menuItems?: Maybe<Array<Maybe<ProductCompany>>>;
};

export type CroppedPictureInput = {
  cropHeight: Scalars['Int'];
  cropWidth: Scalars['Int'];
  cropX: Scalars['Int'];
  cropY: Scalars['Int'];
};

export type Customer = Node & {
  __typename?: 'Customer';
  /** The ID of the object. */
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  validationCode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  preferencesToken?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  userPtrId: Scalars['ID'];
  telephone?: Maybe<Scalars['String']>;
  validationCellphone?: Maybe<Scalars['String']>;
  facebookToken?: Maybe<Scalars['String']>;
  googleplusToken?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['Int']>;
  loginAttemptCount?: Maybe<Scalars['Int']>;
  accountsUser?: Maybe<AccountsUser>;
  card?: Maybe<CardConnection>;
  order?: Maybe<OrderConnection>;
  customerDocumentSet?: Maybe<Array<Maybe<CostumerDocument>>>;
  balance?: Maybe<BalanceConnection>;
  rewards?: Maybe<RewardConnection>;
};

export type CustomerCardArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CustomerOrderArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CustomerBalanceArgs = {
  brandId?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CustomerRewardsArgs = {
  brandId?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CustomerData = {
  __typename?: 'CustomerData';
  id: Scalars['ID'];
  numericalId: Scalars['Int'];
  name: Scalars['String'];
  email: Scalars['String'];
  cpf?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['Int']>;
  telephone?: Maybe<Scalars['String']>;
  acceptedTermsDatetime?: Maybe<Scalars['String']>;
  validationCellphone?: Maybe<Scalars['String']>;
};

export type CustomerDataInput = {
  cpf?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['Int']>;
  telephone?: Maybe<Scalars['String']>;
};

export type CustomerEngagementSignUp = {
  __typename?: 'CustomerEngagementSignUp';
  success?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['String']>;
};

export type DeleteBrandCroppedPicture = {
  __typename?: 'DeleteBrandCroppedPicture';
  error?: Maybe<Scalars['String']>;
  updatedBrand?: Maybe<Brand>;
};

export type DeleteCompanyCroppedPicture = {
  __typename?: 'DeleteCompanyCroppedPicture';
  error?: Maybe<Scalars['String']>;
  updatedCompany?: Maybe<Company>;
};

export type DeliveryAddressInput = {
  street: Scalars['String'];
  number: Scalars['String'];
  complement?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
  country: Scalars['String'];
  geoLat: Scalars['Float'];
  geoLon: Scalars['Float'];
  district: Scalars['String'];
};

export type DeviceMetadataInput = {
  appName: Scalars['String'];
  appVersion: Scalars['String'];
  isEmbedded: Scalars['Boolean'];
  osName: Scalars['String'];
  osVersion: Scalars['String'];
  deviceId: Scalars['String'];
  deficeToken?: Maybe<Scalars['String']>;
  screenSizeX: Scalars['Int'];
  screenSizeY: Scalars['Int'];
};

export type DigitalMenuRegisterMutation = {
  __typename?: 'DigitalMenuRegisterMutation';
  token?: Maybe<Scalars['String']>;
};

export type DiscountFormInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  shoppingName: Scalars['String'];
  workplaceType: WorkplaceType;
  workplaceName: Scalars['String'];
  cpf: Scalars['String'];
  inputFile: Scalars['File'];
};

export type Document = Node & {
  __typename?: 'Document';
  /** The ID of the object. */
  id: Scalars['ID'];
  documentType?: Maybe<DocumentType>;
  data?: Maybe<Scalars['String']>;
  expedition?: Maybe<Scalars['String']>;
  expires?: Maybe<Scalars['String']>;
  issuer?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  customerDocumentSet?: Maybe<CostumerDocumentConnection>;
};

export type DocumentCustomerDocumentSetArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DocumentType {
  Cpf = 'CPF',
  Cnpj = 'CNPJ',
  Identity = 'IDENTITY',
}

export type Error = {
  __typename?: 'Error';
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ExceptionTimeRule = Node & {
  __typename?: 'ExceptionTimeRule';
  /** The ID of the object. */
  id: Scalars['ID'];
  timeRuleId?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
  timeRule?: Maybe<TimeRule>;
};

export type ExceptionTimeRuleConnection = {
  __typename?: 'ExceptionTimeRuleConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ExceptionTimeRuleEdge>>;
};

/** A Relay edge containing a `ExceptionTimeRule` and its cursor. */
export type ExceptionTimeRuleEdge = {
  __typename?: 'ExceptionTimeRuleEdge';
  /** The item at the end of the edge */
  node?: Maybe<ExceptionTimeRule>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Image = {
  __typename?: 'Image';
  context?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  cropId?: Maybe<Scalars['Int']>;
  cropX?: Maybe<Scalars['Int']>;
  cropY?: Maybe<Scalars['Int']>;
  cropWidth?: Maybe<Scalars['Int']>;
  cropHeight?: Maybe<Scalars['Int']>;
  originalImageUrl?: Maybe<Scalars['String']>;
  originalImageWidth?: Maybe<Scalars['Int']>;
  originalImageHeight?: Maybe<Scalars['Int']>;
  originalImageId?: Maybe<Scalars['Int']>;
};

export type LocalCard = {
  __typename?: 'LocalCard';
  id: Scalars['ID'];
  cardNumber: Scalars['String'];
  cardName: Scalars['String'];
  cardExpiration: Scalars['String'];
  birthday: Scalars['String'];
  cardCvv: Scalars['String'];
  cardBrand: Scalars['String'];
  paymentMethod: Scalars['String'];
};

export type LocalCardInput = {
  cardNumber: Scalars['String'];
  cardName: Scalars['String'];
  cardExpiration: Scalars['String'];
  birthday: Scalars['String'];
  cardCvv: Scalars['String'];
  cardBrand: Scalars['String'];
  paymentMethod: Scalars['String'];
};

export type LoginAuthInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  userType: AuthUserType;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type LoginSocialInput = {
  email: Scalars['String'];
  token: Scalars['String'];
  name: Scalars['String'];
};

export type LoginTokenAuth = {
  __typename?: 'LoginTokenAuth';
  accessToken?: Maybe<Scalars['String']>;
};

export type MagicLinkCreateInput = {
  email: Scalars['String'];
};

export type MagicLinkLoginInput = {
  email: Scalars['String'];
  token: Scalars['Int'];
};

export type MagicLinkResult = {
  __typename?: 'MagicLinkResult';
  success: Scalars['Boolean'];
  alreadyExist: Scalars['Boolean'];
  errorMsg?: Maybe<Scalars['String']>;
};

export type MenuCsv = {
  __typename?: 'MenuCSV';
  valid?: Maybe<Scalars['Boolean']>;
  data?: Maybe<Scalars['GenericScalar']>;
};

export type MenuCategory = Node & {
  __typename?: 'MenuCategory';
  /** The ID of the object. */
  id: Scalars['ID'];
  brandId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  deleted?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  backendCategoryId?: Maybe<Scalars['Int']>;
  publishStatus?: Maybe<MenuPublishStatus>;
  slug?: Maybe<Scalars['String']>;
  brand?: Maybe<Brand>;
  backendCategory?: Maybe<Category>;
  menuProduct?: Maybe<MenuProductConnection>;
  numericalId?: Maybe<Scalars['Int']>;
};

export type MenuCategoryMenuProductArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type MenuCategoryConnection = {
  __typename?: 'MenuCategoryConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MenuCategoryEdge>>;
};

/** A Relay edge containing a `MenuCategory` and its cursor. */
export type MenuCategoryEdge = {
  __typename?: 'MenuCategoryEdge';
  /** The item at the end of the edge */
  node?: Maybe<MenuCategory>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum MenuCategorySortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  BrandIdAsc = 'BRAND_ID_ASC',
  BrandIdDesc = 'BRAND_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  OrderAsc = 'ORDER_ASC',
  OrderDesc = 'ORDER_DESC',
  DeletedAsc = 'DELETED_ASC',
  DeletedDesc = 'DELETED_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  BackendCategoryIdAsc = 'BACKEND_CATEGORY_ID_ASC',
  BackendCategoryIdDesc = 'BACKEND_CATEGORY_ID_DESC',
  PublishStatusAsc = 'PUBLISH_STATUS_ASC',
  PublishStatusDesc = 'PUBLISH_STATUS_DESC',
}

export type MenuCreateOrUpdateItem = {
  __typename?: 'MenuCreateOrUpdateItem';
  menuCategory?: Maybe<MenuCategory>;
  menuProduct?: Maybe<MenuProduct>;
  menuProductCompany?: Maybe<MenuProductCompany>;
};

export type MenuCreateOrUpdateItemInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  category: Scalars['String'];
  productId?: Maybe<Scalars['Int']>;
  categoryId?: Maybe<Scalars['Int']>;
};

export type MenuDeleteItem = {
  __typename?: 'MenuDeleteItem';
  menuCategory?: Maybe<MenuCategory>;
  menuProduct?: Maybe<MenuProduct>;
  menuProductCompany?: Maybe<MenuProductCompany>;
};

export type MenuProduct = Node & {
  __typename?: 'MenuProduct';
  /** The ID of the object. */
  id: Scalars['ID'];
  brandId?: Maybe<Scalars['Int']>;
  categoryId?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  fullDescription?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['Int']>;
  hidden?: Maybe<Scalars['Boolean']>;
  deleted?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  backendProductId?: Maybe<Scalars['Int']>;
  publishStatus?: Maybe<MenuPublishStatus>;
  brand?: Maybe<Brand>;
  category?: Maybe<MenuCategory>;
  backendProduct?: Maybe<Product>;
  menuProductCompany?: Maybe<MenuProductCompanyConnection>;
  numericalId?: Maybe<Scalars['Int']>;
};

export type MenuProductMenuProductCompanyArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type MenuProductCompany = Node & {
  __typename?: 'MenuProductCompany';
  /** The ID of the object. */
  id: Scalars['ID'];
  productId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  hidden?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['Float']>;
  unavailable?: Maybe<Scalars['Boolean']>;
  deleted?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  backendProductCompanyId?: Maybe<Scalars['Int']>;
  publishStatus?: Maybe<MenuPublishStatus>;
  product?: Maybe<MenuProduct>;
  company?: Maybe<Company>;
  backendProductCompany?: Maybe<ProductCompany>;
  numericalId?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type MenuProductCompanyConnection = {
  __typename?: 'MenuProductCompanyConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MenuProductCompanyEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `MenuProductCompany` and its cursor. */
export type MenuProductCompanyEdge = {
  __typename?: 'MenuProductCompanyEdge';
  /** The item at the end of the edge */
  node?: Maybe<MenuProductCompany>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum MenuProductCompanySortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ProductIdAsc = 'PRODUCT_ID_ASC',
  ProductIdDesc = 'PRODUCT_ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  HiddenAsc = 'HIDDEN_ASC',
  HiddenDesc = 'HIDDEN_DESC',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  UnavailableAsc = 'UNAVAILABLE_ASC',
  UnavailableDesc = 'UNAVAILABLE_DESC',
  DeletedAsc = 'DELETED_ASC',
  DeletedDesc = 'DELETED_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  BackendProductCompanyIdAsc = 'BACKEND_PRODUCT_COMPANY_ID_ASC',
  BackendProductCompanyIdDesc = 'BACKEND_PRODUCT_COMPANY_ID_DESC',
  PublishStatusAsc = 'PUBLISH_STATUS_ASC',
  PublishStatusDesc = 'PUBLISH_STATUS_DESC',
}

export type MenuProductConnection = {
  __typename?: 'MenuProductConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MenuProductEdge>>;
};

/** A Relay edge containing a `MenuProduct` and its cursor. */
export type MenuProductEdge = {
  __typename?: 'MenuProductEdge';
  /** The item at the end of the edge */
  node?: Maybe<MenuProduct>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type MenuPublish = {
  __typename?: 'MenuPublish';
  valid?: Maybe<Scalars['Boolean']>;
};

export enum MenuPublishStatus {
  Pending = 'PENDING',
  Publishing = 'PUBLISHING',
  Published = 'PUBLISHED',
}

export type MetabaseResourceUrl = {
  __typename?: 'MetabaseResourceUrl';
  url?: Maybe<Scalars['String']>;
};

export type MetabaseResourceUrlInput = {
  resourceName: Scalars['String'];
  resourceId: Scalars['Int'];
  companies?: Maybe<Scalars['String']>;
  brands?: Maybe<Scalars['String']>;
};

export type MiniAppLoginInput = {
  name: Scalars['String'];
  document: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
};

export type Mutations = {
  __typename?: 'Mutations';
  acceptTermsAndConditions: Result;
  addLocalCard?: Maybe<Scalars['Boolean']>;
  addOfflinePayment?: Maybe<Scalars['Boolean']>;
  addToCart?: Maybe<Scalars['Boolean']>;
  backendMenuDeleteProduct?: Maybe<BackendMenuDeleteProduct>;
  backendMenuDeleteProductPictures?: Maybe<BackendMenuDeleteProductPictures>;
  backendMenuReorderProduct?: Maybe<BackendMenuReorderProduct>;
  backendMenuUpsertItem?: Maybe<BackendMenuUpsertItem>;
  backendSubproductRemoveItem?: Maybe<BackendSubproductRemoveItem>;
  clearDeprecatedCache?: Maybe<Scalars['Boolean']>;
  companiesUpdateOnlineOfflineBatch?: Maybe<CompaniesUpdateOnlineOfflineBatch>;
  companyWeekdayTimeRules?: Maybe<CompanyWeekdayTimeRules>;
  companyWeekdayTimeRulesBatch?: Maybe<CompanyWeekdayTimeRulesBatch>;
  createAccountsUser?: Maybe<CreateAccountsUser>;
  createMenuBatch?: Maybe<CreateMenuBatch>;
  createNewShoppingCart?: Maybe<Scalars['Boolean']>;
  customerEngagementSignUp?: Maybe<CustomerEngagementSignUp>;
  decreaseChosenProductQuantity?: Maybe<Scalars['Boolean']>;
  deleteBrandCroppedPicture?: Maybe<DeleteBrandCroppedPicture>;
  deleteCompanyCroppedPicture?: Maybe<DeleteCompanyCroppedPicture>;
  deviceMetadata: Scalars['String'];
  flipChosenProductQuantity?: Maybe<Scalars['Boolean']>;
  getOrLoadSelectedPaymentMethod?: Maybe<PaymentMethod>;
  increaseChosenProductQuantity?: Maybe<Scalars['Boolean']>;
  loadSelectedOrderTypeFromBrowserCache?: Maybe<Scalars['Boolean']>;
  loadShoppingCartsFromBrowserCache?: Maybe<Scalars['Boolean']>;
  loadUserAddressFromBrowserCache?: Maybe<Scalars['Boolean']>;
  login?: Maybe<Result>;
  loginSocial?: Maybe<Result>;
  loginTokenAuth?: Maybe<LoginTokenAuth>;
  logout?: Maybe<Scalars['Boolean']>;
  magicLinkCreate: MagicLinkResult;
  magicLinkLogin: Result;
  menuCreateOrUpdateItem?: Maybe<MenuCreateOrUpdateItem>;
  menuCsv?: Maybe<MenuCsv>;
  menuDeleteItem?: Maybe<MenuDeleteItem>;
  menuPublish?: Maybe<MenuPublish>;
  metabaseResourceUrl?: Maybe<MetabaseResourceUrl>;
  miniAppLogin?: Maybe<Result>;
  node?: Maybe<Node>;
  orderCheckRequest: Result;
  orderPostRequest: OrderPostResult;
  partnerLogin?: Maybe<Result>;
  preOrderPostValidation: Result;
  refreshAccessToken?: Maybe<RefreshAccessToken>;
  removeFromCart?: Maybe<Scalars['Boolean']>;
  removeLocalCard?: Maybe<Scalars['Boolean']>;
  removeRemoteCard?: Maybe<Scalars['Boolean']>;
  resetAccountsPassword?: Maybe<ResetPassword>;
  resetPassword: Result;
  resetShoppingCart?: Maybe<Scalars['Boolean']>;
  saveBrandPictures?: Maybe<SaveBrandPictures>;
  saveCompanyPictures?: Maybe<SaveCompanyPictures>;
  saveShoppingCartToBrowserCache?: Maybe<Scalars['Boolean']>;
  selectCard?: Maybe<Scalars['Boolean']>;
  selectWallet?: Maybe<Scalars['Boolean']>;
  selectedOrderType: SelectedOrderType;
  selfJourneyRegister?: Maybe<DigitalMenuRegisterMutation>;
  sendDiscountForm: Result;
  setGmapsReady: Scalars['Boolean'];
  setScrollToProduct?: Maybe<Scalars['Boolean']>;
  signUp?: Maybe<Result>;
  updateBrandCroppedPicture?: Maybe<UpdateBrandCroppedPicture>;
  updateCompanyCroppedPicture?: Maybe<UpdateCompanyCroppedPicture>;
  updateCustomerData?: Maybe<CustomerData>;
  updateSelectedTab: Scalars['Int'];
  userAddress: UserAddress;
  validateProduct?: Maybe<Scalars['Boolean']>;
};

export type MutationsAddLocalCardArgs = {
  localCard: LocalCardInput;
};

export type MutationsAddOfflinePaymentArgs = {
  cardBrand: Scalars['String'];
  cardName: Scalars['String'];
};

export type MutationsAddToCartArgs = {
  companyId: Scalars['Int'];
  productCacheId: Scalars['String'];
  productQuantity: Scalars['Int'];
};

export type MutationsBackendMenuDeleteProductArgs = {
  brandId: Scalars['Int'];
  companyId: Scalars['Int'];
  productCompanyId: Scalars['Int'];
};

export type MutationsBackendMenuDeleteProductPicturesArgs = {
  brandId: Scalars['Int'];
  companyId: Scalars['Int'];
  croppedPictureIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  productId: Scalars['Int'];
};

export type MutationsBackendMenuReorderProductArgs = {
  brandId: Scalars['Int'];
  companyId: Scalars['Int'];
  productId: Scalars['Int'];
  sequence: Scalars['Int'];
};

export type MutationsBackendMenuUpsertItemArgs = {
  backendMenuUpsertItemInput: BackendMenuUpsertItemInput;
  brandId: Scalars['Int'];
  companyId: Scalars['Int'];
  imageFile?: Maybe<Scalars['Upload']>;
  imageInput?: Maybe<CroppedPictureInput>;
};

export type MutationsBackendSubproductRemoveItemArgs = {
  brandId: Scalars['Int'];
  companyId: Scalars['Int'];
  productId: Scalars['Int'];
  subproductId: Scalars['Int'];
};

export type MutationsCompaniesUpdateOnlineOfflineBatchArgs = {
  brandId: Scalars['Int'];
  companiesItems: Array<Maybe<CompaniesUpdateOnlineOfflineBatchInput>>;
};

export type MutationsCompanyWeekdayTimeRulesArgs = {
  brandId: Scalars['Int'];
  companyId: Scalars['Int'];
  input: Array<Maybe<CompanyWeekdayTimeRulesInput>>;
};

export type MutationsCompanyWeekdayTimeRulesBatchArgs = {
  brandId: Scalars['Int'];
  schedules: Array<Maybe<CompanyWeekdayTimeRulesScheduleInput>>;
};

export type MutationsCreateAccountsUserArgs = {
  cellphone?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
};

export type MutationsCreateMenuBatchArgs = {
  brandId: Scalars['Int'];
  companiesIds: Array<Maybe<Scalars['Int']>>;
  isOpenDeliveryIntegration?: Maybe<Scalars['Boolean']>;
  menu: Array<Maybe<ProductBatchInput>>;
};

export type MutationsCreateNewShoppingCartArgs = {
  companyId: Scalars['Int'];
};

export type MutationsCustomerEngagementSignUpArgs = {
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
};

export type MutationsDecreaseChosenProductQuantityArgs = {
  input: ChangeProductQuantity;
};

export type MutationsDeleteBrandCroppedPictureArgs = {
  brand: Scalars['Int'];
  id: Scalars['Int'];
};

export type MutationsDeleteCompanyCroppedPictureArgs = {
  company: Scalars['Int'];
  id: Scalars['Int'];
};

export type MutationsDeviceMetadataArgs = {
  input: DeviceMetadataInput;
};

export type MutationsFlipChosenProductQuantityArgs = {
  input: ChangeProductQuantity;
};

export type MutationsGetOrLoadSelectedPaymentMethodArgs = {
  companyId?: Maybe<Scalars['Int']>;
};

export type MutationsIncreaseChosenProductQuantityArgs = {
  input: ChangeProductQuantity;
};

export type MutationsLoadShoppingCartsFromBrowserCacheArgs = {
  cacheName?: Maybe<Scalars['String']>;
};

export type MutationsLoadUserAddressFromBrowserCacheArgs = {
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type MutationsLoginArgs = {
  input: LoginInput;
};

export type MutationsLoginSocialArgs = {
  input: LoginSocialInput;
};

export type MutationsLoginTokenAuthArgs = {
  input: LoginAuthInput;
};

export type MutationsMagicLinkCreateArgs = {
  input: MagicLinkCreateInput;
};

export type MutationsMagicLinkLoginArgs = {
  input: MagicLinkLoginInput;
};

export type MutationsMenuCreateOrUpdateItemArgs = {
  brandId: Scalars['Int'];
  companyId: Scalars['Int'];
  menuCreateOrUpdateItemInput: MenuCreateOrUpdateItemInput;
};

export type MutationsMenuCsvArgs = {
  brandId: Scalars['Int'];
  companyId: Scalars['Int'];
  file: Scalars['Upload'];
};

export type MutationsMenuDeleteItemArgs = {
  brandId: Scalars['Int'];
  companyId: Scalars['Int'];
  menuCategoryId?: Maybe<Scalars['Int']>;
  menuProductId?: Maybe<Scalars['Int']>;
};

export type MutationsMenuPublishArgs = {
  companyId: Scalars['Int'];
};

export type MutationsMetabaseResourceUrlArgs = {
  input: MetabaseResourceUrlInput;
};

export type MutationsMiniAppLoginArgs = {
  input: MiniAppLoginInput;
};

export type MutationsNodeArgs = {
  id: Scalars['ID'];
};

export type MutationsOrderCheckRequestArgs = {
  companyId: Scalars['Int'];
  orderTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MutationsOrderPostRequestArgs = {
  input: OrderPostInput;
};

export type MutationsPartnerLoginArgs = {
  token: Scalars['String'];
};

export type MutationsPreOrderPostValidationArgs = {
  companyId: Scalars['Int'];
};

export type MutationsRemoveFromCartArgs = {
  companyId: Scalars['Int'];
  productCacheId: Scalars['String'];
};

export type MutationsRemoveRemoteCardArgs = {
  cardId: Scalars['Int'];
};

export type MutationsResetAccountsPasswordArgs = {
  email: Scalars['String'];
};

export type MutationsResetPasswordArgs = {
  email: Scalars['String'];
};

export type MutationsResetShoppingCartArgs = {
  companyId: Scalars['Int'];
};

export type MutationsSaveBrandPicturesArgs = {
  brand: Scalars['Int'];
  files: Array<Maybe<Scalars['Upload']>>;
  input: Array<Maybe<SavePicturesInput>>;
};

export type MutationsSaveCompanyPicturesArgs = {
  company: Scalars['Int'];
  files: Array<Maybe<Scalars['Upload']>>;
  input: Array<Maybe<SavePicturesInput>>;
};

export type MutationsSaveShoppingCartToBrowserCacheArgs = {
  companyId: Scalars['Int'];
};

export type MutationsSelectCardArgs = {
  remoteCard?: Maybe<RemoteCardInput>;
  localCard?: Maybe<LocalCardInput>;
};

export type MutationsSelectWalletArgs = {
  cardBrand: Scalars['String'];
};

export type MutationsSelectedOrderTypeArgs = {
  input: SelectedOrderTypeInput;
};

export type MutationsSelfJourneyRegisterArgs = {
  selfJourneyData: SelfJourneyInput;
};

export type MutationsSendDiscountFormArgs = {
  input: DiscountFormInput;
};

export type MutationsSetGmapsReadyArgs = {
  ready: Scalars['Boolean'];
};

export type MutationsSetScrollToProductArgs = {
  productId: Scalars['String'];
  value: Scalars['Boolean'];
};

export type MutationsSignUpArgs = {
  input: SignUpInput;
};

export type MutationsUpdateBrandCroppedPictureArgs = {
  brand: Scalars['Int'];
  id: Scalars['Int'];
  input: UpdateCroppedPictureInput;
};

export type MutationsUpdateCompanyCroppedPictureArgs = {
  company: Scalars['Int'];
  id: Scalars['Int'];
  input: UpdateCroppedPictureInput;
};

export type MutationsUpdateCustomerDataArgs = {
  input: CustomerDataInput;
};

export type MutationsUpdateSelectedTabArgs = {
  selectedTab: Scalars['Int'];
};

export type MutationsUserAddressArgs = {
  input: UserAddressInput;
};

export type MutationsValidateProductArgs = {
  productCacheId: Scalars['String'];
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type Notify = {
  __typename?: 'Notify';
  status: Scalars['String'];
  message: Scalars['String'];
  active: Scalars['Boolean'];
};

export type OfflinePayment = {
  __typename?: 'OfflinePayment';
  id: Scalars['ID'];
  cardBrand: Scalars['String'];
  cardName: Scalars['String'];
};

export enum OnlineStatus {
  Online = 'ONLINE',
  Closed = 'CLOSED',
  Offline = 'OFFLINE',
}

export type Order = Node & {
  __typename?: 'Order';
  /** The ID of the object. */
  id: Scalars['ID'];
  companyId?: Maybe<Scalars['Int']>;
  ownerId?: Maybe<Scalars['Int']>;
  originBrandId?: Maybe<Scalars['Int']>;
  addressId?: Maybe<Scalars['Int']>;
  retryId?: Maybe<Scalars['Int']>;
  ownerUserAgentInfo?: Maybe<Scalars['String']>;
  orderType?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  posImportStatus?: Maybe<Scalars['Int']>;
  lastStatusReceivedMobile?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['Int']>;
  posStatus?: Maybe<Scalars['Int']>;
  posStatusDatetime?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<Scalars['Int']>;
  antifraudStatus?: Maybe<Scalars['Int']>;
  antifraudStatusDatetime?: Maybe<Scalars['DateTime']>;
  canceled?: Maybe<Scalars['Boolean']>;
  canceledDatetime?: Maybe<Scalars['DateTime']>;
  paymentAuthorizedDatetime?: Maybe<Scalars['DateTime']>;
  posReceivedDatetime?: Maybe<Scalars['DateTime']>;
  posAcceptedDatetime?: Maybe<Scalars['DateTime']>;
  posDeniedDatetime?: Maybe<Scalars['DateTime']>;
  readyDatetime?: Maybe<Scalars['DateTime']>;
  dispatchedDatetime?: Maybe<Scalars['DateTime']>;
  deliveredDatetime?: Maybe<Scalars['DateTime']>;
  receivedDatetime?: Maybe<Scalars['DateTime']>;
  consumedDatetime?: Maybe<Scalars['DateTime']>;
  posImportedDatetime?: Maybe<Scalars['DateTime']>;
  posImportErrorDatetime?: Maybe<Scalars['DateTime']>;
  preparingDatetime?: Maybe<Scalars['DateTime']>;
  posAnalysingDatetime?: Maybe<Scalars['DateTime']>;
  productUnavailableDatetime?: Maybe<Scalars['DateTime']>;
  customerActionNeededDatetime?: Maybe<Scalars['DateTime']>;
  printed?: Maybe<Scalars['Boolean']>;
  printedDatetime?: Maybe<Scalars['DateTime']>;
  creationDatetime?: Maybe<Scalars['DateTime']>;
  lastMobileUpdateDatetime?: Maybe<Scalars['DateTime']>;
  lastUpdateDatetime?: Maybe<Scalars['DateTime']>;
  prepareRightaway?: Maybe<Scalars['Boolean']>;
  ownedLoyaltyPoints?: Maybe<Scalars['Int']>;
  usedLoyaltyPoints?: Maybe<Scalars['Int']>;
  printId?: Maybe<Scalars['String']>;
  tableReference?: Maybe<Scalars['String']>;
  barCode?: Maybe<Scalars['String']>;
  displayCode?: Maybe<Scalars['String']>;
  receiptMessage?: Maybe<Scalars['String']>;
  totalValue?: Maybe<Scalars['Float']>;
  payableValue?: Maybe<Scalars['Float']>;
  discountPercentual?: Maybe<Scalars['Float']>;
  discountValue?: Maybe<Scalars['Float']>;
  subsidyValue?: Maybe<Scalars['Float']>;
  observation?: Maybe<Scalars['String']>;
  sequenceNumber?: Maybe<Scalars['String']>;
  taxPercentual?: Maybe<Scalars['Float']>;
  taxValue?: Maybe<Scalars['Float']>;
  serviceChargePercentual?: Maybe<Scalars['Float']>;
  serviceChargeValue?: Maybe<Scalars['Float']>;
  hasRedeem?: Maybe<Scalars['Boolean']>;
  preparationTimeMinimum?: Maybe<Scalars['Int']>;
  preparationTimeMaximum?: Maybe<Scalars['Int']>;
  isMock?: Maybe<Scalars['Boolean']>;
  received?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Company>;
  owner?: Maybe<Customer>;
  originBrand?: Maybe<Brand>;
  address?: Maybe<Address>;
  orderExtras?: Maybe<OrderExtraConnection>;
  items?: Maybe<Array<Maybe<OrderItem>>>;
  payments?: Maybe<Array<Maybe<Payment>>>;
  orderExtra?: Maybe<Array<Maybe<OrderExtra>>>;
};

export type OrderOrderExtrasArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type OrderCheckInput = {
  companyId: Scalars['Int'];
  orderType: Scalars['String'];
  extras?: Maybe<Array<Maybe<OrderExtraInput>>>;
  items: Array<Maybe<OrderItemInput>>;
};

export type OrderCheckResponse = {
  __typename?: 'OrderCheckResponse';
  id: Scalars['ID'];
  minimumPreparationTime?: Maybe<Scalars['Int']>;
  maximumPreparationTime?: Maybe<Scalars['Int']>;
  warning?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
  extras?: Maybe<Array<Maybe<OrderExtra>>>;
};

export type OrderConnection = {
  __typename?: 'OrderConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OrderEdge>>;
};

/** A Relay edge containing a `Order` and its cursor. */
export type OrderEdge = {
  __typename?: 'OrderEdge';
  /** The item at the end of the edge */
  node?: Maybe<Order>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type OrderExtra = Node & {
  __typename?: 'OrderExtra';
  /** The ID of the object. */
  id: Scalars['ID'];
  key: Scalars['String'];
  label: Scalars['String'];
  optional: Scalars['Boolean'];
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars['Int']>;
  value: Scalars['Float'];
};

export type OrderExtraConnection = {
  __typename?: 'OrderExtraConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OrderExtraEdge>>;
};

/** A Relay edge containing a `OrderExtra` and its cursor. */
export type OrderExtraEdge = {
  __typename?: 'OrderExtraEdge';
  /** The item at the end of the edge */
  node?: Maybe<OrderExtra>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type OrderExtraInput = {
  key: Scalars['String'];
  value: Scalars['Float'];
};

export type OrderField = Node & {
  __typename?: 'OrderField';
  /** The ID of the object. */
  id: Scalars['ID'];
  mapping?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  fieldType?: Maybe<OrderFieldType>;
  title?: Maybe<Scalars['String']>;
  requiredErrorMessage?: Maybe<Scalars['String']>;
  invalidErrorMessage?: Maybe<Scalars['String']>;
  hint?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  maxLength?: Maybe<Scalars['Int']>;
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  numericalId?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
};

export type OrderFieldParameter = Node & {
  __typename?: 'OrderFieldParameter';
  /** The ID of the object. */
  id: Scalars['ID'];
  orderTypeParameterId?: Maybe<Scalars['Int']>;
  fieldId?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  field?: Maybe<OrderField>;
};

export enum OrderFieldType {
  Text = 'TEXT',
  Numeric = 'NUMERIC',
  Telephone = 'TELEPHONE',
  Cpf = 'CPF',
  OptionList = 'OPTION_LIST',
}

export type OrderItem = Node & {
  __typename?: 'OrderItem';
  /** The ID of the object. */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  unitPosPrice?: Maybe<Scalars['Float']>;
  productType?: Maybe<ProductType>;
  registerDatetime?: Maybe<Scalars['DateTime']>;
  orderId?: Maybe<Scalars['Int']>;
  parentId?: Maybe<Scalars['Int']>;
  redeemId?: Maybe<Scalars['Int']>;
  posParentId?: Maybe<Scalars['Int']>;
  itemId?: Maybe<Scalars['Int']>;
  productCompanyItemId?: Maybe<Scalars['Int']>;
  billItemId?: Maybe<Scalars['JSONString']>;
  orderPosition?: Maybe<Scalars['Int']>;
  draggedBy?: Maybe<Scalars['Int']>;
  isPackage?: Maybe<Scalars['Boolean']>;
  combo?: Maybe<Scalars['Boolean']>;
  isNote?: Maybe<Scalars['Boolean']>;
  offer?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  posName?: Maybe<Scalars['String']>;
  posReference?: Maybe<Scalars['String']>;
  itemPosReference?: Maybe<Scalars['String']>;
  campaign?: Maybe<Scalars['String']>;
  observation?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  baseUnitPrice?: Maybe<Scalars['Float']>;
  baseUnitPosPrice?: Maybe<Scalars['Float']>;
  subsidy?: Maybe<Scalars['Float']>;
  unitPoints?: Maybe<Scalars['Int']>;
  partition?: Maybe<Scalars['Int']>;
  prepaidRewardPoints?: Maybe<Scalars['Int']>;
  numericalId?: Maybe<Scalars['Int']>;
};

export type OrderItemInput = {
  isRedeem: Scalars['Boolean'];
  product: Scalars['Int'];
  quantity: Scalars['String'];
  unitPrice?: Maybe<Scalars['Float']>;
  items?: Maybe<Array<Maybe<OrderItemInput>>>;
};

export type OrderItems = {
  __typename?: 'OrderItems';
  product?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['String']>;
};

export type OrderPostInput = {
  brandId: Scalars['Int'];
  companyId: Scalars['Int'];
  cvv?: Maybe<Scalars['String']>;
};

export type OrderPostResult = {
  __typename?: 'OrderPostResult';
  success: Scalars['Boolean'];
  orderId?: Maybe<Scalars['Int']>;
  errorMsg?: Maybe<Scalars['String']>;
};

export type OrderRemoteResponse = {
  __typename?: 'OrderRemoteResponse';
  id: Scalars['String'];
  status: Scalars['String'];
  orderType?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  displayCode?: Maybe<Scalars['String']>;
  creationDatetime?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<OrderItems>>>;
};

export type OrderStatus = {
  __typename?: 'OrderStatus';
  id: Scalars['ID'];
  receiptMessage?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  numericalId?: Maybe<Scalars['Int']>;
  displayCode?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  shouldRequestCardNumber?: Maybe<Scalars['Boolean']>;
  errorMsg?: Maybe<Scalars['String']>;
  preparationTimeMinimum?: Maybe<Scalars['Int']>;
  preparationTimeMaximum?: Maybe<Scalars['Int']>;
  creationDatetime?: Maybe<Scalars['String']>;
};

export enum OrderStatusCode {
  Canceled = 'CANCELED',
  BackendReceived = 'BACKEND_RECEIVED',
  PaymentAuthorized = 'PAYMENT_AUTHORIZED',
  PosReceived = 'POS_RECEIVED',
  PosAccepted = 'POS_ACCEPTED',
  PosDenied = 'POS_DENIED',
  Ready = 'READY',
  Dispatched = 'DISPATCHED',
  Delivered = 'DELIVERED',
  Received = 'RECEIVED',
  Consumed = 'CONSUMED',
  PosImported = 'POS_IMPORTED',
  PosImportError = 'POS_IMPORT_ERROR',
  Preparing = 'PREPARING',
  PosAnalysing = 'POS_ANALYSING',
  ProductUnavailable = 'PRODUCT_UNAVAILABLE',
  CustomerActionNeeded = 'CUSTOMER_ACTION_NEEDED',
}

export type OrderStatusInput = {
  orderId: Scalars['Int'];
  brandId: Scalars['Int'];
  missingCardCvv?: Maybe<Scalars['String']>;
  missingCardNumber?: Maybe<Scalars['String']>;
};

export type OrderTypeFieldValue = {
  position: Scalars['Int'];
  label: Scalars['String'];
  value: Scalars['String'];
};

export enum OrderTypeFilterOptionEnum {
  Counter = 'COUNTER',
  ToGo = 'TO_GO',
  Curbside = 'CURBSIDE',
  Table = 'TABLE',
  Delivery = 'DELIVERY',
  Card = 'CARD',
  Payment = 'PAYMENT',
  Coupon = 'COUPON',
  RoomService = 'ROOM_SERVICE',
  PrepaidPoints = 'PREPAID_POINTS',
  TruckStop = 'TRUCK_STOP',
  Event = 'EVENT',
}

export type OrderTypeParameter = Node & {
  __typename?: 'OrderTypeParameter';
  /** The ID of the object. */
  id: Scalars['ID'];
  parameterId?: Maybe<Scalars['Int']>;
  additionalPreparationTime?: Maybe<Scalars['Int']>;
  orderType?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  numericalId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  additionalChargeParameter?: Maybe<Array<Maybe<AdditionalChargeParameter>>>;
  orderFields?: Maybe<Array<Maybe<OrderFieldParameter>>>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

export type Parameter = Node & {
  __typename?: 'Parameter';
  /** The ID of the object. */
  id: Scalars['ID'];
  companyGroupId?: Maybe<Scalars['Int']>;
  barcodeEnabled?: Maybe<Scalars['Boolean']>;
  mailOrderCancellation?: Maybe<Scalars['String']>;
  mailReplyTo?: Maybe<Scalars['String']>;
  productChangeDestination?: Maybe<Scalars['String']>;
  mailSenderName?: Maybe<Scalars['String']>;
  mailOrderConfirmation?: Maybe<Scalars['String']>;
  mockOrderEmail?: Maybe<Scalars['String']>;
  feedbackCommentsDestination?: Maybe<Scalars['String']>;
  orderDisplaycodeRule?: Maybe<Scalars['String']>;
  orderDisplaycodeNumber?: Maybe<Scalars['Int']>;
  redeemPointsAutomatic?: Maybe<Scalars['Boolean']>;
  allowRewardByProduct?: Maybe<Scalars['Boolean']>;
  allowRedeemNoChoosable?: Maybe<Scalars['Boolean']>;
  allowFirstPaymentInStore?: Maybe<Scalars['Boolean']>;
  rewardByCpf?: Maybe<Scalars['Boolean']>;
  rewardFeedbackListValue?: Maybe<Scalars['Int']>;
  orderActive?: Maybe<Scalars['Boolean']>;
  paymentActive?: Maybe<Scalars['Boolean']>;
  invitationListActive?: Maybe<Scalars['Boolean']>;
  posBillOnlineTimeout?: Maybe<Scalars['JSONString']>;
  posGeneralOnlineTimeout?: Maybe<Scalars['JSONString']>;
  posOrderOnlineTimeout?: Maybe<Scalars['JSONString']>;
  posTimeout?: Maybe<Scalars['Int']>;
  paymentIntegrationTimeout?: Maybe<Scalars['Int']>;
  productsCacheMaxAge?: Maybe<Scalars['JSONString']>;
  aggregatorCacheMaxAge?: Maybe<Scalars['JSONString']>;
  antifraudActive?: Maybe<Scalars['Boolean']>;
  allowCreditRewards?: Maybe<Scalars['Boolean']>;
  onyoAffiliationBalanceThreshold?: Maybe<Scalars['Float']>;
  onyoAffiliationEnabled?: Maybe<Scalars['Boolean']>;
  loyaltyActive?: Maybe<Scalars['Boolean']>;
  mobileCard?: Maybe<Scalars['JSONString']>;
  mobileClaim?: Maybe<Scalars['JSONString']>;
  mobileFeedback?: Maybe<Scalars['JSONString']>;
  mobileHome?: Maybe<Scalars['JSONString']>;
  mobileModules?: Maybe<Scalars['JSONString']>;
  mobileOrder?: Maybe<Scalars['JSONString']>;
  mobileTutorial?: Maybe<Scalars['JSONString']>;
  paymentParameter?: Maybe<Array<Maybe<PaymentParameter>>>;
  numericalId?: Maybe<Scalars['Int']>;
  orderTypes?: Maybe<Array<Maybe<OrderTypeParameter>>>;
  offlinePaymentParameter?: Maybe<Array<Maybe<PaymentParameter>>>;
};

export type Payment = Node & {
  __typename?: 'Payment';
  /** The ID of the object. */
  id: Scalars['ID'];
  value?: Maybe<Scalars['Float']>;
  method?: Maybe<PaymentMethodEnum>;
  card?: Maybe<Card>;
};

export type PaymentMethod = LocalCard | RemoteCard | Wallet | OfflinePayment;

export enum PaymentMethodEnum {
  Card = 'CARD',
  Coupon = 'COUPON',
  Redeem = 'REDEEM',
  PrepaidRedeem = 'PREPAID_REDEEM',
  Wallet = 'WALLET',
  Offline = 'OFFLINE',
}

export type PaymentParameter = Node & {
  __typename?: 'PaymentParameter';
  /** The ID of the object. */
  id: Scalars['ID'];
  parameterId?: Maybe<Scalars['Int']>;
  cardBrandId?: Maybe<Scalars['Int']>;
  paymentMethod?: Maybe<Scalars['String']>;
  parameter?: Maybe<Parameter>;
  cardBrand?: Maybe<CardBrand>;
  requiresCpf?: Maybe<Scalars['Boolean']>;
};

export type Person = Customer | TemporaryUser;

export type PreparationTime = {
  __typename?: 'PreparationTime';
  minTime?: Maybe<Scalars['Int']>;
  maxTime?: Maybe<Scalars['Int']>;
  additionalTime?: Maybe<Scalars['Int']>;
  status?: Maybe<CompanyDemandStatus>;
};

export type Product = Node & {
  __typename?: 'Product';
  barcode?: Maybe<Scalars['String']>;
  brand?: Maybe<Brand>;
  brandId?: Maybe<Scalars['Int']>;
  category?: Maybe<Category>;
  categoryId?: Maybe<Scalars['Int']>;
  chosenQuantity: Scalars['Int'];
  feedbackable?: Maybe<Scalars['Boolean']>;
  fullDescription?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['Boolean']>;
  hasChoosableSubproducts: Scalars['Boolean'];
  hidden?: Maybe<Scalars['Boolean']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  images?: Maybe<Array<Maybe<Image>>>;
  isNote?: Maybe<Scalars['Boolean']>;
  isValid?: Maybe<ProductValidationStatus>;
  maximumChoices?: Maybe<Scalars['Int']>;
  menuProduct?: Maybe<MenuProductConnection>;
  minimumChoices?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  numericalId?: Maybe<Scalars['Int']>;
  offer?: Maybe<Scalars['Boolean']>;
  operator?: Maybe<Scalars['Int']>;
  posName?: Maybe<Scalars['String']>;
  posReference?: Maybe<Scalars['String']>;
  posUpdated?: Maybe<Scalars['Boolean']>;
  preparationTimeMaximum?: Maybe<Scalars['Int']>;
  preparationTimeMinimum?: Maybe<Scalars['Int']>;
  productCompany?: Maybe<ProductCompanyConnection>;
  productCompanyByCompanyId?: Maybe<ProductCompany>;
  productType?: Maybe<ProductType>;
  recommended?: Maybe<Scalars['Boolean']>;
  redeemable?: Maybe<Scalars['Boolean']>;
  rewardable?: Maybe<Scalars['Boolean']>;
  scrollToProduct: Scalars['Boolean'];
  sequence?: Maybe<Scalars['Int']>;
  shortDescription?: Maybe<Scalars['String']>;
  subproducts?: Maybe<Array<Maybe<Product>>>;
  timeRule?: Maybe<TimeRule>;
  timeRuleId?: Maybe<Scalars['Int']>;
  totalPrice?: Maybe<Scalars['Float']>;
};

export type ProductMenuProductArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ProductProductCompanyArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ProductBatchInput = {
  productName: Scalars['String'];
  productPosName?: Maybe<Scalars['String']>;
  productPosReference?: Maybe<Scalars['String']>;
  productOperator: Scalars['String'];
  productSequence: Scalars['Int'];
  productCompanyPrice?: Maybe<Scalars['Float']>;
  productCompanyPosPrice?: Maybe<Scalars['Float']>;
  productShortDescription?: Maybe<Scalars['String']>;
  productFullDescription?: Maybe<Scalars['String']>;
  productCategoryId?: Maybe<Scalars['Int']>;
  productCategoryName?: Maybe<Scalars['String']>;
  productImageUrl?: Maybe<Scalars['String']>;
  choosables?: Maybe<Array<Maybe<ChoosableInput>>>;
};

export type ProductCompany = Node & {
  __typename?: 'ProductCompany';
  children?: Maybe<ProductCompanyConnection>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  hidden?: Maybe<Scalars['Boolean']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  isMenuException?: Maybe<Scalars['Boolean']>;
  menuProductCompany?: Maybe<MenuProductCompanyConnection>;
  numericalId?: Maybe<Scalars['Int']>;
  path: Scalars['JSONString'];
  posPrice?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['Int']>;
  redeemPoints?: Maybe<Scalars['Int']>;
  rewardPoints?: Maybe<Scalars['Int']>;
  subsidy?: Maybe<Scalars['Float']>;
  totalCount?: Maybe<Scalars['Int']>;
  unavailable?: Maybe<Scalars['Boolean']>;
};

export type ProductCompanyMenuProductCompanyArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ProductCompanyConnection = {
  __typename?: 'ProductCompanyConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProductCompanyEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `ProductCompany` and its cursor. */
export type ProductCompanyEdge = {
  __typename?: 'ProductCompanyEdge';
  /** The item at the end of the edge */
  node?: Maybe<ProductCompany>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type ProductConnection = {
  __typename?: 'ProductConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProductEdge>>;
};

export type ProductDetailsDialog = {
  __typename?: 'ProductDetailsDialog';
  active: Scalars['Boolean'];
  product: Scalars['Int'];
};

/** A Relay edge containing a `Product` and its cursor. */
export type ProductEdge = {
  __typename?: 'ProductEdge';
  /** The item at the end of the edge */
  node?: Maybe<Product>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type ProductFilter = {
  __typename?: 'ProductFilter';
  query: Scalars['String'];
  active: Scalars['Boolean'];
};

/** An enumeration. */
export enum ProductSortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  BrandIdAsc = 'BRAND_ID_ASC',
  BrandIdDesc = 'BRAND_ID_DESC',
  CategoryIdAsc = 'CATEGORY_ID_ASC',
  CategoryIdDesc = 'CATEGORY_ID_DESC',
  TimeRuleIdAsc = 'TIME_RULE_ID_ASC',
  TimeRuleIdDesc = 'TIME_RULE_ID_DESC',
  ImagesAsc = 'IMAGES_ASC',
  ImagesDesc = 'IMAGES_DESC',
  SequenceAsc = 'SEQUENCE_ASC',
  SequenceDesc = 'SEQUENCE_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  ShortDescriptionAsc = 'SHORT_DESCRIPTION_ASC',
  ShortDescriptionDesc = 'SHORT_DESCRIPTION_DESC',
  FullDescriptionAsc = 'FULL_DESCRIPTION_ASC',
  FullDescriptionDesc = 'FULL_DESCRIPTION_DESC',
  PreparationTimeMinimumAsc = 'PREPARATION_TIME_MINIMUM_ASC',
  PreparationTimeMinimumDesc = 'PREPARATION_TIME_MINIMUM_DESC',
  PreparationTimeMaximumAsc = 'PREPARATION_TIME_MAXIMUM_ASC',
  PreparationTimeMaximumDesc = 'PREPARATION_TIME_MAXIMUM_DESC',
  RewardableAsc = 'REWARDABLE_ASC',
  RewardableDesc = 'REWARDABLE_DESC',
  RedeemableAsc = 'REDEEMABLE_ASC',
  RedeemableDesc = 'REDEEMABLE_DESC',
  MinimumChoicesAsc = 'MINIMUM_CHOICES_ASC',
  MinimumChoicesDesc = 'MINIMUM_CHOICES_DESC',
  MaximumChoicesAsc = 'MAXIMUM_CHOICES_ASC',
  MaximumChoicesDesc = 'MAXIMUM_CHOICES_DESC',
  OfferAsc = 'OFFER_ASC',
  OfferDesc = 'OFFER_DESC',
  ProductTypeAsc = 'PRODUCT_TYPE_ASC',
  ProductTypeDesc = 'PRODUCT_TYPE_DESC',
  HiddenAsc = 'HIDDEN_ASC',
  HiddenDesc = 'HIDDEN_DESC',
  RecommendedAsc = 'RECOMMENDED_ASC',
  RecommendedDesc = 'RECOMMENDED_DESC',
  BarcodeAsc = 'BARCODE_ASC',
  BarcodeDesc = 'BARCODE_DESC',
  FeedbackableAsc = 'FEEDBACKABLE_ASC',
  FeedbackableDesc = 'FEEDBACKABLE_DESC',
  PosUpdatedAsc = 'POS_UPDATED_ASC',
  PosUpdatedDesc = 'POS_UPDATED_DESC',
  GroupAsc = 'GROUP_ASC',
  GroupDesc = 'GROUP_DESC',
  IsNoteAsc = 'IS_NOTE_ASC',
  IsNoteDesc = 'IS_NOTE_DESC',
  PosNameAsc = 'POS_NAME_ASC',
  PosNameDesc = 'POS_NAME_DESC',
  PosReferenceAsc = 'POS_REFERENCE_ASC',
  PosReferenceDesc = 'POS_REFERENCE_DESC',
  OperatorAsc = 'OPERATOR_ASC',
  OperatorDesc = 'OPERATOR_DESC',
}

export enum ProductType {
  Simple = 'SIMPLE',
  Choosable = 'CHOOSABLE',
  MenuItem = 'MENU_ITEM',
}

export enum ProductValidationStatus {
  Unknown = 'UNKNOWN',
  Valid = 'VALID',
  Invalid = 'INVALID',
}

export type Promotion = Node & {
  __typename?: 'Promotion';
  /** The ID of the object. */
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Int']>;
  discountOnyo?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  restrict?: Maybe<Scalars['Boolean']>;
  singleUse?: Maybe<Scalars['Boolean']>;
  uniqueUse?: Maybe<Scalars['Boolean']>;
  applyToAllBrandCompanies?: Maybe<Scalars['Boolean']>;
  applyToAllCompaniesProducts?: Maybe<Scalars['Boolean']>;
  brandId?: Maybe<Scalars['Int']>;
  topLabel?: Maybe<Scalars['String']>;
  centerLabel?: Maybe<Scalars['String']>;
  bottomLabel?: Maybe<Scalars['String']>;
  brand?: Maybe<Brand>;
  title?: Maybe<Scalars['String']>;
  companies?: Maybe<CompanySharedConnection>;
};

export type PromotionCompaniesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PromotionConnection = {
  __typename?: 'PromotionConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PromotionEdge>>;
};

/** A Relay edge containing a `Promotion` and its cursor. */
export type PromotionEdge = {
  __typename?: 'PromotionEdge';
  /** The item at the end of the edge */
  node?: Maybe<Promotion>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  aggregator?: Maybe<CompanyGroup>;
  aggregators?: Maybe<CompanyGroupConnection>;
  allOrdersByDays?: Maybe<OrderConnection>;
  authExecuted: Scalars['Boolean'];
  brandBalance: BrandBalance;
  brandTheme?: Maybe<BrandTheme>;
  brands?: Maybe<Array<Maybe<Brand>>>;
  cards?: Maybe<Array<Maybe<RemoteCard>>>;
  cartPaymentFlow: CartPaymentFlow;
  company?: Maybe<Company>;
  coupons: CouponsList;
  crmUser?: Maybe<AccountsUser>;
  customerData?: Maybe<CustomerData>;
  defaultBrandTheme?: Maybe<BrandTheme>;
  deviceMetadata?: Maybe<Scalars['String']>;
  isAuthenticated: Scalars['Boolean'];
  isEmbedded: Scalars['Boolean'];
  isExecutingPaymentCall: Scalars['Boolean'];
  isGmapsReady: Scalars['Boolean'];
  lastOrders?: Maybe<OrderConnection>;
  listBrands?: Maybe<BrandConnection>;
  listCategories?: Maybe<CategoryConnection>;
  listCompanies?: Maybe<CompanyConnection>;
  listMenuCategories?: Maybe<MenuCategoryConnection>;
  listMenuCompanyProducts?: Maybe<MenuProductCompanyConnection>;
  listProductDetails?: Maybe<Array<Maybe<Product>>>;
  listProductsByBrand?: Maybe<ProductConnection>;
  localCard?: Maybe<LocalCard>;
  me?: Maybe<Person>;
  menuProductCompanyById?: Maybe<MenuProductCompany>;
  menuPublishState?: Maybe<MenuPublishStatus>;
  mobileParameter?: Maybe<Parameter>;
  node?: Maybe<Node>;
  notify: Notify;
  offlinePayment?: Maybe<OfflinePayment>;
  order?: Maybe<Order>;
  orderCheck?: Maybe<OrderCheckResponse>;
  orderStatus: OrderStatus;
  orders?: Maybe<Array<Maybe<OrderRemoteResponse>>>;
  productCompanies?: Maybe<ProductCompanyConnection>;
  productCompanyById?: Maybe<ProductCompany>;
  productDetails?: Maybe<Product>;
  productDetailsDialog: ProductDetailsDialog;
  productFilter: ProductFilter;
  selectedOrderType?: Maybe<SelectedOrderType>;
  selectedPaymentMethod?: Maybe<PaymentMethod>;
  selectedTab: Scalars['Int'];
  shoppingCart?: Maybe<ShoppingCart>;
  shoppingCartProducts: Array<ShoppingCartProduct>;
  shoppingCarts: Array<Maybe<ShoppingCart>>;
  userAddress?: Maybe<UserAddress>;
  userListPromotions?: Maybe<PromotionConnection>;
};

export type QueryAggregatorArgs = {
  companyGroupId?: Maybe<Scalars['Int']>;
  aggregatorId: Scalars['Int'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  maximumDistanceKilometers?: Maybe<Scalars['Float']>;
  filterBy?: Maybe<AggregatorFilterOption>;
  orderType?: Maybe<Array<Maybe<OrderTypeFilterOptionEnum>>>;
  companyNameStartsWith?: Maybe<Scalars['String']>;
};

export type QueryAggregatorsArgs = {
  companyGroupId?: Maybe<Scalars['Int']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  maximumDistanceKilometers: Scalars['Float'];
  filterBy?: Maybe<AggregatorFilterOption>;
  companyNameStartsWith?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryAllOrdersByDaysArgs = {
  days: Scalars['Int'];
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryBrandBalanceArgs = {
  brandId: Scalars['Int'];
};

export type QueryBrandThemeArgs = {
  companyId?: Maybe<Scalars['Int']>;
  brandId?: Maybe<Scalars['Int']>;
};

export type QueryBrandsArgs = {
  ids: Array<Maybe<Scalars['Int']>>;
};

export type QueryCardsArgs = {
  companyId?: Maybe<Scalars['Int']>;
};

export type QueryCompanyArgs = {
  companyId: Scalars['Int'];
  showHiddenCompany?: Maybe<Scalars['Boolean']>;
};

export type QueryLastOrdersArgs = {
  fromDatetime: Scalars['DateTime'];
  toDatetime?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Array<Maybe<OrderStatusCode>>>;
  brand?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryListBrandsArgs = {
  sort?: Maybe<Array<Maybe<BrandSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryListCategoriesArgs = {
  brandId: Scalars['Int'];
  sort?: Maybe<Array<Maybe<CategorySortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryListCompaniesArgs = {
  sort?: Maybe<Array<Maybe<CompanySortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryListMenuCategoriesArgs = {
  brandId: Scalars['Int'];
  sort?: Maybe<Array<Maybe<MenuCategorySortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryListMenuCompanyProductsArgs = {
  companyId: Scalars['Int'];
  productNameStartsWith?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<Maybe<MenuProductCompanySortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryListProductDetailsArgs = {
  productIds: Array<Maybe<Scalars['Int']>>;
  companyId: Scalars['Int'];
};

export type QueryListProductsByBrandArgs = {
  brandId: Scalars['Int'];
  productType?: Maybe<ProductType>;
  sort?: Maybe<Array<Maybe<ProductSortEnum>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryMenuProductCompanyByIdArgs = {
  id: Scalars['Int'];
  companyId: Scalars['Int'];
};

export type QueryMenuPublishStateArgs = {
  companyId: Scalars['Int'];
};

export type QueryMobileParameterArgs = {
  companyId: Scalars['Int'];
};

export type QueryNodeArgs = {
  id: Scalars['ID'];
};

export type QueryOrderArgs = {
  id: Scalars['Int'];
};

export type QueryOrderStatusArgs = {
  input: OrderStatusInput;
};

export type QueryProductCompaniesArgs = {
  companyId: Scalars['Int'];
  productType?: Maybe<ProductType>;
  productHidden?: Maybe<Scalars['Boolean']>;
  unavailable?: Maybe<Scalars['Boolean']>;
  productNameStartsWith?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryProductCompanyByIdArgs = {
  companyId: Scalars['Int'];
  id: Scalars['Int'];
};

export type QueryProductDetailsArgs = {
  productId: Scalars['Int'];
  companyId: Scalars['Int'];
};

export type QueryShoppingCartArgs = {
  companyId: Scalars['Int'];
};

export type QueryShoppingCartProductsArgs = {
  companyId: Scalars['Int'];
};

export type QueryUserListPromotionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type RefreshAccessToken = {
  __typename?: 'RefreshAccessToken';
  accessToken?: Maybe<Scalars['String']>;
};

export type RemoteCard = {
  __typename?: 'RemoteCard';
  id: Scalars['ID'];
  error?: Maybe<Scalars['String']>;
  numericalId: Scalars['String'];
  backendId: Scalars['String'];
  printedName: Scalars['String'];
  maskedNumber: Scalars['String'];
  maskedNumberFull: Scalars['String'];
  expiration: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  cardBrand: Scalars['String'];
};

export type RemoteCardInput = {
  backendId: Scalars['String'];
  cardBrand: Scalars['String'];
  expiration: Scalars['String'];
  id: Scalars['ID'];
  maskedNumberFull: Scalars['String'];
  numericalId: Scalars['String'];
  printedName: Scalars['String'];
};

export type ResetPassword = {
  __typename?: 'ResetPassword';
  success?: Maybe<Scalars['Boolean']>;
};

export type Result = {
  __typename?: 'Result';
  success: Scalars['Boolean'];
  errorMsg?: Maybe<Scalars['String']>;
};

export type Reward = Node & {
  __typename?: 'Reward';
  /** The ID of the object. */
  id: Scalars['ID'];
  customerId?: Maybe<Scalars['Int']>;
  temporaryUserId?: Maybe<Scalars['Int']>;
  orderId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  brandId?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Int']>;
  datetime?: Maybe<Scalars['DateTime']>;
  expiration?: Maybe<Scalars['DateTime']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  availablePoints?: Maybe<Scalars['Int']>;
  source?: Maybe<Scalars['String']>;
  temporaryUser?: Maybe<TemporaryUser>;
  brand?: Maybe<Brand>;
};

export type RewardConnection = {
  __typename?: 'RewardConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RewardEdge>>;
};

/** A Relay edge containing a `Reward` and its cursor. */
export type RewardEdge = {
  __typename?: 'RewardEdge';
  /** The item at the end of the edge */
  node?: Maybe<Reward>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type SaveBrandPictures = {
  __typename?: 'SaveBrandPictures';
  error?: Maybe<Scalars['String']>;
  updatedBrand?: Maybe<Brand>;
};

export type SaveCompanyPictures = {
  __typename?: 'SaveCompanyPictures';
  error?: Maybe<Scalars['String']>;
  updatedCompany?: Maybe<Company>;
};

export type SavePicturesInput = {
  cropHeight: Scalars['Int'];
  cropWidth: Scalars['Int'];
  cropX: Scalars['Int'];
  cropY: Scalars['Int'];
  context: Scalars['String'];
  cropId?: Maybe<Scalars['Int']>;
};

export type SelectOrderTypeInput = {
  companyId: Scalars['Int'];
  orderType: Scalars['String'];
  values: Array<Maybe<OrderTypeFieldValue>>;
};

export type SelectedOrderType = {
  __typename?: 'SelectedOrderType';
  id: Scalars['ID'];
  orderType: Scalars['String'];
  fieldValues?: Maybe<Array<Maybe<SelectedOrderTypeFieldValue>>>;
};

export type SelectedOrderTypeFieldValue = {
  __typename?: 'SelectedOrderTypeFieldValue';
  id: Scalars['ID'];
  numericalId: Scalars['Int'];
  position: Scalars['Int'];
  value: Scalars['String'];
  label: Scalars['String'];
};

export type SelectedOrderTypeFieldValueInput = {
  position: Scalars['Int'];
  numericalId: Scalars['Int'];
  value: Scalars['String'];
  label: Scalars['String'];
};

export type SelectedOrderTypeInput = {
  orderType: Scalars['String'];
  fieldValues?: Maybe<Array<Maybe<SelectedOrderTypeFieldValueInput>>>;
};

export type SelfJourneyInput = {
  accounts: AccountsInput;
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  corporateName: Scalars['String'];
  brandName: Scalars['String'];
  cnpj: Scalars['String'];
  telephone: Scalars['String'];
  address: AddressInput;
  brandImageInput?: Maybe<SavePicturesInput>;
  brandImageFile?: Maybe<Scalars['Upload']>;
};

export type ShippingPrice = {
  __typename?: 'ShippingPrice';
  value?: Maybe<Scalars['Float']>;
  label?: Maybe<Scalars['String']>;
};

export type ShoppingCart = {
  __typename?: 'ShoppingCart';
  id: Scalars['ID'];
  totalProductsPrice: Scalars['Float'];
  totalExtras: Scalars['Float'];
  orderTotal: Scalars['Float'];
  products: Array<Product>;
};

export type ShoppingCartProduct = {
  __typename?: 'ShoppingCartProduct';
  id: Scalars['ID'];
  name: Scalars['String'];
  numericalId: Scalars['Int'];
  totalPrice: Scalars['Float'];
  subproductsDescription: Scalars['String'];
};

export type SignUpInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  name: Scalars['String'];
};

export type SimpleInput = {
  productName: Scalars['String'];
  productPosName?: Maybe<Scalars['String']>;
  productPosReference?: Maybe<Scalars['String']>;
  productOperator: Scalars['String'];
  productSequence: Scalars['Int'];
  productCompanyPrice?: Maybe<Scalars['Float']>;
  productCompanyPosPrice?: Maybe<Scalars['Float']>;
  productShortDescription?: Maybe<Scalars['String']>;
  productFullDescription?: Maybe<Scalars['String']>;
  productCategoryId?: Maybe<Scalars['Int']>;
  productCategoryName?: Maybe<Scalars['String']>;
  productImageUrl?: Maybe<Scalars['String']>;
  productMinimumChoices: Scalars['Int'];
  productMaximumChoices: Scalars['Int'];
};

export type TemporaryUser = Node & {
  __typename?: 'TemporaryUser';
  /** The ID of the object. */
  id: Scalars['ID'];
  couponId?: Maybe<Scalars['Int']>;
  token?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  telephone?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  validationCode?: Maybe<Scalars['String']>;
  creationDatetime?: Maybe<Scalars['DateTime']>;
  validated?: Maybe<Scalars['Boolean']>;
  balance?: Maybe<BalanceConnection>;
  rewards?: Maybe<RewardConnection>;
};

export type TemporaryUserBalanceArgs = {
  brandId?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type TemporaryUserRewardsArgs = {
  brandId?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type TimeRule = Node & {
  __typename?: 'TimeRule';
  /** The ID of the object. */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  brandId?: Maybe<Scalars['Int']>;
  brand?: Maybe<Brand>;
  calendarTimeRule?: Maybe<CalendarTimeRuleConnection>;
  company?: Maybe<Company>;
  exceptionTimeRule?: Maybe<ExceptionTimeRuleConnection>;
  products?: Maybe<ProductConnection>;
  weekdayTimeRule?: Maybe<WeekedayTimeRuleConnection>;
};

export type TimeRuleCalendarTimeRuleArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type TimeRuleExceptionTimeRuleArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type TimeRuleProductsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type TimeRuleWeekdayTimeRuleArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type TimeRuleConnection = {
  __typename?: 'TimeRuleConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TimeRuleEdge>>;
};

/** A Relay edge containing a `TimeRule` and its cursor. */
export type TimeRuleEdge = {
  __typename?: 'TimeRuleEdge';
  /** The item at the end of the edge */
  node?: Maybe<TimeRule>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type TimeRulesScheduleInput = {
  timeFrom: Scalars['Time'];
  timeTo: Scalars['Time'];
  numericalId?: Maybe<Scalars['Int']>;
  day: Scalars['Int'];
};

export type UpdateBrandCroppedPicture = {
  __typename?: 'UpdateBrandCroppedPicture';
  error?: Maybe<Scalars['String']>;
  updatedBrand?: Maybe<Brand>;
};

export type UpdateCompanyCroppedPicture = {
  __typename?: 'UpdateCompanyCroppedPicture';
  error?: Maybe<Scalars['String']>;
  updatedCompany?: Maybe<Company>;
};

export type UpdateCroppedPictureInput = {
  cropHeight: Scalars['Int'];
  cropWidth: Scalars['Int'];
  cropX: Scalars['Int'];
  cropY: Scalars['Int'];
  picture: Scalars['String'];
  target: Scalars['String'];
};

export type User = Node & {
  __typename?: 'User';
  /** The ID of the object. */
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  validationCode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  preferencesToken?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  dateJoined?: Maybe<Scalars['DateTime']>;
  isSuperuser?: Maybe<Scalars['Boolean']>;
  isStaff?: Maybe<Scalars['Boolean']>;
  accountsUser?: Maybe<AccountsUser>;
  customer?: Maybe<Customer>;
};

export type UserAddress = {
  __typename?: 'UserAddress';
  id: Scalars['ID'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  formattedAddress?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  fields?: Maybe<AddressFields>;
};

export type UserAddressInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  formattedAddress?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  fields?: Maybe<AddressFieldsInput>;
};

export type Wallet = {
  __typename?: 'Wallet';
  id: Scalars['ID'];
  cardBrand: Scalars['String'];
};

export type WeekedayTimeRule = Node & {
  __typename?: 'WeekedayTimeRule';
  /** The ID of the object. */
  id: Scalars['ID'];
  timeRuleId?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
  timeFrom?: Maybe<Scalars['String']>;
  timeTo?: Maybe<Scalars['String']>;
  timeRule?: Maybe<TimeRule>;
  numericalId?: Maybe<Scalars['Int']>;
};

export type WeekedayTimeRuleConnection = {
  __typename?: 'WeekedayTimeRuleConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<WeekedayTimeRuleEdge>>;
};

/** A Relay edge containing a `WeekedayTimeRule` and its cursor. */
export type WeekedayTimeRuleEdge = {
  __typename?: 'WeekedayTimeRuleEdge';
  /** The item at the end of the edge */
  node?: Maybe<WeekedayTimeRule>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum WorkplaceType {
  Store = 'STORE',
  Company = 'COMPANY',
}

export type AcceptTermsAndConditionsMutationVariables = Exact<{ [key: string]: never }>;

export type AcceptTermsAndConditionsMutation = { __typename?: 'Mutations' } & {
  acceptTermsAndConditions: { __typename?: 'Result' } & Pick<Result, 'success' | 'errorMsg'>;
};

export type AddLocalCardMutationVariables = Exact<{
  localCard: LocalCardInput;
}>;

export type AddLocalCardMutation = { __typename?: 'Mutations' } & Pick<Mutations, 'addLocalCard'>;

export type AddOfflinePaymentMutationVariables = Exact<{
  cardBrand: Scalars['String'];
  cardName: Scalars['String'];
}>;

export type AddOfflinePaymentMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'addOfflinePayment'
>;

export type AddToCartMutationVariables = Exact<{
  companyId: Scalars['Int'];
  productCacheId: Scalars['String'];
  productQuantity: Scalars['Int'];
}>;

export type AddToCartMutation = { __typename?: 'Mutations' } & Pick<Mutations, 'addToCart'>;

export type AggregatorCompaniesFragment = { __typename: 'CompanySharedEdge' } & Pick<
  CompanySharedEdge,
  'cursor'
> & {
    node?: Maybe<
      { __typename: 'Company' } & Pick<
        Company,
        'id' | 'name' | 'displayName' | 'numericalId' | 'onlineStatus' | 'hidden'
      > & {
          brand?: Maybe<
            { __typename: 'Brand' } & Pick<Brand, 'id' | 'numericalId'> & {
                images?: Maybe<
                  Array<Maybe<{ __typename: 'Image' } & Pick<Image, 'context' | 'url'>>>
                >;
              }
          >;
          images?: Maybe<Array<Maybe<{ __typename: 'Image' } & Pick<Image, 'context' | 'url'>>>>;
          openingTimes?: Maybe<{ __typename?: 'TimeRule' } & TimeRulesFragment>;
          highlight?: Maybe<
            { __typename: 'CompanyHighlight' } & Pick<
              CompanyHighlight,
              'numericalId' | 'topLabel' | 'centerLabel' | 'bottomLabel'
            >
          >;
          preparationTime?: Maybe<
            { __typename: 'PreparationTime' } & Pick<
              PreparationTime,
              'minTime' | 'maxTime' | 'status'
            >
          >;
          parameter?: Maybe<
            { __typename: 'Parameter' } & Pick<Parameter, 'id'> & {
                paymentParameter?: Maybe<
                  Array<Maybe<{ __typename?: 'PaymentParameter' } & PaymentParameterFragment>>
                >;
                orderTypes?: Maybe<
                  Array<
                    Maybe<
                      { __typename: 'OrderTypeParameter' } & Pick<
                        OrderTypeParameter,
                        'id' | 'title' | 'description' | 'additionalPreparationTime'
                      > & {
                          orderFields?: Maybe<
                            Array<
                              Maybe<
                                { __typename?: 'OrderFieldParameter' } & OrderFieldParameterFragment
                              >
                            >
                          >;
                        }
                    >
                  >
                >;
              }
          >;
        }
    >;
  };

export type CartPaymentFlowQueryVariables = Exact<{ [key: string]: never }>;

export type CartPaymentFlowQuery = { __typename?: 'Query' } & {
  cartPaymentFlow: { __typename: 'CartPaymentFlow' } & Pick<
    CartPaymentFlow,
    'status' | 'cardBrand'
  >;
};

export type ClearDeprecatedCacheMutationVariables = Exact<{ [key: string]: never }>;

export type ClearDeprecatedCacheMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'clearDeprecatedCache'
>;

export type CreateNewShoppingCartMutationVariables = Exact<{
  companyId: Scalars['Int'];
}>;

export type CreateNewShoppingCartMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'createNewShoppingCart'
>;

export type CustomerDataFragment = { __typename: 'CustomerData' } & Pick<
  CustomerData,
  | 'id'
  | 'numericalId'
  | 'name'
  | 'email'
  | 'cpf'
  | 'birthday'
  | 'gender'
  | 'telephone'
  | 'acceptedTermsDatetime'
  | 'validationCellphone'
>;

export type DecreaseChosenProductQuantityMutationVariables = Exact<{
  input: ChangeProductQuantity;
}>;

export type DecreaseChosenProductQuantityMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'decreaseChosenProductQuantity'
>;

export type FlipChosenProductQuantityMutationVariables = Exact<{
  input: ChangeProductQuantity;
}>;

export type FlipChosenProductQuantityMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'flipChosenProductQuantity'
>;

export type GetAggregatorCompaniesQueryVariables = Exact<{
  aggregatorId: Scalars['Int'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  maximumDistanceKilometers?: Maybe<Scalars['Float']>;
  filterBy?: Maybe<AggregatorFilterOption>;
  orderType?: Maybe<Array<Maybe<OrderTypeFilterOptionEnum>> | Maybe<OrderTypeFilterOptionEnum>>;
  first?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  companyNameStartsWith?: Maybe<Scalars['String']>;
  deliveryAddress: DeliveryAddressInput;
}>;

export type GetAggregatorCompaniesQuery = { __typename?: 'Query' } & {
  aggregator?: Maybe<
    { __typename: 'CompanyGroup' } & Pick<CompanyGroup, 'id' | 'name' | 'numericalId'> & {
        address?: Maybe<{ __typename: 'Address' } & Pick<Address, 'geoLat' | 'geoLon'>>;
        images?: Maybe<Array<Maybe<{ __typename: 'Image' } & Pick<Image, 'url' | 'context'>>>>;
        companies?: Maybe<
          { __typename?: 'CompanySharedConnection' } & {
            edges: Array<
              Maybe<
                { __typename: 'CompanySharedEdge' } & Pick<CompanySharedEdge, 'cursor'> & {
                    node?: Maybe<
                      { __typename: 'Company' } & Pick<
                        Company,
                        | 'id'
                        | 'name'
                        | 'displayName'
                        | 'numericalId'
                        | 'onlineStatus'
                        | 'hidden'
                        | 'isFullService'
                      > & {
                          shippingPrice?: Maybe<
                            { __typename?: 'ShippingPrice' } & Pick<
                              ShippingPrice,
                              'value' | 'label'
                            >
                          >;
                          brand?: Maybe<
                            { __typename: 'Brand' } & Pick<Brand, 'id' | 'numericalId'> & {
                                images?: Maybe<
                                  Array<
                                    Maybe<{ __typename: 'Image' } & Pick<Image, 'context' | 'url'>>
                                  >
                                >;
                              }
                          >;
                          images?: Maybe<
                            Array<Maybe<{ __typename: 'Image' } & Pick<Image, 'context' | 'url'>>>
                          >;
                          openingTimes?: Maybe<{ __typename?: 'TimeRule' } & TimeRulesFragment>;
                          highlight?: Maybe<
                            { __typename: 'CompanyHighlight' } & Pick<
                              CompanyHighlight,
                              'numericalId' | 'topLabel' | 'centerLabel' | 'bottomLabel'
                            >
                          >;
                          preparationTime?: Maybe<
                            { __typename: 'PreparationTime' } & Pick<
                              PreparationTime,
                              'minTime' | 'maxTime' | 'status'
                            >
                          >;
                          parameter?: Maybe<
                            { __typename: 'Parameter' } & Pick<Parameter, 'id'> & {
                                paymentParameter?: Maybe<
                                  Array<
                                    Maybe<
                                      { __typename?: 'PaymentParameter' } & PaymentParameterFragment
                                    >
                                  >
                                >;
                                orderTypes?: Maybe<
                                  Array<
                                    Maybe<
                                      { __typename: 'OrderTypeParameter' } & Pick<
                                        OrderTypeParameter,
                                        'id' | 'title' | 'description' | 'additionalPreparationTime'
                                      > & {
                                          additionalChargeParameter?: Maybe<
                                            Array<
                                              Maybe<
                                                { __typename?: 'AdditionalChargeParameter' } & Pick<
                                                  AdditionalChargeParameter,
                                                  'additionalType' | 'additionalValue'
                                                >
                                              >
                                            >
                                          >;
                                          orderFields?: Maybe<
                                            Array<
                                              Maybe<
                                                {
                                                  __typename?: 'OrderFieldParameter';
                                                } & OrderFieldParameterFragment
                                              >
                                            >
                                          >;
                                        }
                                    >
                                  >
                                >;
                              }
                          >;
                        }
                    >;
                  }
              >
            >;
          }
        >;
      }
  >;
};

export type GetAggregatorImagesQueryVariables = Exact<{
  aggregatorId: Scalars['Int'];
}>;

export type GetAggregatorImagesQuery = { __typename?: 'Query' } & {
  aggregator?: Maybe<
    { __typename: 'CompanyGroup' } & Pick<CompanyGroup, 'id' | 'name' | 'numericalId'> & {
        images?: Maybe<Array<Maybe<{ __typename: 'Image' } & Pick<Image, 'url' | 'context'>>>>;
      }
  >;
};

export type GetAggregatorNameQueryVariables = Exact<{
  aggregatorId: Scalars['Int'];
  companyGroupId?: Maybe<Scalars['Int']>;
}>;

export type GetAggregatorNameQuery = { __typename?: 'Query' } & {
  aggregator?: Maybe<
    { __typename: 'CompanyGroup' } & Pick<CompanyGroup, 'id' | 'name' | 'numericalId'>
  >;
};

export type GetAllShoppingCartsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllShoppingCartsQuery = { __typename?: 'Query' } & {
  shoppingCarts: Array<Maybe<{ __typename?: 'ShoppingCart' } & ShoppingCartFragment>>;
};

export type GetBrandBalanceQueryVariables = Exact<{
  brandId: Scalars['Int'];
}>;

export type GetBrandBalanceQuery = { __typename?: 'Query' } & {
  brandBalance: { __typename: 'BrandBalance' } & Pick<
    BrandBalance,
    'id' | 'expiringDate' | 'expiringPoints' | 'balancePoints' | 'reference'
  > & {
      user: { __typename: 'BalanceUser' } & Pick<
        BalanceUser,
        | 'id'
        | 'numericalId'
        | 'firstName'
        | 'lastName'
        | 'telephone'
        | 'email'
        | 'birthday'
        | 'gender'
        | 'validationCellphone'
      > & {
          document: Array<
            Maybe<
              { __typename: 'BalanceUserDocument' } & Pick<
                BalanceUserDocument,
                'id' | 'numericalId' | 'documentType' | 'data'
              >
            >
          >;
        };
    };
};

export type GetBrandThemeQueryVariables = Exact<{
  brandId: Scalars['Int'];
}>;

export type GetBrandThemeQuery = { __typename?: 'Query' } & {
  brandTheme?: Maybe<
    { __typename?: 'BrandTheme' } & Pick<BrandTheme, 'id'> & {
        brandThemeColor?: Maybe<
          Array<Maybe<{ __typename?: 'BrandThemeColor' } & Pick<BrandThemeColor, 'key' | 'value'>>>
        >;
      }
  >;
};

export type GetCardsQueryVariables = Exact<{
  companyId?: Maybe<Scalars['Int']>;
}>;

export type GetCardsQuery = { __typename?: 'Query' } & {
  cards?: Maybe<
    Array<
      Maybe<
        { __typename: 'RemoteCard' } & Pick<
          RemoteCard,
          | 'id'
          | 'numericalId'
          | 'backendId'
          | 'printedName'
          | 'maskedNumber'
          | 'maskedNumberFull'
          | 'expiration'
          | 'status'
          | 'cardBrand'
        >
      >
    >
  >;
};

export type GetCompaniesGroupsByAggregatorsQueryVariables = Exact<{
  companyGroupId: Scalars['Int'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  maximumDistanceKilometers: Scalars['Float'];
  filterBy?: Maybe<AggregatorFilterOption>;
  first: Scalars['Int'];
  after: Scalars['String'];
  companyNameStartsWith?: Maybe<Scalars['String']>;
}>;

export type GetCompaniesGroupsByAggregatorsQuery = { __typename?: 'Query' } & {
  aggregators?: Maybe<
    { __typename: 'CompanyGroupConnection' } & {
      pageInfo: { __typename: 'PageInfo' } & Pick<PageInfo, 'hasNextPage' | 'endCursor'>;
      edges: Array<
        Maybe<
          { __typename: 'CompanyGroupEdge' } & {
            node?: Maybe<
              { __typename: 'CompanyGroup' } & Pick<
                CompanyGroup,
                'name' | 'numericalId' | 'isEvent'
              > & {
                  address?: Maybe<{ __typename: 'Address' } & Pick<Address, 'geoLat' | 'geoLon'>>;
                  images?: Maybe<
                    Array<Maybe<{ __typename: 'Image' } & Pick<Image, 'context' | 'url'>>>
                  >;
                  companies?: Maybe<
                    { __typename: 'CompanySharedConnection' } & {
                      edges: Array<
                        Maybe<{ __typename?: 'CompanySharedEdge' } & AggregatorCompaniesFragment>
                      >;
                    }
                  >;
                }
            >;
          }
        >
      >;
    }
  >;
  defaultBrandTheme?: Maybe<
    { __typename: 'BrandTheme' } & {
      brandThemeMessage?: Maybe<
        Array<Maybe<{ __typename: 'BrandThemeMessage' } & Pick<BrandThemeMessage, 'key' | 'value'>>>
      >;
    }
  >;
};

export type GetCompanyAddressQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;

export type GetCompanyAddressQuery = { __typename?: 'Query' } & {
  company?: Maybe<
    { __typename: 'Company' } & Pick<Company, 'id'> & {
        address?: Maybe<
          { __typename: 'Address' } & Pick<
            Address,
            'id' | 'street' | 'number' | 'complement' | 'city'
          >
        >;
      }
  >;
};

export type GetCompanyDataQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;

export type GetCompanyDataQuery = { __typename?: 'Query' } & {
  company?: Maybe<{ __typename?: 'Company' } & CompanyDataFragment>;
  productCompanies?: Maybe<
    { __typename?: 'ProductCompanyConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'ProductCompanyEdge' } & {
            node?: Maybe<
              { __typename?: 'ProductCompany' } & Pick<
                ProductCompany,
                'id' | 'price' | 'unavailable' | 'numericalId' | 'companyId'
              > & {
                  product?: Maybe<
                    { __typename?: 'Product' } & Pick<
                      Product,
                      'id' | 'numericalId' | 'name' | 'sequence' | 'offer' | 'shortDescription'
                    > & {
                        category?: Maybe<
                          { __typename?: 'Category' } & Pick<
                            Category,
                            'id' | 'numericalId' | 'name' | 'order'
                          >
                        >;
                        images?: Maybe<
                          Array<Maybe<{ __typename?: 'Image' } & Pick<Image, 'context' | 'url'>>>
                        >;
                      }
                  >;
                }
            >;
          }
        >
      >;
    }
  >;
};

export type GetCompanyImagesQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;

export type GetCompanyImagesQuery = { __typename?: 'Query' } & {
  company?: Maybe<
    { __typename?: 'Company' } & Pick<Company, 'id' | 'name'> & {
        brand?: Maybe<
          { __typename?: 'Brand' } & Pick<Brand, 'id'> & {
              images?: Maybe<
                Array<Maybe<{ __typename?: 'Image' } & Pick<Image, 'context' | 'url'>>>
              >;
            }
        >;
      }
  >;
};

export type GetCompanyOnlineStatusQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;

export type GetCompanyOnlineStatusQuery = { __typename?: 'Query' } & {
  company?: Maybe<{ __typename: 'Company' } & Pick<Company, 'id' | 'onlineStatus'>>;
};

export type GetCompanyOrderTypesQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;

export type GetCompanyOrderTypesQuery = { __typename?: 'Query' } & {
  company?: Maybe<
    { __typename: 'Company' } & Pick<Company, 'id'> & {
        parameter?: Maybe<
          { __typename: 'Parameter' } & Pick<Parameter, 'id'> & {
              orderTypes?: Maybe<
                Array<
                  Maybe<
                    { __typename: 'OrderTypeParameter' } & Pick<
                      OrderTypeParameter,
                      'id' | 'title' | 'description'
                    > & {
                        orderFields?: Maybe<
                          Array<
                            Maybe<
                              { __typename?: 'OrderFieldParameter' } & OrderFieldParameterFragment
                            >
                          >
                        >;
                      }
                  >
                >
              >;
            }
        >;
      }
  >;
};

export type GetCompanyPaymentMethodsQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;

export type GetCompanyPaymentMethodsQuery = { __typename?: 'Query' } & {
  company?: Maybe<
    { __typename: 'Company' } & Pick<Company, 'id' | 'name'> & {
        brand?: Maybe<
          { __typename: 'Brand' } & Pick<Brand, 'id'> & {
              images?: Maybe<
                Array<Maybe<{ __typename: 'Image' } & Pick<Image, 'context' | 'url'>>>
              >;
            }
        >;
        parameter?: Maybe<
          { __typename: 'Parameter' } & Pick<Parameter, 'id'> & {
              paymentParameter?: Maybe<
                Array<Maybe<{ __typename?: 'PaymentParameter' } & PaymentParameterFragment>>
              >;
              offlinePaymentParameter?: Maybe<
                Array<Maybe<{ __typename?: 'PaymentParameter' } & OfflinePaymentParameterFragment>>
              >;
            }
        >;
      }
  >;
};

export type GetCompanyPaymentParamsQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;

export type GetCompanyPaymentParamsQuery = { __typename?: 'Query' } & {
  company?: Maybe<
    { __typename: 'Company' } & Pick<Company, 'id'> & {
        parameter?: Maybe<
          { __typename: 'Parameter' } & Pick<Parameter, 'id'> & {
              paymentParameter?: Maybe<
                Array<Maybe<{ __typename?: 'PaymentParameter' } & PaymentParameterFragment>>
              >;
              offlinePaymentParameter?: Maybe<
                Array<Maybe<{ __typename?: 'PaymentParameter' } & OfflinePaymentParameterFragment>>
              >;
            }
        >;
      }
  >;
};

export type GetCouponsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCouponsQuery = { __typename?: 'Query' } & {
  coupons: { __typename: 'CouponsList' } & Pick<CouponsList, 'telephoneValidated' | 'loading'> & {
      nodes?: Maybe<
        Array<
          Maybe<
            { __typename?: 'Coupon' } & Pick<Coupon, 'code' | 'value' | 'expiration' | 'type'> & {
                brand?: Maybe<{ __typename?: 'Brand' } & Pick<Brand, 'id' | 'name'>>;
              }
          >
        >
      >;
    };
};

export type GetCustomerDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetCustomerDataQuery = { __typename?: 'Query' } & {
  customerData?: Maybe<{ __typename?: 'CustomerData' } & CustomerDataFragment>;
};

export type GetCvvCriptoKeyQueryVariables = Exact<{
  brandId: Scalars['Int'];
}>;

export type GetCvvCriptoKeyQuery = { __typename?: 'Query' } & {
  brandBalance: { __typename: 'BrandBalance' } & Pick<BrandBalance, 'id' | 'reference'>;
};

export type GetDeviceMetadataQueryVariables = Exact<{ [key: string]: never }>;

export type GetDeviceMetadataQuery = { __typename?: 'Query' } & Pick<Query, 'deviceMetadata'>;

export type GetLastOrdersQueryVariables = Exact<{
  fromDatetime: Scalars['DateTime'];
  brand?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;

export type GetLastOrdersQuery = { __typename?: 'Query' } & {
  lastOrders?: Maybe<
    { __typename: 'OrderConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'OrderEdge' } & {
            node?: Maybe<
              { __typename: 'Order' } & Pick<
                Order,
                | 'id'
                | 'status'
                | 'orderType'
                | 'displayCode'
                | 'preparationTimeMinimum'
                | 'preparationTimeMaximum'
                | 'creationDatetime'
                | 'companyId'
              >
            >;
          }
        >
      >;
    }
  >;
};

export type GetLocalCardQueryVariables = Exact<{ [key: string]: never }>;

export type GetLocalCardQuery = { __typename?: 'Query' } & {
  localCard?: Maybe<
    { __typename: 'LocalCard' } & Pick<
      LocalCard,
      | 'id'
      | 'cardNumber'
      | 'cardName'
      | 'cardExpiration'
      | 'birthday'
      | 'cardCvv'
      | 'cardBrand'
      | 'paymentMethod'
    >
  >;
};

export type GetOfflinePaymentQueryVariables = Exact<{ [key: string]: never }>;

export type GetOfflinePaymentQuery = { __typename?: 'Query' } & {
  offlinePayment?: Maybe<
    { __typename: 'OfflinePayment' } & Pick<OfflinePayment, 'id' | 'cardBrand' | 'cardName'>
  >;
};

export type GetOrLoadSelectedPaymentMethodMutationVariables = Exact<{
  companyId?: Maybe<Scalars['Int']>;
}>;

export type GetOrLoadSelectedPaymentMethodMutation = { __typename?: 'Mutations' } & {
  getOrLoadSelectedPaymentMethod?: Maybe<
    | ({ __typename: 'LocalCard' } & LocalCardFragment)
    | ({ __typename: 'RemoteCard' } & RemoteCardFragment)
    | ({ __typename: 'Wallet' } & WalletFragment)
    | { __typename: 'OfflinePayment' }
  >;
};

export type GetOrderCheckErrorsQueryVariables = Exact<{ [key: string]: never }>;

export type GetOrderCheckErrorsQuery = { __typename?: 'Query' } & {
  orderCheck?: Maybe<
    { __typename?: 'OrderCheckResponse' } & Pick<OrderCheckResponse, 'warning'> & {
        error?: Maybe<{ __typename?: 'Error' } & Pick<Error, 'message'>>;
      }
  >;
};

export type GetOrderCheckExtrasQueryVariables = Exact<{ [key: string]: never }>;

export type GetOrderCheckExtrasQuery = { __typename?: 'Query' } & {
  orderCheck?: Maybe<
    { __typename?: 'OrderCheckResponse' } & {
      extras?: Maybe<
        Array<
          Maybe<
            { __typename?: 'OrderExtra' } & Pick<OrderExtra, 'key' | 'value' | 'label' | 'optional'>
          >
        >
      >;
    }
  >;
};

export type GetOrderCheckFromCacheQueryVariables = Exact<{ [key: string]: never }>;

export type GetOrderCheckFromCacheQuery = { __typename?: 'Query' } & {
  orderCheck?: Maybe<
    { __typename: 'OrderCheckResponse' } & Pick<
      OrderCheckResponse,
      'id' | 'minimumPreparationTime' | 'maximumPreparationTime' | 'warning'
    > & {
        extras?: Maybe<
          Array<
            Maybe<
              { __typename: 'OrderExtra' } & Pick<
                OrderExtra,
                'id' | 'optional' | 'key' | 'value' | 'label'
              >
            >
          >
        >;
        error?: Maybe<{ __typename: 'Error' } & Pick<Error, 'id' | 'type' | 'message'>>;
      }
  >;
};

export type GetOrderCheckPreparationTimeQueryVariables = Exact<{ [key: string]: never }>;

export type GetOrderCheckPreparationTimeQuery = { __typename?: 'Query' } & {
  orderCheck?: Maybe<
    { __typename?: 'OrderCheckResponse' } & Pick<
      OrderCheckResponse,
      'minimumPreparationTime' | 'maximumPreparationTime'
    >
  >;
};

export type GetOrderDataQueryVariables = Exact<{
  orderId: Scalars['Int'];
}>;

export type GetOrderDataQuery = { __typename?: 'Query' } & {
  order?: Maybe<
    { __typename: 'Order' } & Pick<
      Order,
      | 'id'
      | 'status'
      | 'orderType'
      | 'displayCode'
      | 'preparationTimeMinimum'
      | 'preparationTimeMaximum'
      | 'creationDatetime'
      | 'companyId'
      | 'sequenceNumber'
      | 'totalValue'
      | 'payableValue'
      | 'discountPercentual'
      | 'taxPercentual'
      | 'taxValue'
      | 'discountValue'
      | 'subsidyValue'
      | 'received'
      | 'tableReference'
    > & {
        orderExtra?: Maybe<
          Array<
            Maybe<
              { __typename?: 'OrderExtra' } & Pick<
                OrderExtra,
                'key' | 'optional' | 'label' | 'value'
              >
            >
          >
        >;
        address?: Maybe<
          { __typename?: 'Address' } & Pick<Address, 'street' | 'number' | 'zipCode'>
        >;
        payments?: Maybe<
          Array<
            Maybe<
              { __typename?: 'Payment' } & Pick<Payment, 'method' | 'value'> & {
                  card?: Maybe<
                    { __typename?: 'Card' } & Pick<Card, 'maskedNumber' | 'paymentMethod'> & {
                        cardBrand?: Maybe<
                          { __typename?: 'CardBrand' } & Pick<CardBrand, 'name' | 'code'>
                        >;
                      }
                  >;
                }
            >
          >
        >;
        items?: Maybe<
          Array<
            Maybe<
              { __typename?: 'OrderItem' } & Pick<
                OrderItem,
                | 'parentId'
                | 'name'
                | 'unitPrice'
                | 'productType'
                | 'numericalId'
                | 'offer'
                | 'itemId'
              >
            >
          >
        >;
      }
  >;
};

export type GetOrderTotalQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;

export type GetOrderTotalQuery = { __typename?: 'Query' } & {
  shoppingCart?: Maybe<
    { __typename?: 'ShoppingCart' } & Pick<
      ShoppingCart,
      'totalProductsPrice' | 'totalExtras' | 'orderTotal'
    >
  >;
  orderCheck?: Maybe<
    { __typename?: 'OrderCheckResponse' } & {
      extras?: Maybe<
        Array<
          Maybe<
            { __typename?: 'OrderExtra' } & Pick<
              OrderExtra,
              'id' | 'key' | 'optional' | 'value' | 'label'
            >
          >
        >
      >;
    }
  >;
};

export type GetOrdersQueryVariables = Exact<{ [key: string]: never }>;

export type GetOrdersQuery = { __typename?: 'Query' } & {
  orders?: Maybe<
    Array<
      Maybe<
        { __typename?: 'OrderRemoteResponse' } & Pick<
          OrderRemoteResponse,
          | 'id'
          | 'status'
          | 'orderType'
          | 'companyName'
          | 'company'
          | 'creationDatetime'
          | 'displayCode'
        > & {
            items?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'OrderItems' } & Pick<OrderItems, 'product' | 'name' | 'quantity'>
                >
              >
            >;
          }
      >
    >
  >;
};

export type GetProductDetailsQueryVariables = Exact<{
  productId: Scalars['Int'];
  companyId: Scalars['Int'];
}>;

export type GetProductDetailsQuery = { __typename?: 'Query' } & {
  productDetails?: Maybe<
    { __typename?: 'Product' } & {
      images?: Maybe<Array<Maybe<{ __typename?: 'Image' } & Pick<Image, 'context' | 'url'>>>>;
      subproducts?: Maybe<
        Array<
          Maybe<
            { __typename?: 'Product' } & {
              subproducts?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'Product' } & {
                      subproducts?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: 'Product' } & {
                              subproducts?: Maybe<
                                Array<
                                  Maybe<
                                    { __typename?: 'Product' } & {
                                      subproducts?: Maybe<
                                        Array<
                                          Maybe<
                                            { __typename?: 'Product' } & {
                                              subproducts?: Maybe<
                                                Array<
                                                  Maybe<
                                                    { __typename?: 'Product' } & {
                                                      subproducts?: Maybe<
                                                        Array<
                                                          Maybe<
                                                            {
                                                              __typename?: 'Product';
                                                            } & ProductFragment
                                                          >
                                                        >
                                                      >;
                                                    } & ProductFragment
                                                  >
                                                >
                                              >;
                                            } & ProductFragment
                                          >
                                        >
                                      >;
                                    } & ProductFragment
                                  >
                                >
                              >;
                            } & ProductFragment
                          >
                        >
                      >;
                    } & ProductFragment
                  >
                >
              >;
            } & ProductFragment
          >
        >
      >;
    } & ProductFragment
  >;
};

export type GetSelectedOrderTypeQueryVariables = Exact<{ [key: string]: never }>;

export type GetSelectedOrderTypeQuery = { __typename?: 'Query' } & {
  selectedOrderType?: Maybe<{ __typename?: 'SelectedOrderType' } & SelectedOrderTypeFragment>;
};

export type GetSelectedPaymentMethodFromCacheQueryVariables = Exact<{ [key: string]: never }>;

export type GetSelectedPaymentMethodFromCacheQuery = { __typename?: 'Query' } & {
  selectedPaymentMethod?: Maybe<
    | ({ __typename: 'LocalCard' } & LocalCardFragment)
    | ({ __typename: 'RemoteCard' } & RemoteCardFragment)
    | ({ __typename: 'Wallet' } & WalletFragment)
    | ({ __typename: 'OfflinePayment' } & OfflinePaymentFragment)
  >;
};

export type SelectedTabQueryVariables = Exact<{ [key: string]: never }>;

export type SelectedTabQuery = { __typename?: 'Query' } & Pick<Query, 'selectedTab'>;

export type GetShoppingCartProductsQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;

export type GetShoppingCartProductsQuery = { __typename?: 'Query' } & {
  shoppingCartProducts: Array<
    { __typename?: 'ShoppingCartProduct' } & Pick<
      ShoppingCartProduct,
      'id' | 'name' | 'numericalId' | 'totalPrice' | 'subproductsDescription'
    >
  >;
};

export type GetShoppingCartQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;

export type GetShoppingCartQuery = { __typename?: 'Query' } & {
  shoppingCart?: Maybe<{ __typename?: 'ShoppingCart' } & ShoppingCartFragment>;
};

export type GetUserAddressQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserAddressQuery = { __typename?: 'Query' } & {
  userAddress?: Maybe<{ __typename?: 'UserAddress' } & UserAddressFragment>;
};

export type IncreaseChosenProductQuantityMutationVariables = Exact<{
  input: ChangeProductQuantity;
}>;

export type IncreaseChosenProductQuantityMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'increaseChosenProductQuantity'
>;

export type IsAuthenticatedQueryVariables = Exact<{ [key: string]: never }>;

export type IsAuthenticatedQuery = { __typename?: 'Query' } & Pick<
  Query,
  'isAuthenticated' | 'authExecuted'
>;

export type IsEmbeddedQueryVariables = Exact<{ [key: string]: never }>;

export type IsEmbeddedQuery = { __typename?: 'Query' } & Pick<Query, 'isEmbedded'>;

export type IsExecutingPaymentCallQueryVariables = Exact<{ [key: string]: never }>;

export type IsExecutingPaymentCallQuery = { __typename?: 'Query' } & Pick<
  Query,
  'isExecutingPaymentCall'
>;

export type IsGmapsReadyQueryVariables = Exact<{ [key: string]: never }>;

export type IsGmapsReadyQuery = { __typename?: 'Query' } & Pick<Query, 'isGmapsReady'>;

export type LoadSelectedOrderTypeFromBrowserCacheMutationVariables = Exact<{
  [key: string]: never;
}>;

export type LoadSelectedOrderTypeFromBrowserCacheMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'loadSelectedOrderTypeFromBrowserCache'
>;

export type LoadShoppingCartsFromBrowserCacheMutationVariables = Exact<{
  cacheName?: Maybe<Scalars['String']>;
}>;

export type LoadShoppingCartsFromBrowserCacheMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'loadShoppingCartsFromBrowserCache'
>;

export type LoadUserAddressFromBrowserCacheMutationVariables = Exact<{
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
}>;

export type LoadUserAddressFromBrowserCacheMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'loadUserAddressFromBrowserCache'
>;

export type LocalCardFragment = { __typename: 'LocalCard' } & Pick<
  LocalCard,
  'id' | 'cardNumber' | 'cardName' | 'cardExpiration' | 'cardCvv' | 'cardBrand' | 'paymentMethod'
>;

export type LoginSocialMutationVariables = Exact<{
  input: LoginSocialInput;
}>;

export type LoginSocialMutation = { __typename?: 'Mutations' } & {
  loginSocial?: Maybe<{ __typename?: 'Result' } & Pick<Result, 'success' | 'errorMsg'>>;
};

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;

export type LoginMutation = { __typename?: 'Mutations' } & {
  login?: Maybe<{ __typename?: 'Result' } & Pick<Result, 'success' | 'errorMsg'>>;
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = { __typename?: 'Mutations' } & Pick<Mutations, 'logout'>;

export type MagicLinkCreateMutationVariables = Exact<{
  input: MagicLinkCreateInput;
}>;

export type MagicLinkCreateMutation = { __typename?: 'Mutations' } & {
  magicLinkCreate: { __typename?: 'MagicLinkResult' } & Pick<
    MagicLinkResult,
    'success' | 'alreadyExist' | 'errorMsg'
  >;
};

export type MagicLinkLoginMutationVariables = Exact<{
  input: MagicLinkLoginInput;
}>;

export type MagicLinkLoginMutation = { __typename?: 'Mutations' } & {
  magicLinkLogin: { __typename?: 'Result' } & Pick<Result, 'success' | 'errorMsg'>;
};

export type MiniAppLoginMutationVariables = Exact<{
  input: MiniAppLoginInput;
}>;

export type MiniAppLoginMutation = { __typename?: 'Mutations' } & {
  miniAppLogin?: Maybe<{ __typename?: 'Result' } & Pick<Result, 'success' | 'errorMsg'>>;
};

export type NotifyQueryVariables = Exact<{ [key: string]: never }>;

export type NotifyQuery = { __typename?: 'Query' } & {
  notify: { __typename: 'Notify' } & Pick<Notify, 'status' | 'active' | 'message'>;
};

export type OfflinePaymentParameterFragment = { __typename: 'PaymentParameter' } & Pick<
  PaymentParameter,
  'id' | 'parameterId' | 'cardBrandId' | 'paymentMethod'
> & { cardBrand?: Maybe<{ __typename: 'CardBrand' } & Pick<CardBrand, 'id' | 'name' | 'code'>> };

export type OfflinePaymentFragment = { __typename?: 'OfflinePayment' } & Pick<
  OfflinePayment,
  'id' | 'cardBrand' | 'cardName'
>;

export type OrderCheckRequestMutationVariables = Exact<{
  companyId: Scalars['Int'];
  orderTypes?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;

export type OrderCheckRequestMutation = { __typename?: 'Mutations' } & {
  orderCheckRequest: { __typename?: 'Result' } & Pick<Result, 'success' | 'errorMsg'>;
};

export type OrderFieldParameterFragment = { __typename: 'OrderFieldParameter' } & Pick<
  OrderFieldParameter,
  'id'
> & {
    field?: Maybe<
      { __typename: 'OrderField' } & Pick<
        OrderField,
        | 'id'
        | 'numericalId'
        | 'title'
        | 'label'
        | 'fieldType'
        | 'maxLength'
        | 'position'
        | 'required'
        | 'requiredErrorMessage'
        | 'invalidErrorMessage'
      >
    >;
  };

export type OrderPostRequestMutationVariables = Exact<{
  input: OrderPostInput;
}>;

export type OrderPostRequestMutation = { __typename?: 'Mutations' } & {
  orderPostRequest: { __typename?: 'OrderPostResult' } & Pick<
    OrderPostResult,
    'success' | 'orderId' | 'errorMsg'
  >;
};

export type GetOrderStatusQueryVariables = Exact<{
  input: OrderStatusInput;
}>;

export type GetOrderStatusQuery = { __typename?: 'Query' } & {
  orderStatus: { __typename: 'OrderStatus' } & Pick<
    OrderStatus,
    'id' | 'status' | 'numericalId' | 'shouldRequestCardNumber' | 'creationDatetime' | 'errorMsg'
  >;
};

export type PartnerLoginMutationVariables = Exact<{
  token: Scalars['String'];
}>;

export type PartnerLoginMutation = { __typename?: 'Mutations' } & {
  partnerLogin?: Maybe<{ __typename?: 'Result' } & Pick<Result, 'success' | 'errorMsg'>>;
};

export type PaymentParameterFragment = { __typename: 'PaymentParameter' } & Pick<
  PaymentParameter,
  'id' | 'paymentMethod'
> & { cardBrand?: Maybe<{ __typename: 'CardBrand' } & Pick<CardBrand, 'id' | 'name' | 'code'>> };

export type PreOrderPostValidationMutationVariables = Exact<{
  companyId: Scalars['Int'];
}>;

export type PreOrderPostValidationMutation = { __typename?: 'Mutations' } & {
  preOrderPostValidation: { __typename?: 'Result' } & Pick<Result, 'success' | 'errorMsg'>;
};

export type ProductAndSubproductsFragment = { __typename?: 'Product' } & {
  subproducts?: Maybe<Array<Maybe<{ __typename: 'Product' } & Pick<Product, 'id'>>>>;
} & ProductFragment;

export type ProductChosenQuantityFragment = { __typename: 'Product' } & Pick<
  Product,
  'id' | 'chosenQuantity' | 'scrollToProduct'
>;

export type ProductClientPropsFragment = { __typename: 'Product' } & Pick<
  Product,
  'id' | 'chosenQuantity' | 'isValid' | 'totalPrice'
>;

export type ProductDetailsDialogQueryVariables = Exact<{ [key: string]: never }>;

export type ProductDetailsDialogQuery = { __typename?: 'Query' } & {
  productDetailsDialog: { __typename: 'ProductDetailsDialog' } & Pick<
    ProductDetailsDialog,
    'active' | 'product'
  >;
};

export type ProductFilterQueryVariables = Exact<{ [key: string]: never }>;

export type ProductFilterQuery = { __typename?: 'Query' } & {
  productFilter: { __typename: 'ProductFilter' } & Pick<ProductFilter, 'active' | 'query'>;
};

export type ProductIsValidFragment = { __typename: 'Product' } & Pick<Product, 'id' | 'isValid'>;

export type ProductPriceFragment = { __typename: 'Product' } & Pick<
  Product,
  'id' | 'numericalId' | 'totalPrice'
>;

export type ProductWithDescriptionAndSubproductsFragment = { __typename?: 'Product' } & {
  images?: Maybe<Array<Maybe<{ __typename: 'Image' } & Pick<Image, 'context' | 'url'>>>>;
} & ProductAndSubproductsFragment;

export type ProductFragment = { __typename: 'Product' } & Pick<
  Product,
  | 'id'
  | 'numericalId'
  | 'name'
  | 'productType'
  | 'fullDescription'
  | 'shortDescription'
  | 'minimumChoices'
  | 'maximumChoices'
  | 'hidden'
  | 'offer'
  | 'chosenQuantity'
  | 'isValid'
  | 'totalPrice'
  | 'hasChoosableSubproducts'
  | 'scrollToProduct'
> & {
    category?: Maybe<{ __typename?: 'Category' } & Pick<Category, 'id' | 'name'>>;
    productCompanyByCompanyId?: Maybe<
      { __typename: 'ProductCompany' } & Pick<
        ProductCompany,
        'id' | 'price' | 'unavailable' | 'hidden'
      >
    >;
  };

export type RemoteCardFragment = { __typename: 'RemoteCard' } & Pick<
  RemoteCard,
  | 'id'
  | 'backendId'
  | 'numericalId'
  | 'printedName'
  | 'maskedNumberFull'
  | 'expiration'
  | 'cardBrand'
>;

export type RemoveFromCartMutationVariables = Exact<{
  companyId: Scalars['Int'];
  productCacheId: Scalars['String'];
}>;

export type RemoveFromCartMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'removeFromCart'
>;

export type RemoveLocalCardMutationVariables = Exact<{ [key: string]: never }>;

export type RemoveLocalCardMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'removeLocalCard'
>;

export type RemoveRemoteCardMutationVariables = Exact<{
  cardId: Scalars['Int'];
}>;

export type RemoveRemoteCardMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'removeRemoteCard'
>;

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type ResetPasswordMutation = { __typename?: 'Mutations' } & {
  resetPassword: { __typename?: 'Result' } & Pick<Result, 'success' | 'errorMsg'>;
};

export type ResetShoppingCartMutationVariables = Exact<{
  companyId: Scalars['Int'];
}>;

export type ResetShoppingCartMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'resetShoppingCart'
>;

export type SaveShoppingCartToBrowserCacheMutationVariables = Exact<{
  companyId: Scalars['Int'];
}>;

export type SaveShoppingCartToBrowserCacheMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'saveShoppingCartToBrowserCache'
>;

export type ScrollToProductFragment = { __typename: 'Product' } & Pick<
  Product,
  'id' | 'scrollToProduct'
>;

export type SelectCardMutationVariables = Exact<{
  remoteCard?: Maybe<RemoteCardInput>;
  localCard?: Maybe<LocalCardInput>;
}>;

export type SelectCardMutation = { __typename?: 'Mutations' } & Pick<Mutations, 'selectCard'>;

export type SelectOrderTypeMutationVariables = Exact<{
  input: SelectedOrderTypeInput;
}>;

export type SelectOrderTypeMutation = { __typename?: 'Mutations' } & {
  selectedOrderType: { __typename?: 'SelectedOrderType' } & SelectedOrderTypeFragment;
};

export type SelectWalletMutationVariables = Exact<{
  cardBrand: Scalars['String'];
}>;

export type SelectWalletMutation = { __typename?: 'Mutations' } & Pick<Mutations, 'selectWallet'>;

export type SelectedOrderTypeFragment = { __typename: 'SelectedOrderType' } & Pick<
  SelectedOrderType,
  'id' | 'orderType'
> & {
    fieldValues?: Maybe<
      Array<
        Maybe<
          { __typename: 'SelectedOrderTypeFieldValue' } & Pick<
            SelectedOrderTypeFieldValue,
            'id' | 'position' | 'value' | 'label' | 'numericalId'
          >
        >
      >
    >;
  };

export type SetGmapsReadyMutationVariables = Exact<{
  ready: Scalars['Boolean'];
}>;

export type SetGmapsReadyMutation = { __typename?: 'Mutations' } & Pick<Mutations, 'setGmapsReady'>;

export type SetScrollToProductMutationVariables = Exact<{
  productId: Scalars['String'];
  value: Scalars['Boolean'];
}>;

export type SetScrollToProductMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'setScrollToProduct'
>;

export type SetUserAddressMutationVariables = Exact<{
  input: UserAddressInput;
}>;

export type SetUserAddressMutation = { __typename?: 'Mutations' } & {
  userAddress: { __typename?: 'UserAddress' } & UserAddressFragment;
};

export type SetDeviceMetadataMutationVariables = Exact<{
  input: DeviceMetadataInput;
}>;

export type SetDeviceMetadataMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'deviceMetadata'
>;

export type ShoppingCartFragment = { __typename: 'ShoppingCart' } & Pick<
  ShoppingCart,
  'id' | 'totalProductsPrice' | 'totalExtras' | 'orderTotal'
> & { products: Array<{ __typename?: 'Product' } & ProductAndSubproductsFragment> };

export type SignUpMutationVariables = Exact<{
  input: SignUpInput;
}>;

export type SignUpMutation = { __typename?: 'Mutations' } & {
  signUp?: Maybe<{ __typename?: 'Result' } & Pick<Result, 'success' | 'errorMsg'>>;
};

export type TimeRulesFragment = { __typename: 'TimeRule' } & Pick<TimeRule, 'id'> & {
    weekdayTimeRule?: Maybe<
      { __typename: 'WeekedayTimeRuleConnection' } & {
        edges: Array<
          Maybe<
            { __typename: 'WeekedayTimeRuleEdge' } & {
              node?: Maybe<
                { __typename: 'WeekedayTimeRule' } & Pick<
                  WeekedayTimeRule,
                  'id' | 'day' | 'timeFrom' | 'timeTo'
                >
              >;
            }
          >
        >;
      }
    >;
    exceptionTimeRule?: Maybe<
      { __typename: 'ExceptionTimeRuleConnection' } & {
        edges: Array<
          Maybe<
            { __typename: 'ExceptionTimeRuleEdge' } & {
              node?: Maybe<
                { __typename: 'ExceptionTimeRule' } & Pick<ExceptionTimeRule, 'id' | 'date'>
              >;
            }
          >
        >;
      }
    >;
  };

export type UpdateCustomerDataMutationVariables = Exact<{
  input: CustomerDataInput;
}>;

export type UpdateCustomerDataMutation = { __typename?: 'Mutations' } & {
  updateCustomerData?: Maybe<{ __typename?: 'CustomerData' } & CustomerDataFragment>;
};

export type CompanyDataFragment = { __typename: 'Company' } & Pick<
  Company,
  'id' | 'name' | 'numericalId' | 'onlineStatus'
> & {
    highlight?: Maybe<
      { __typename?: 'CompanyHighlight' } & Pick<
        CompanyHighlight,
        'topLabel' | 'centerLabel' | 'bottomLabel' | 'details'
      >
    >;
    preparationTime?: Maybe<
      { __typename: 'PreparationTime' } & Pick<PreparationTime, 'minTime' | 'maxTime' | 'status'>
    >;
    brand?: Maybe<
      { __typename?: 'Brand' } & Pick<Brand, 'id'> & {
          images?: Maybe<Array<Maybe<{ __typename?: 'Image' } & Pick<Image, 'context' | 'url'>>>>;
        }
    >;
    images?: Maybe<Array<Maybe<{ __typename?: 'Image' } & Pick<Image, 'context' | 'url'>>>>;
    companyPhone?: Maybe<{ __typename: 'CompanyPhone' } & Pick<CompanyPhone, 'id' | 'number'>>;
    address?: Maybe<
      { __typename: 'Address' } & Pick<Address, 'id' | 'street' | 'number' | 'complement' | 'city'>
    >;
    openingTimes?: Maybe<{ __typename?: 'TimeRule' } & TimeRulesFragment>;
    parameter?: Maybe<
      { __typename?: 'Parameter' } & Pick<Parameter, 'id'> & {
          paymentParameter?: Maybe<
            Array<Maybe<{ __typename?: 'PaymentParameter' } & PaymentParameterFragment>>
          >;
          offlinePaymentParameter?: Maybe<
            Array<Maybe<{ __typename?: 'PaymentParameter' } & PaymentParameterFragment>>
          >;
          orderTypes?: Maybe<
            Array<
              Maybe<
                { __typename?: 'OrderTypeParameter' } & Pick<
                  OrderTypeParameter,
                  'id' | 'title' | 'description' | 'additionalPreparationTime'
                > & {
                    orderFields?: Maybe<
                      Array<
                        Maybe<{ __typename?: 'OrderFieldParameter' } & OrderFieldParameterFragment>
                      >
                    >;
                  }
              >
            >
          >;
        }
    >;
  };

export type UserAddressFragment = { __typename: 'UserAddress' } & Pick<
  UserAddress,
  'id' | 'latitude' | 'longitude' | 'formattedAddress' | 'placeId' | 'complement'
> & {
    fields?: Maybe<
      { __typename?: 'AddressFields' } & Pick<
        AddressFields,
        'street' | 'number' | 'district' | 'state' | 'city' | 'zipCode'
      >
    >;
  };

export type ValidateProductMutationVariables = Exact<{
  productCacheId: Scalars['String'];
}>;

export type ValidateProductMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'validateProduct'
>;

export type WalletFragment = { __typename?: 'Wallet' } & Pick<Wallet, 'id' | 'cardBrand'>;

export const TimeRulesFragmentDoc = gql`
  fragment timeRules on TimeRule {
    id
    __typename
    weekdayTimeRule {
      __typename
      edges {
        __typename
        node {
          __typename
          id
          day
          timeFrom
          timeTo
        }
      }
    }
    exceptionTimeRule {
      __typename
      edges {
        __typename
        node {
          __typename
          id
          date
        }
      }
    }
  }
`;
export const PaymentParameterFragmentDoc = gql`
  fragment paymentParameter on PaymentParameter {
    id
    __typename
    paymentMethod
    cardBrand {
      id
      __typename
      name
      code
    }
  }
`;
export const OrderFieldParameterFragmentDoc = gql`
  fragment orderFieldParameter on OrderFieldParameter {
    id
    __typename
    field {
      id
      __typename
      numericalId
      title
      label
      fieldType
      maxLength
      position
      required
      requiredErrorMessage
      invalidErrorMessage
    }
  }
`;
export const AggregatorCompaniesFragmentDoc = gql`
  fragment aggregatorCompanies on CompanySharedEdge {
    __typename
    cursor
    node {
      id
      __typename
      name
      displayName
      numericalId
      onlineStatus
      hidden
      brand {
        id
        __typename
        numericalId
        images {
          __typename
          context
          url
        }
      }
      images {
        __typename
        context
        url
      }
      openingTimes {
        ...timeRules
      }
      highlight {
        __typename
        numericalId
        topLabel
        centerLabel
        bottomLabel
      }
      preparationTime {
        __typename
        minTime
        maxTime
        status
      }
      parameter {
        id
        __typename
        paymentParameter {
          ...paymentParameter
        }
        orderTypes {
          __typename
          id
          title
          description
          additionalPreparationTime
          orderFields {
            ...orderFieldParameter
          }
        }
      }
    }
  }
  ${TimeRulesFragmentDoc}
  ${PaymentParameterFragmentDoc}
  ${OrderFieldParameterFragmentDoc}
`;
export const CustomerDataFragmentDoc = gql`
  fragment customerData on CustomerData {
    id
    __typename
    numericalId
    name
    email
    cpf
    birthday
    gender
    telephone
    acceptedTermsDatetime
    validationCellphone
  }
`;
export const LocalCardFragmentDoc = gql`
  fragment localCard on LocalCard {
    id
    __typename
    cardNumber
    cardName
    cardExpiration
    cardCvv
    cardBrand
    paymentMethod
  }
`;
export const OfflinePaymentParameterFragmentDoc = gql`
  fragment offlinePaymentParameter on PaymentParameter {
    id
    __typename
    parameterId
    cardBrandId
    paymentMethod
    cardBrand {
      id
      __typename
      name
      code
    }
  }
`;
export const OfflinePaymentFragmentDoc = gql`
  fragment offlinePayment on OfflinePayment {
    id
    cardBrand
    cardName
  }
`;
export const ProductChosenQuantityFragmentDoc = gql`
  fragment productChosenQuantity on Product {
    id
    __typename
    chosenQuantity @client
    scrollToProduct @client
  }
`;
export const ProductClientPropsFragmentDoc = gql`
  fragment productClientProps on Product {
    __typename
    id
    chosenQuantity @client
    isValid @client
    totalPrice @client
  }
`;
export const ProductIsValidFragmentDoc = gql`
  fragment productIsValid on Product {
    id
    __typename
    isValid @client
  }
`;
export const ProductPriceFragmentDoc = gql`
  fragment productPrice on Product {
    __typename
    id
    numericalId
    totalPrice @client
  }
`;
export const ProductFragmentDoc = gql`
  fragment product on Product {
    __typename
    id
    numericalId
    name
    productType
    fullDescription
    shortDescription
    minimumChoices
    maximumChoices
    hidden
    offer
    category {
      id
      name
    }
    chosenQuantity @client
    isValid @client
    totalPrice @client
    hasChoosableSubproducts @client
    scrollToProduct @client
    productCompanyByCompanyId {
      id
      __typename
      price
      unavailable
      hidden
    }
  }
`;
export const ProductAndSubproductsFragmentDoc = gql`
  fragment productAndSubproducts on Product {
    ...product
    subproducts {
      __typename
      id
    }
  }
  ${ProductFragmentDoc}
`;
export const ProductWithDescriptionAndSubproductsFragmentDoc = gql`
  fragment productWithDescriptionAndSubproducts on Product {
    ...productAndSubproducts
    images {
      __typename
      context
      url
    }
  }
  ${ProductAndSubproductsFragmentDoc}
`;
export const RemoteCardFragmentDoc = gql`
  fragment remoteCard on RemoteCard {
    id
    __typename
    backendId
    numericalId
    printedName
    maskedNumberFull
    expiration
    cardBrand
  }
`;
export const ScrollToProductFragmentDoc = gql`
  fragment scrollToProduct on Product {
    id
    __typename
    scrollToProduct
  }
`;
export const SelectedOrderTypeFragmentDoc = gql`
  fragment selectedOrderType on SelectedOrderType {
    id
    __typename
    orderType
    fieldValues {
      id
      __typename
      position
      value
      label
      numericalId
    }
  }
`;
export const ShoppingCartFragmentDoc = gql`
  fragment shoppingCart on ShoppingCart {
    id
    __typename
    totalProductsPrice
    totalExtras
    orderTotal
    products {
      ...productAndSubproducts
    }
  }
  ${ProductAndSubproductsFragmentDoc}
`;
export const CompanyDataFragmentDoc = gql`
  fragment companyData on Company {
    __typename
    id
    name
    numericalId
    onlineStatus
    highlight {
      topLabel
      centerLabel
      bottomLabel
      details
    }
    preparationTime {
      __typename
      minTime
      maxTime
      status
    }
    brand {
      id
      images {
        context
        url
      }
    }
    images {
      context
      url
    }
    companyPhone {
      id
      __typename
      number
    }
    address {
      id
      __typename
      street
      number
      complement
      city
    }
    openingTimes {
      ...timeRules
    }
    parameter {
      id
      paymentParameter {
        ...paymentParameter
      }
      offlinePaymentParameter {
        ...paymentParameter
      }
      orderTypes {
        id
        title
        description
        additionalPreparationTime
        orderFields {
          ...orderFieldParameter
        }
      }
    }
  }
  ${TimeRulesFragmentDoc}
  ${PaymentParameterFragmentDoc}
  ${OrderFieldParameterFragmentDoc}
`;
export const UserAddressFragmentDoc = gql`
  fragment userAddress on UserAddress {
    id
    __typename
    latitude
    longitude
    formattedAddress
    placeId
    complement
    fields {
      street
      number
      district
      state
      city
      zipCode
    }
  }
`;
export const WalletFragmentDoc = gql`
  fragment wallet on Wallet {
    id
    cardBrand
  }
`;
export const AcceptTermsAndConditionsDocument = gql`
  mutation AcceptTermsAndConditions {
    acceptTermsAndConditions @client {
      success
      errorMsg
    }
  }
`;
export type AcceptTermsAndConditionsMutationFn = Apollo.MutationFunction<
  AcceptTermsAndConditionsMutation,
  AcceptTermsAndConditionsMutationVariables
>;

/**
 * __useAcceptTermsAndConditionsMutation__
 *
 * To run a mutation, you first call `useAcceptTermsAndConditionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptTermsAndConditionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptTermsAndConditionsMutation, { data, loading, error }] = useAcceptTermsAndConditionsMutation({
 *   variables: {
 *   },
 * });
 */
export function useAcceptTermsAndConditionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptTermsAndConditionsMutation,
    AcceptTermsAndConditionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AcceptTermsAndConditionsMutation,
    AcceptTermsAndConditionsMutationVariables
  >(AcceptTermsAndConditionsDocument, options);
}
export type AcceptTermsAndConditionsMutationHookResult = ReturnType<
  typeof useAcceptTermsAndConditionsMutation
>;
export type AcceptTermsAndConditionsMutationResult =
  Apollo.MutationResult<AcceptTermsAndConditionsMutation>;
export type AcceptTermsAndConditionsMutationOptions = Apollo.BaseMutationOptions<
  AcceptTermsAndConditionsMutation,
  AcceptTermsAndConditionsMutationVariables
>;
export const AddLocalCardDocument = gql`
  mutation AddLocalCard($localCard: LocalCardInput!) {
    addLocalCard(localCard: $localCard) @client
  }
`;
export type AddLocalCardMutationFn = Apollo.MutationFunction<
  AddLocalCardMutation,
  AddLocalCardMutationVariables
>;

/**
 * __useAddLocalCardMutation__
 *
 * To run a mutation, you first call `useAddLocalCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLocalCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLocalCardMutation, { data, loading, error }] = useAddLocalCardMutation({
 *   variables: {
 *      localCard: // value for 'localCard'
 *   },
 * });
 */
export function useAddLocalCardMutation(
  baseOptions?: Apollo.MutationHookOptions<AddLocalCardMutation, AddLocalCardMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddLocalCardMutation, AddLocalCardMutationVariables>(
    AddLocalCardDocument,
    options
  );
}
export type AddLocalCardMutationHookResult = ReturnType<typeof useAddLocalCardMutation>;
export type AddLocalCardMutationResult = Apollo.MutationResult<AddLocalCardMutation>;
export type AddLocalCardMutationOptions = Apollo.BaseMutationOptions<
  AddLocalCardMutation,
  AddLocalCardMutationVariables
>;
export const AddOfflinePaymentDocument = gql`
  mutation AddOfflinePayment($cardBrand: String!, $cardName: String!) {
    addOfflinePayment(cardBrand: $cardBrand, cardName: $cardName) @client
  }
`;
export type AddOfflinePaymentMutationFn = Apollo.MutationFunction<
  AddOfflinePaymentMutation,
  AddOfflinePaymentMutationVariables
>;

/**
 * __useAddOfflinePaymentMutation__
 *
 * To run a mutation, you first call `useAddOfflinePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOfflinePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOfflinePaymentMutation, { data, loading, error }] = useAddOfflinePaymentMutation({
 *   variables: {
 *      cardBrand: // value for 'cardBrand'
 *      cardName: // value for 'cardName'
 *   },
 * });
 */
export function useAddOfflinePaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddOfflinePaymentMutation,
    AddOfflinePaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddOfflinePaymentMutation, AddOfflinePaymentMutationVariables>(
    AddOfflinePaymentDocument,
    options
  );
}
export type AddOfflinePaymentMutationHookResult = ReturnType<typeof useAddOfflinePaymentMutation>;
export type AddOfflinePaymentMutationResult = Apollo.MutationResult<AddOfflinePaymentMutation>;
export type AddOfflinePaymentMutationOptions = Apollo.BaseMutationOptions<
  AddOfflinePaymentMutation,
  AddOfflinePaymentMutationVariables
>;
export const AddToCartDocument = gql`
  mutation AddToCart($companyId: Int!, $productCacheId: String!, $productQuantity: Int!) {
    addToCart(
      companyId: $companyId
      productCacheId: $productCacheId
      productQuantity: $productQuantity
    ) @client
  }
`;
export type AddToCartMutationFn = Apollo.MutationFunction<
  AddToCartMutation,
  AddToCartMutationVariables
>;

/**
 * __useAddToCartMutation__
 *
 * To run a mutation, you first call `useAddToCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToCartMutation, { data, loading, error }] = useAddToCartMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      productCacheId: // value for 'productCacheId'
 *      productQuantity: // value for 'productQuantity'
 *   },
 * });
 */
export function useAddToCartMutation(
  baseOptions?: Apollo.MutationHookOptions<AddToCartMutation, AddToCartMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddToCartMutation, AddToCartMutationVariables>(
    AddToCartDocument,
    options
  );
}
export type AddToCartMutationHookResult = ReturnType<typeof useAddToCartMutation>;
export type AddToCartMutationResult = Apollo.MutationResult<AddToCartMutation>;
export type AddToCartMutationOptions = Apollo.BaseMutationOptions<
  AddToCartMutation,
  AddToCartMutationVariables
>;
export const CartPaymentFlowDocument = gql`
  query CartPaymentFlow {
    cartPaymentFlow @client {
      __typename
      status
      cardBrand
    }
  }
`;

/**
 * __useCartPaymentFlowQuery__
 *
 * To run a query within a React component, call `useCartPaymentFlowQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartPaymentFlowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartPaymentFlowQuery({
 *   variables: {
 *   },
 * });
 */
export function useCartPaymentFlowQuery(
  baseOptions?: Apollo.QueryHookOptions<CartPaymentFlowQuery, CartPaymentFlowQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CartPaymentFlowQuery, CartPaymentFlowQueryVariables>(
    CartPaymentFlowDocument,
    options
  );
}
export function useCartPaymentFlowLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CartPaymentFlowQuery, CartPaymentFlowQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CartPaymentFlowQuery, CartPaymentFlowQueryVariables>(
    CartPaymentFlowDocument,
    options
  );
}
export type CartPaymentFlowQueryHookResult = ReturnType<typeof useCartPaymentFlowQuery>;
export type CartPaymentFlowLazyQueryHookResult = ReturnType<typeof useCartPaymentFlowLazyQuery>;
export type CartPaymentFlowQueryResult = Apollo.QueryResult<
  CartPaymentFlowQuery,
  CartPaymentFlowQueryVariables
>;
export const ClearDeprecatedCacheDocument = gql`
  mutation ClearDeprecatedCache {
    clearDeprecatedCache @client
  }
`;
export type ClearDeprecatedCacheMutationFn = Apollo.MutationFunction<
  ClearDeprecatedCacheMutation,
  ClearDeprecatedCacheMutationVariables
>;

/**
 * __useClearDeprecatedCacheMutation__
 *
 * To run a mutation, you first call `useClearDeprecatedCacheMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearDeprecatedCacheMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearDeprecatedCacheMutation, { data, loading, error }] = useClearDeprecatedCacheMutation({
 *   variables: {
 *   },
 * });
 */
export function useClearDeprecatedCacheMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClearDeprecatedCacheMutation,
    ClearDeprecatedCacheMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ClearDeprecatedCacheMutation, ClearDeprecatedCacheMutationVariables>(
    ClearDeprecatedCacheDocument,
    options
  );
}
export type ClearDeprecatedCacheMutationHookResult = ReturnType<
  typeof useClearDeprecatedCacheMutation
>;
export type ClearDeprecatedCacheMutationResult =
  Apollo.MutationResult<ClearDeprecatedCacheMutation>;
export type ClearDeprecatedCacheMutationOptions = Apollo.BaseMutationOptions<
  ClearDeprecatedCacheMutation,
  ClearDeprecatedCacheMutationVariables
>;
export const CreateNewShoppingCartDocument = gql`
  mutation CreateNewShoppingCart($companyId: Int!) {
    createNewShoppingCart(companyId: $companyId) @client
  }
`;
export type CreateNewShoppingCartMutationFn = Apollo.MutationFunction<
  CreateNewShoppingCartMutation,
  CreateNewShoppingCartMutationVariables
>;

/**
 * __useCreateNewShoppingCartMutation__
 *
 * To run a mutation, you first call `useCreateNewShoppingCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewShoppingCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewShoppingCartMutation, { data, loading, error }] = useCreateNewShoppingCartMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCreateNewShoppingCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateNewShoppingCartMutation,
    CreateNewShoppingCartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateNewShoppingCartMutation, CreateNewShoppingCartMutationVariables>(
    CreateNewShoppingCartDocument,
    options
  );
}
export type CreateNewShoppingCartMutationHookResult = ReturnType<
  typeof useCreateNewShoppingCartMutation
>;
export type CreateNewShoppingCartMutationResult =
  Apollo.MutationResult<CreateNewShoppingCartMutation>;
export type CreateNewShoppingCartMutationOptions = Apollo.BaseMutationOptions<
  CreateNewShoppingCartMutation,
  CreateNewShoppingCartMutationVariables
>;
export const DecreaseChosenProductQuantityDocument = gql`
  mutation DecreaseChosenProductQuantity($input: ChangeProductQuantity!) {
    decreaseChosenProductQuantity(input: $input) @client
  }
`;
export type DecreaseChosenProductQuantityMutationFn = Apollo.MutationFunction<
  DecreaseChosenProductQuantityMutation,
  DecreaseChosenProductQuantityMutationVariables
>;

/**
 * __useDecreaseChosenProductQuantityMutation__
 *
 * To run a mutation, you first call `useDecreaseChosenProductQuantityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDecreaseChosenProductQuantityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [decreaseChosenProductQuantityMutation, { data, loading, error }] = useDecreaseChosenProductQuantityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDecreaseChosenProductQuantityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DecreaseChosenProductQuantityMutation,
    DecreaseChosenProductQuantityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DecreaseChosenProductQuantityMutation,
    DecreaseChosenProductQuantityMutationVariables
  >(DecreaseChosenProductQuantityDocument, options);
}
export type DecreaseChosenProductQuantityMutationHookResult = ReturnType<
  typeof useDecreaseChosenProductQuantityMutation
>;
export type DecreaseChosenProductQuantityMutationResult =
  Apollo.MutationResult<DecreaseChosenProductQuantityMutation>;
export type DecreaseChosenProductQuantityMutationOptions = Apollo.BaseMutationOptions<
  DecreaseChosenProductQuantityMutation,
  DecreaseChosenProductQuantityMutationVariables
>;
export const FlipChosenProductQuantityDocument = gql`
  mutation FlipChosenProductQuantity($input: ChangeProductQuantity!) {
    flipChosenProductQuantity(input: $input) @client
  }
`;
export type FlipChosenProductQuantityMutationFn = Apollo.MutationFunction<
  FlipChosenProductQuantityMutation,
  FlipChosenProductQuantityMutationVariables
>;

/**
 * __useFlipChosenProductQuantityMutation__
 *
 * To run a mutation, you first call `useFlipChosenProductQuantityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFlipChosenProductQuantityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [flipChosenProductQuantityMutation, { data, loading, error }] = useFlipChosenProductQuantityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFlipChosenProductQuantityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FlipChosenProductQuantityMutation,
    FlipChosenProductQuantityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FlipChosenProductQuantityMutation,
    FlipChosenProductQuantityMutationVariables
  >(FlipChosenProductQuantityDocument, options);
}
export type FlipChosenProductQuantityMutationHookResult = ReturnType<
  typeof useFlipChosenProductQuantityMutation
>;
export type FlipChosenProductQuantityMutationResult =
  Apollo.MutationResult<FlipChosenProductQuantityMutation>;
export type FlipChosenProductQuantityMutationOptions = Apollo.BaseMutationOptions<
  FlipChosenProductQuantityMutation,
  FlipChosenProductQuantityMutationVariables
>;
export const GetAggregatorCompaniesDocument = gql`
  query GetAggregatorCompanies(
    $aggregatorId: Int!
    $latitude: Float
    $longitude: Float
    $maximumDistanceKilometers: Float
    $filterBy: AggregatorFilterOption
    $orderType: [OrderTypeFilterOptionEnum]
    $first: Int
    $cursor: String
    $companyNameStartsWith: String
    $deliveryAddress: DeliveryAddressInput!
  ) {
    aggregator(
      aggregatorId: $aggregatorId
      latitude: $latitude
      longitude: $longitude
      maximumDistanceKilometers: $maximumDistanceKilometers
      filterBy: $filterBy
      orderType: $orderType
      companyNameStartsWith: $companyNameStartsWith
    ) {
      id
      __typename
      name
      numericalId
      address {
        __typename
        geoLat
        geoLon
      }
      images {
        __typename
        url
        context
      }
      companies(first: $first, after: $cursor) {
        edges {
          __typename
          cursor
          node {
            id
            __typename
            name
            displayName
            numericalId
            onlineStatus
            hidden
            isFullService
            shippingPrice(deliveryAddress: $deliveryAddress) {
              value
              label
            }
            brand {
              id
              __typename
              numericalId
              images {
                __typename
                context
                url
              }
            }
            images {
              __typename
              context
              url
            }
            openingTimes {
              ...timeRules
            }
            highlight {
              __typename
              numericalId
              topLabel
              centerLabel
              bottomLabel
            }
            preparationTime {
              __typename
              minTime
              maxTime
              status
            }
            parameter {
              id
              __typename
              paymentParameter {
                ...paymentParameter
              }
              orderTypes {
                __typename
                id
                title
                description
                additionalPreparationTime
                additionalChargeParameter {
                  additionalType
                  additionalValue
                }
                orderFields {
                  ...orderFieldParameter
                }
              }
            }
          }
        }
      }
    }
  }
  ${TimeRulesFragmentDoc}
  ${PaymentParameterFragmentDoc}
  ${OrderFieldParameterFragmentDoc}
`;

/**
 * __useGetAggregatorCompaniesQuery__
 *
 * To run a query within a React component, call `useGetAggregatorCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAggregatorCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAggregatorCompaniesQuery({
 *   variables: {
 *      aggregatorId: // value for 'aggregatorId'
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *      maximumDistanceKilometers: // value for 'maximumDistanceKilometers'
 *      filterBy: // value for 'filterBy'
 *      orderType: // value for 'orderType'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *      companyNameStartsWith: // value for 'companyNameStartsWith'
 *      deliveryAddress: // value for 'deliveryAddress'
 *   },
 * });
 */
export function useGetAggregatorCompaniesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAggregatorCompaniesQuery,
    GetAggregatorCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAggregatorCompaniesQuery, GetAggregatorCompaniesQueryVariables>(
    GetAggregatorCompaniesDocument,
    options
  );
}
export function useGetAggregatorCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAggregatorCompaniesQuery,
    GetAggregatorCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAggregatorCompaniesQuery, GetAggregatorCompaniesQueryVariables>(
    GetAggregatorCompaniesDocument,
    options
  );
}
export type GetAggregatorCompaniesQueryHookResult = ReturnType<
  typeof useGetAggregatorCompaniesQuery
>;
export type GetAggregatorCompaniesLazyQueryHookResult = ReturnType<
  typeof useGetAggregatorCompaniesLazyQuery
>;
export type GetAggregatorCompaniesQueryResult = Apollo.QueryResult<
  GetAggregatorCompaniesQuery,
  GetAggregatorCompaniesQueryVariables
>;
export const GetAggregatorImagesDocument = gql`
  query GetAggregatorImages($aggregatorId: Int!) {
    aggregator(aggregatorId: $aggregatorId) {
      id
      __typename
      name
      numericalId
      images {
        __typename
        url
        context
      }
    }
  }
`;

/**
 * __useGetAggregatorImagesQuery__
 *
 * To run a query within a React component, call `useGetAggregatorImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAggregatorImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAggregatorImagesQuery({
 *   variables: {
 *      aggregatorId: // value for 'aggregatorId'
 *   },
 * });
 */
export function useGetAggregatorImagesQuery(
  baseOptions: Apollo.QueryHookOptions<GetAggregatorImagesQuery, GetAggregatorImagesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAggregatorImagesQuery, GetAggregatorImagesQueryVariables>(
    GetAggregatorImagesDocument,
    options
  );
}
export function useGetAggregatorImagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAggregatorImagesQuery,
    GetAggregatorImagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAggregatorImagesQuery, GetAggregatorImagesQueryVariables>(
    GetAggregatorImagesDocument,
    options
  );
}
export type GetAggregatorImagesQueryHookResult = ReturnType<typeof useGetAggregatorImagesQuery>;
export type GetAggregatorImagesLazyQueryHookResult = ReturnType<
  typeof useGetAggregatorImagesLazyQuery
>;
export type GetAggregatorImagesQueryResult = Apollo.QueryResult<
  GetAggregatorImagesQuery,
  GetAggregatorImagesQueryVariables
>;
export const GetAggregatorNameDocument = gql`
  query GetAggregatorName($aggregatorId: Int!, $companyGroupId: Int) {
    aggregator(companyGroupId: $companyGroupId, aggregatorId: $aggregatorId) {
      id
      __typename
      name
      numericalId
    }
  }
`;

/**
 * __useGetAggregatorNameQuery__
 *
 * To run a query within a React component, call `useGetAggregatorNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAggregatorNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAggregatorNameQuery({
 *   variables: {
 *      aggregatorId: // value for 'aggregatorId'
 *      companyGroupId: // value for 'companyGroupId'
 *   },
 * });
 */
export function useGetAggregatorNameQuery(
  baseOptions: Apollo.QueryHookOptions<GetAggregatorNameQuery, GetAggregatorNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAggregatorNameQuery, GetAggregatorNameQueryVariables>(
    GetAggregatorNameDocument,
    options
  );
}
export function useGetAggregatorNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAggregatorNameQuery, GetAggregatorNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAggregatorNameQuery, GetAggregatorNameQueryVariables>(
    GetAggregatorNameDocument,
    options
  );
}
export type GetAggregatorNameQueryHookResult = ReturnType<typeof useGetAggregatorNameQuery>;
export type GetAggregatorNameLazyQueryHookResult = ReturnType<typeof useGetAggregatorNameLazyQuery>;
export type GetAggregatorNameQueryResult = Apollo.QueryResult<
  GetAggregatorNameQuery,
  GetAggregatorNameQueryVariables
>;
export const GetAllShoppingCartsDocument = gql`
  query GetAllShoppingCarts {
    shoppingCarts {
      ...shoppingCart
    }
  }
  ${ShoppingCartFragmentDoc}
`;

/**
 * __useGetAllShoppingCartsQuery__
 *
 * To run a query within a React component, call `useGetAllShoppingCartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllShoppingCartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllShoppingCartsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllShoppingCartsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllShoppingCartsQuery, GetAllShoppingCartsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllShoppingCartsQuery, GetAllShoppingCartsQueryVariables>(
    GetAllShoppingCartsDocument,
    options
  );
}
export function useGetAllShoppingCartsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllShoppingCartsQuery,
    GetAllShoppingCartsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllShoppingCartsQuery, GetAllShoppingCartsQueryVariables>(
    GetAllShoppingCartsDocument,
    options
  );
}
export type GetAllShoppingCartsQueryHookResult = ReturnType<typeof useGetAllShoppingCartsQuery>;
export type GetAllShoppingCartsLazyQueryHookResult = ReturnType<
  typeof useGetAllShoppingCartsLazyQuery
>;
export type GetAllShoppingCartsQueryResult = Apollo.QueryResult<
  GetAllShoppingCartsQuery,
  GetAllShoppingCartsQueryVariables
>;
export const GetBrandBalanceDocument = gql`
  query GetBrandBalance($brandId: Int!) {
    brandBalance(brandId: $brandId) @client {
      id
      __typename
      expiringDate
      expiringPoints
      balancePoints
      reference
      user {
        id
        __typename
        numericalId
        firstName
        lastName
        telephone
        email
        birthday
        gender
        validationCellphone
        document {
          id
          __typename
          numericalId
          documentType
          data
        }
      }
    }
  }
`;

/**
 * __useGetBrandBalanceQuery__
 *
 * To run a query within a React component, call `useGetBrandBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandBalanceQuery({
 *   variables: {
 *      brandId: // value for 'brandId'
 *   },
 * });
 */
export function useGetBrandBalanceQuery(
  baseOptions: Apollo.QueryHookOptions<GetBrandBalanceQuery, GetBrandBalanceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBrandBalanceQuery, GetBrandBalanceQueryVariables>(
    GetBrandBalanceDocument,
    options
  );
}
export function useGetBrandBalanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBrandBalanceQuery, GetBrandBalanceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBrandBalanceQuery, GetBrandBalanceQueryVariables>(
    GetBrandBalanceDocument,
    options
  );
}
export type GetBrandBalanceQueryHookResult = ReturnType<typeof useGetBrandBalanceQuery>;
export type GetBrandBalanceLazyQueryHookResult = ReturnType<typeof useGetBrandBalanceLazyQuery>;
export type GetBrandBalanceQueryResult = Apollo.QueryResult<
  GetBrandBalanceQuery,
  GetBrandBalanceQueryVariables
>;
export const GetBrandThemeDocument = gql`
  query GetBrandTheme($brandId: Int!) {
    brandTheme(brandId: $brandId) {
      id
      brandThemeColor {
        key
        value
      }
    }
  }
`;

/**
 * __useGetBrandThemeQuery__
 *
 * To run a query within a React component, call `useGetBrandThemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandThemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandThemeQuery({
 *   variables: {
 *      brandId: // value for 'brandId'
 *   },
 * });
 */
export function useGetBrandThemeQuery(
  baseOptions: Apollo.QueryHookOptions<GetBrandThemeQuery, GetBrandThemeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBrandThemeQuery, GetBrandThemeQueryVariables>(
    GetBrandThemeDocument,
    options
  );
}
export function useGetBrandThemeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBrandThemeQuery, GetBrandThemeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBrandThemeQuery, GetBrandThemeQueryVariables>(
    GetBrandThemeDocument,
    options
  );
}
export type GetBrandThemeQueryHookResult = ReturnType<typeof useGetBrandThemeQuery>;
export type GetBrandThemeLazyQueryHookResult = ReturnType<typeof useGetBrandThemeLazyQuery>;
export type GetBrandThemeQueryResult = Apollo.QueryResult<
  GetBrandThemeQuery,
  GetBrandThemeQueryVariables
>;
export const GetCardsDocument = gql`
  query GetCards($companyId: Int) {
    cards(companyId: $companyId) @client {
      __typename
      id
      numericalId
      backendId
      printedName
      maskedNumber
      maskedNumberFull
      expiration
      status
      cardBrand
    }
  }
`;

/**
 * __useGetCardsQuery__
 *
 * To run a query within a React component, call `useGetCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCardsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCardsQuery, GetCardsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCardsQuery, GetCardsQueryVariables>(GetCardsDocument, options);
}
export function useGetCardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCardsQuery, GetCardsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCardsQuery, GetCardsQueryVariables>(GetCardsDocument, options);
}
export type GetCardsQueryHookResult = ReturnType<typeof useGetCardsQuery>;
export type GetCardsLazyQueryHookResult = ReturnType<typeof useGetCardsLazyQuery>;
export type GetCardsQueryResult = Apollo.QueryResult<GetCardsQuery, GetCardsQueryVariables>;
export const GetCompaniesGroupsByAggregatorsDocument = gql`
  query GetCompaniesGroupsByAggregators(
    $companyGroupId: Int!
    $latitude: Float!
    $longitude: Float!
    $maximumDistanceKilometers: Float!
    $filterBy: AggregatorFilterOption
    $first: Int!
    $after: String!
    $companyNameStartsWith: String
  ) {
    aggregators(
      companyGroupId: $companyGroupId
      latitude: $latitude
      longitude: $longitude
      maximumDistanceKilometers: $maximumDistanceKilometers
      filterBy: $filterBy
      first: $first
      after: $after
      companyNameStartsWith: $companyNameStartsWith
    ) {
      __typename
      pageInfo {
        __typename
        hasNextPage
        endCursor
      }
      edges {
        __typename
        node {
          __typename
          name
          numericalId
          isEvent
          address {
            __typename
            geoLat
            geoLon
          }
          images {
            __typename
            context
            url
          }
          companies {
            __typename
            edges {
              ...aggregatorCompanies
            }
          }
        }
      }
    }
    defaultBrandTheme {
      __typename
      brandThemeMessage {
        __typename
        key
        value
      }
    }
  }
  ${AggregatorCompaniesFragmentDoc}
`;

/**
 * __useGetCompaniesGroupsByAggregatorsQuery__
 *
 * To run a query within a React component, call `useGetCompaniesGroupsByAggregatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesGroupsByAggregatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesGroupsByAggregatorsQuery({
 *   variables: {
 *      companyGroupId: // value for 'companyGroupId'
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *      maximumDistanceKilometers: // value for 'maximumDistanceKilometers'
 *      filterBy: // value for 'filterBy'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      companyNameStartsWith: // value for 'companyNameStartsWith'
 *   },
 * });
 */
export function useGetCompaniesGroupsByAggregatorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompaniesGroupsByAggregatorsQuery,
    GetCompaniesGroupsByAggregatorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompaniesGroupsByAggregatorsQuery,
    GetCompaniesGroupsByAggregatorsQueryVariables
  >(GetCompaniesGroupsByAggregatorsDocument, options);
}
export function useGetCompaniesGroupsByAggregatorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompaniesGroupsByAggregatorsQuery,
    GetCompaniesGroupsByAggregatorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompaniesGroupsByAggregatorsQuery,
    GetCompaniesGroupsByAggregatorsQueryVariables
  >(GetCompaniesGroupsByAggregatorsDocument, options);
}
export type GetCompaniesGroupsByAggregatorsQueryHookResult = ReturnType<
  typeof useGetCompaniesGroupsByAggregatorsQuery
>;
export type GetCompaniesGroupsByAggregatorsLazyQueryHookResult = ReturnType<
  typeof useGetCompaniesGroupsByAggregatorsLazyQuery
>;
export type GetCompaniesGroupsByAggregatorsQueryResult = Apollo.QueryResult<
  GetCompaniesGroupsByAggregatorsQuery,
  GetCompaniesGroupsByAggregatorsQueryVariables
>;
export const GetCompanyAddressDocument = gql`
  query GetCompanyAddress($companyId: Int!) {
    company(companyId: $companyId) {
      id
      __typename
      address {
        id
        __typename
        street
        number
        complement
        city
      }
    }
  }
`;

/**
 * __useGetCompanyAddressQuery__
 *
 * To run a query within a React component, call `useGetCompanyAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyAddressQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyAddressQuery(
  baseOptions: Apollo.QueryHookOptions<GetCompanyAddressQuery, GetCompanyAddressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanyAddressQuery, GetCompanyAddressQueryVariables>(
    GetCompanyAddressDocument,
    options
  );
}
export function useGetCompanyAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyAddressQuery, GetCompanyAddressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompanyAddressQuery, GetCompanyAddressQueryVariables>(
    GetCompanyAddressDocument,
    options
  );
}
export type GetCompanyAddressQueryHookResult = ReturnType<typeof useGetCompanyAddressQuery>;
export type GetCompanyAddressLazyQueryHookResult = ReturnType<typeof useGetCompanyAddressLazyQuery>;
export type GetCompanyAddressQueryResult = Apollo.QueryResult<
  GetCompanyAddressQuery,
  GetCompanyAddressQueryVariables
>;
export const GetCompanyDataDocument = gql`
  query getCompanyData($companyId: Int!) {
    company(companyId: $companyId) {
      ...companyData
    }
    productCompanies(
      companyId: $companyId
      productType: MENU_ITEM
      productHidden: false
      unavailable: false
    ) {
      edges {
        node {
          id
          price
          unavailable
          numericalId
          companyId
          product {
            id
            numericalId
            name
            sequence
            category {
              id
              numericalId
              name
              order
            }
            offer
            shortDescription
            images {
              context
              url
            }
          }
        }
      }
    }
  }
  ${CompanyDataFragmentDoc}
`;

/**
 * __useGetCompanyDataQuery__
 *
 * To run a query within a React component, call `useGetCompanyDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyDataQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyDataQuery(
  baseOptions: Apollo.QueryHookOptions<GetCompanyDataQuery, GetCompanyDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanyDataQuery, GetCompanyDataQueryVariables>(
    GetCompanyDataDocument,
    options
  );
}
export function useGetCompanyDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyDataQuery, GetCompanyDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompanyDataQuery, GetCompanyDataQueryVariables>(
    GetCompanyDataDocument,
    options
  );
}
export type GetCompanyDataQueryHookResult = ReturnType<typeof useGetCompanyDataQuery>;
export type GetCompanyDataLazyQueryHookResult = ReturnType<typeof useGetCompanyDataLazyQuery>;
export type GetCompanyDataQueryResult = Apollo.QueryResult<
  GetCompanyDataQuery,
  GetCompanyDataQueryVariables
>;
export const GetCompanyImagesDocument = gql`
  query GetCompanyImages($companyId: Int!) {
    company(companyId: $companyId) {
      id
      name
      brand {
        id
        images {
          context
          url
        }
      }
    }
  }
`;

/**
 * __useGetCompanyImagesQuery__
 *
 * To run a query within a React component, call `useGetCompanyImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyImagesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyImagesQuery(
  baseOptions: Apollo.QueryHookOptions<GetCompanyImagesQuery, GetCompanyImagesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanyImagesQuery, GetCompanyImagesQueryVariables>(
    GetCompanyImagesDocument,
    options
  );
}
export function useGetCompanyImagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyImagesQuery, GetCompanyImagesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompanyImagesQuery, GetCompanyImagesQueryVariables>(
    GetCompanyImagesDocument,
    options
  );
}
export type GetCompanyImagesQueryHookResult = ReturnType<typeof useGetCompanyImagesQuery>;
export type GetCompanyImagesLazyQueryHookResult = ReturnType<typeof useGetCompanyImagesLazyQuery>;
export type GetCompanyImagesQueryResult = Apollo.QueryResult<
  GetCompanyImagesQuery,
  GetCompanyImagesQueryVariables
>;
export const GetCompanyOnlineStatusDocument = gql`
  query getCompanyOnlineStatus($companyId: Int!) {
    company(companyId: $companyId) {
      id
      __typename
      onlineStatus
    }
  }
`;

/**
 * __useGetCompanyOnlineStatusQuery__
 *
 * To run a query within a React component, call `useGetCompanyOnlineStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyOnlineStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyOnlineStatusQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyOnlineStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyOnlineStatusQuery,
    GetCompanyOnlineStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanyOnlineStatusQuery, GetCompanyOnlineStatusQueryVariables>(
    GetCompanyOnlineStatusDocument,
    options
  );
}
export function useGetCompanyOnlineStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyOnlineStatusQuery,
    GetCompanyOnlineStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompanyOnlineStatusQuery, GetCompanyOnlineStatusQueryVariables>(
    GetCompanyOnlineStatusDocument,
    options
  );
}
export type GetCompanyOnlineStatusQueryHookResult = ReturnType<
  typeof useGetCompanyOnlineStatusQuery
>;
export type GetCompanyOnlineStatusLazyQueryHookResult = ReturnType<
  typeof useGetCompanyOnlineStatusLazyQuery
>;
export type GetCompanyOnlineStatusQueryResult = Apollo.QueryResult<
  GetCompanyOnlineStatusQuery,
  GetCompanyOnlineStatusQueryVariables
>;
export const GetCompanyOrderTypesDocument = gql`
  query GetCompanyOrderTypes($companyId: Int!) {
    company(companyId: $companyId) {
      __typename
      id
      parameter {
        __typename
        id
        orderTypes {
          __typename
          id
          title
          description
          orderFields {
            ...orderFieldParameter
          }
        }
      }
    }
  }
  ${OrderFieldParameterFragmentDoc}
`;

/**
 * __useGetCompanyOrderTypesQuery__
 *
 * To run a query within a React component, call `useGetCompanyOrderTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyOrderTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyOrderTypesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyOrderTypesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyOrderTypesQuery,
    GetCompanyOrderTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanyOrderTypesQuery, GetCompanyOrderTypesQueryVariables>(
    GetCompanyOrderTypesDocument,
    options
  );
}
export function useGetCompanyOrderTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyOrderTypesQuery,
    GetCompanyOrderTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompanyOrderTypesQuery, GetCompanyOrderTypesQueryVariables>(
    GetCompanyOrderTypesDocument,
    options
  );
}
export type GetCompanyOrderTypesQueryHookResult = ReturnType<typeof useGetCompanyOrderTypesQuery>;
export type GetCompanyOrderTypesLazyQueryHookResult = ReturnType<
  typeof useGetCompanyOrderTypesLazyQuery
>;
export type GetCompanyOrderTypesQueryResult = Apollo.QueryResult<
  GetCompanyOrderTypesQuery,
  GetCompanyOrderTypesQueryVariables
>;
export const GetCompanyPaymentMethodsDocument = gql`
  query GetCompanyPaymentMethods($companyId: Int!) {
    company(companyId: $companyId) {
      id
      __typename
      name
      brand {
        id
        __typename
        images {
          __typename
          context
          url
        }
      }
      parameter {
        id
        __typename
        paymentParameter {
          ...paymentParameter
        }
        offlinePaymentParameter {
          ...offlinePaymentParameter
        }
      }
    }
  }
  ${PaymentParameterFragmentDoc}
  ${OfflinePaymentParameterFragmentDoc}
`;

/**
 * __useGetCompanyPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useGetCompanyPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyPaymentMethodsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyPaymentMethodsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyPaymentMethodsQuery,
    GetCompanyPaymentMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanyPaymentMethodsQuery, GetCompanyPaymentMethodsQueryVariables>(
    GetCompanyPaymentMethodsDocument,
    options
  );
}
export function useGetCompanyPaymentMethodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyPaymentMethodsQuery,
    GetCompanyPaymentMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompanyPaymentMethodsQuery, GetCompanyPaymentMethodsQueryVariables>(
    GetCompanyPaymentMethodsDocument,
    options
  );
}
export type GetCompanyPaymentMethodsQueryHookResult = ReturnType<
  typeof useGetCompanyPaymentMethodsQuery
>;
export type GetCompanyPaymentMethodsLazyQueryHookResult = ReturnType<
  typeof useGetCompanyPaymentMethodsLazyQuery
>;
export type GetCompanyPaymentMethodsQueryResult = Apollo.QueryResult<
  GetCompanyPaymentMethodsQuery,
  GetCompanyPaymentMethodsQueryVariables
>;
export const GetCompanyPaymentParamsDocument = gql`
  query GetCompanyPaymentParams($companyId: Int!) {
    company(companyId: $companyId) {
      id
      __typename
      parameter {
        id
        __typename
        paymentParameter {
          ...paymentParameter
        }
        offlinePaymentParameter {
          ...offlinePaymentParameter
        }
      }
    }
  }
  ${PaymentParameterFragmentDoc}
  ${OfflinePaymentParameterFragmentDoc}
`;

/**
 * __useGetCompanyPaymentParamsQuery__
 *
 * To run a query within a React component, call `useGetCompanyPaymentParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyPaymentParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyPaymentParamsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCompanyPaymentParamsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyPaymentParamsQuery,
    GetCompanyPaymentParamsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanyPaymentParamsQuery, GetCompanyPaymentParamsQueryVariables>(
    GetCompanyPaymentParamsDocument,
    options
  );
}
export function useGetCompanyPaymentParamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyPaymentParamsQuery,
    GetCompanyPaymentParamsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompanyPaymentParamsQuery, GetCompanyPaymentParamsQueryVariables>(
    GetCompanyPaymentParamsDocument,
    options
  );
}
export type GetCompanyPaymentParamsQueryHookResult = ReturnType<
  typeof useGetCompanyPaymentParamsQuery
>;
export type GetCompanyPaymentParamsLazyQueryHookResult = ReturnType<
  typeof useGetCompanyPaymentParamsLazyQuery
>;
export type GetCompanyPaymentParamsQueryResult = Apollo.QueryResult<
  GetCompanyPaymentParamsQuery,
  GetCompanyPaymentParamsQueryVariables
>;
export const GetCouponsDocument = gql`
  query GetCoupons {
    coupons @client {
      __typename
      telephoneValidated
      loading
      nodes {
        code
        value
        expiration
        type
        brand {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useGetCouponsQuery__
 *
 * To run a query within a React component, call `useGetCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCouponsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCouponsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCouponsQuery, GetCouponsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCouponsQuery, GetCouponsQueryVariables>(GetCouponsDocument, options);
}
export function useGetCouponsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCouponsQuery, GetCouponsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCouponsQuery, GetCouponsQueryVariables>(
    GetCouponsDocument,
    options
  );
}
export type GetCouponsQueryHookResult = ReturnType<typeof useGetCouponsQuery>;
export type GetCouponsLazyQueryHookResult = ReturnType<typeof useGetCouponsLazyQuery>;
export type GetCouponsQueryResult = Apollo.QueryResult<GetCouponsQuery, GetCouponsQueryVariables>;
export const GetCustomerDataDocument = gql`
  query GetCustomerData {
    customerData @client {
      ...customerData
    }
  }
  ${CustomerDataFragmentDoc}
`;

/**
 * __useGetCustomerDataQuery__
 *
 * To run a query within a React component, call `useGetCustomerDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomerDataQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCustomerDataQuery, GetCustomerDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomerDataQuery, GetCustomerDataQueryVariables>(
    GetCustomerDataDocument,
    options
  );
}
export function useGetCustomerDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerDataQuery, GetCustomerDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCustomerDataQuery, GetCustomerDataQueryVariables>(
    GetCustomerDataDocument,
    options
  );
}
export type GetCustomerDataQueryHookResult = ReturnType<typeof useGetCustomerDataQuery>;
export type GetCustomerDataLazyQueryHookResult = ReturnType<typeof useGetCustomerDataLazyQuery>;
export type GetCustomerDataQueryResult = Apollo.QueryResult<
  GetCustomerDataQuery,
  GetCustomerDataQueryVariables
>;
export const GetCvvCriptoKeyDocument = gql`
  query GetCVVCriptoKey($brandId: Int!) {
    brandBalance(brandId: $brandId) @client {
      id
      __typename
      reference
    }
  }
`;

/**
 * __useGetCvvCriptoKeyQuery__
 *
 * To run a query within a React component, call `useGetCvvCriptoKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCvvCriptoKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCvvCriptoKeyQuery({
 *   variables: {
 *      brandId: // value for 'brandId'
 *   },
 * });
 */
export function useGetCvvCriptoKeyQuery(
  baseOptions: Apollo.QueryHookOptions<GetCvvCriptoKeyQuery, GetCvvCriptoKeyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCvvCriptoKeyQuery, GetCvvCriptoKeyQueryVariables>(
    GetCvvCriptoKeyDocument,
    options
  );
}
export function useGetCvvCriptoKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCvvCriptoKeyQuery, GetCvvCriptoKeyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCvvCriptoKeyQuery, GetCvvCriptoKeyQueryVariables>(
    GetCvvCriptoKeyDocument,
    options
  );
}
export type GetCvvCriptoKeyQueryHookResult = ReturnType<typeof useGetCvvCriptoKeyQuery>;
export type GetCvvCriptoKeyLazyQueryHookResult = ReturnType<typeof useGetCvvCriptoKeyLazyQuery>;
export type GetCvvCriptoKeyQueryResult = Apollo.QueryResult<
  GetCvvCriptoKeyQuery,
  GetCvvCriptoKeyQueryVariables
>;
export const GetDeviceMetadataDocument = gql`
  query GetDeviceMetadata {
    deviceMetadata @client
  }
`;

/**
 * __useGetDeviceMetadataQuery__
 *
 * To run a query within a React component, call `useGetDeviceMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceMetadataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDeviceMetadataQuery(
  baseOptions?: Apollo.QueryHookOptions<GetDeviceMetadataQuery, GetDeviceMetadataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDeviceMetadataQuery, GetDeviceMetadataQueryVariables>(
    GetDeviceMetadataDocument,
    options
  );
}
export function useGetDeviceMetadataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDeviceMetadataQuery, GetDeviceMetadataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDeviceMetadataQuery, GetDeviceMetadataQueryVariables>(
    GetDeviceMetadataDocument,
    options
  );
}
export type GetDeviceMetadataQueryHookResult = ReturnType<typeof useGetDeviceMetadataQuery>;
export type GetDeviceMetadataLazyQueryHookResult = ReturnType<typeof useGetDeviceMetadataLazyQuery>;
export type GetDeviceMetadataQueryResult = Apollo.QueryResult<
  GetDeviceMetadataQuery,
  GetDeviceMetadataQueryVariables
>;
export const GetLastOrdersDocument = gql`
  query GetLastOrders($fromDatetime: DateTime!, $brand: Int, $limit: Int) {
    lastOrders(fromDatetime: $fromDatetime, brand: $brand, first: $limit) {
      __typename
      edges {
        node {
          id
          __typename
          status
          orderType
          displayCode
          preparationTimeMinimum
          preparationTimeMaximum
          creationDatetime
          companyId
        }
      }
    }
  }
`;

/**
 * __useGetLastOrdersQuery__
 *
 * To run a query within a React component, call `useGetLastOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastOrdersQuery({
 *   variables: {
 *      fromDatetime: // value for 'fromDatetime'
 *      brand: // value for 'brand'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetLastOrdersQuery(
  baseOptions: Apollo.QueryHookOptions<GetLastOrdersQuery, GetLastOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLastOrdersQuery, GetLastOrdersQueryVariables>(
    GetLastOrdersDocument,
    options
  );
}
export function useGetLastOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLastOrdersQuery, GetLastOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLastOrdersQuery, GetLastOrdersQueryVariables>(
    GetLastOrdersDocument,
    options
  );
}
export type GetLastOrdersQueryHookResult = ReturnType<typeof useGetLastOrdersQuery>;
export type GetLastOrdersLazyQueryHookResult = ReturnType<typeof useGetLastOrdersLazyQuery>;
export type GetLastOrdersQueryResult = Apollo.QueryResult<
  GetLastOrdersQuery,
  GetLastOrdersQueryVariables
>;
export const GetLocalCardDocument = gql`
  query GetLocalCard {
    localCard @client {
      id
      __typename
      cardNumber
      cardName
      cardExpiration
      birthday
      cardCvv
      cardBrand
      paymentMethod
    }
  }
`;

/**
 * __useGetLocalCardQuery__
 *
 * To run a query within a React component, call `useGetLocalCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocalCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocalCardQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocalCardQuery(
  baseOptions?: Apollo.QueryHookOptions<GetLocalCardQuery, GetLocalCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLocalCardQuery, GetLocalCardQueryVariables>(
    GetLocalCardDocument,
    options
  );
}
export function useGetLocalCardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLocalCardQuery, GetLocalCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLocalCardQuery, GetLocalCardQueryVariables>(
    GetLocalCardDocument,
    options
  );
}
export type GetLocalCardQueryHookResult = ReturnType<typeof useGetLocalCardQuery>;
export type GetLocalCardLazyQueryHookResult = ReturnType<typeof useGetLocalCardLazyQuery>;
export type GetLocalCardQueryResult = Apollo.QueryResult<
  GetLocalCardQuery,
  GetLocalCardQueryVariables
>;
export const GetOfflinePaymentDocument = gql`
  query GetOfflinePayment {
    offlinePayment @client {
      id
      __typename
      cardBrand
      cardName
    }
  }
`;

/**
 * __useGetOfflinePaymentQuery__
 *
 * To run a query within a React component, call `useGetOfflinePaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfflinePaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfflinePaymentQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOfflinePaymentQuery(
  baseOptions?: Apollo.QueryHookOptions<GetOfflinePaymentQuery, GetOfflinePaymentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOfflinePaymentQuery, GetOfflinePaymentQueryVariables>(
    GetOfflinePaymentDocument,
    options
  );
}
export function useGetOfflinePaymentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOfflinePaymentQuery, GetOfflinePaymentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOfflinePaymentQuery, GetOfflinePaymentQueryVariables>(
    GetOfflinePaymentDocument,
    options
  );
}
export type GetOfflinePaymentQueryHookResult = ReturnType<typeof useGetOfflinePaymentQuery>;
export type GetOfflinePaymentLazyQueryHookResult = ReturnType<typeof useGetOfflinePaymentLazyQuery>;
export type GetOfflinePaymentQueryResult = Apollo.QueryResult<
  GetOfflinePaymentQuery,
  GetOfflinePaymentQueryVariables
>;
export const GetOrLoadSelectedPaymentMethodDocument = gql`
  mutation GetOrLoadSelectedPaymentMethod($companyId: Int) {
    getOrLoadSelectedPaymentMethod(companyId: $companyId) @client {
      __typename
      ...remoteCard
      ...localCard
      ...wallet
    }
  }
  ${RemoteCardFragmentDoc}
  ${LocalCardFragmentDoc}
  ${WalletFragmentDoc}
`;
export type GetOrLoadSelectedPaymentMethodMutationFn = Apollo.MutationFunction<
  GetOrLoadSelectedPaymentMethodMutation,
  GetOrLoadSelectedPaymentMethodMutationVariables
>;

/**
 * __useGetOrLoadSelectedPaymentMethodMutation__
 *
 * To run a mutation, you first call `useGetOrLoadSelectedPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetOrLoadSelectedPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getOrLoadSelectedPaymentMethodMutation, { data, loading, error }] = useGetOrLoadSelectedPaymentMethodMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetOrLoadSelectedPaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetOrLoadSelectedPaymentMethodMutation,
    GetOrLoadSelectedPaymentMethodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GetOrLoadSelectedPaymentMethodMutation,
    GetOrLoadSelectedPaymentMethodMutationVariables
  >(GetOrLoadSelectedPaymentMethodDocument, options);
}
export type GetOrLoadSelectedPaymentMethodMutationHookResult = ReturnType<
  typeof useGetOrLoadSelectedPaymentMethodMutation
>;
export type GetOrLoadSelectedPaymentMethodMutationResult =
  Apollo.MutationResult<GetOrLoadSelectedPaymentMethodMutation>;
export type GetOrLoadSelectedPaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  GetOrLoadSelectedPaymentMethodMutation,
  GetOrLoadSelectedPaymentMethodMutationVariables
>;
export const GetOrderCheckErrorsDocument = gql`
  query GetOrderCheckErrors {
    orderCheck @client {
      warning
      error {
        message
      }
    }
  }
`;

/**
 * __useGetOrderCheckErrorsQuery__
 *
 * To run a query within a React component, call `useGetOrderCheckErrorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderCheckErrorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderCheckErrorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrderCheckErrorsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetOrderCheckErrorsQuery, GetOrderCheckErrorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrderCheckErrorsQuery, GetOrderCheckErrorsQueryVariables>(
    GetOrderCheckErrorsDocument,
    options
  );
}
export function useGetOrderCheckErrorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrderCheckErrorsQuery,
    GetOrderCheckErrorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrderCheckErrorsQuery, GetOrderCheckErrorsQueryVariables>(
    GetOrderCheckErrorsDocument,
    options
  );
}
export type GetOrderCheckErrorsQueryHookResult = ReturnType<typeof useGetOrderCheckErrorsQuery>;
export type GetOrderCheckErrorsLazyQueryHookResult = ReturnType<
  typeof useGetOrderCheckErrorsLazyQuery
>;
export type GetOrderCheckErrorsQueryResult = Apollo.QueryResult<
  GetOrderCheckErrorsQuery,
  GetOrderCheckErrorsQueryVariables
>;
export const GetOrderCheckExtrasDocument = gql`
  query GetOrderCheckExtras {
    orderCheck @client {
      extras {
        key
        value
        label
        optional
      }
    }
  }
`;

/**
 * __useGetOrderCheckExtrasQuery__
 *
 * To run a query within a React component, call `useGetOrderCheckExtrasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderCheckExtrasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderCheckExtrasQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrderCheckExtrasQuery(
  baseOptions?: Apollo.QueryHookOptions<GetOrderCheckExtrasQuery, GetOrderCheckExtrasQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrderCheckExtrasQuery, GetOrderCheckExtrasQueryVariables>(
    GetOrderCheckExtrasDocument,
    options
  );
}
export function useGetOrderCheckExtrasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrderCheckExtrasQuery,
    GetOrderCheckExtrasQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrderCheckExtrasQuery, GetOrderCheckExtrasQueryVariables>(
    GetOrderCheckExtrasDocument,
    options
  );
}
export type GetOrderCheckExtrasQueryHookResult = ReturnType<typeof useGetOrderCheckExtrasQuery>;
export type GetOrderCheckExtrasLazyQueryHookResult = ReturnType<
  typeof useGetOrderCheckExtrasLazyQuery
>;
export type GetOrderCheckExtrasQueryResult = Apollo.QueryResult<
  GetOrderCheckExtrasQuery,
  GetOrderCheckExtrasQueryVariables
>;
export const GetOrderCheckFromCacheDocument = gql`
  query GetOrderCheckFromCache {
    orderCheck @client {
      id
      __typename
      minimumPreparationTime
      maximumPreparationTime
      extras {
        id
        __typename
        optional
        key
        value
        label
      }
      warning
      error {
        __typename
        id
        type
        message
      }
    }
  }
`;

/**
 * __useGetOrderCheckFromCacheQuery__
 *
 * To run a query within a React component, call `useGetOrderCheckFromCacheQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderCheckFromCacheQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderCheckFromCacheQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrderCheckFromCacheQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrderCheckFromCacheQuery,
    GetOrderCheckFromCacheQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrderCheckFromCacheQuery, GetOrderCheckFromCacheQueryVariables>(
    GetOrderCheckFromCacheDocument,
    options
  );
}
export function useGetOrderCheckFromCacheLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrderCheckFromCacheQuery,
    GetOrderCheckFromCacheQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrderCheckFromCacheQuery, GetOrderCheckFromCacheQueryVariables>(
    GetOrderCheckFromCacheDocument,
    options
  );
}
export type GetOrderCheckFromCacheQueryHookResult = ReturnType<
  typeof useGetOrderCheckFromCacheQuery
>;
export type GetOrderCheckFromCacheLazyQueryHookResult = ReturnType<
  typeof useGetOrderCheckFromCacheLazyQuery
>;
export type GetOrderCheckFromCacheQueryResult = Apollo.QueryResult<
  GetOrderCheckFromCacheQuery,
  GetOrderCheckFromCacheQueryVariables
>;
export const GetOrderCheckPreparationTimeDocument = gql`
  query GetOrderCheckPreparationTime {
    orderCheck @client {
      minimumPreparationTime
      maximumPreparationTime
    }
  }
`;

/**
 * __useGetOrderCheckPreparationTimeQuery__
 *
 * To run a query within a React component, call `useGetOrderCheckPreparationTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderCheckPreparationTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderCheckPreparationTimeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrderCheckPreparationTimeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrderCheckPreparationTimeQuery,
    GetOrderCheckPreparationTimeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOrderCheckPreparationTimeQuery,
    GetOrderCheckPreparationTimeQueryVariables
  >(GetOrderCheckPreparationTimeDocument, options);
}
export function useGetOrderCheckPreparationTimeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrderCheckPreparationTimeQuery,
    GetOrderCheckPreparationTimeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrderCheckPreparationTimeQuery,
    GetOrderCheckPreparationTimeQueryVariables
  >(GetOrderCheckPreparationTimeDocument, options);
}
export type GetOrderCheckPreparationTimeQueryHookResult = ReturnType<
  typeof useGetOrderCheckPreparationTimeQuery
>;
export type GetOrderCheckPreparationTimeLazyQueryHookResult = ReturnType<
  typeof useGetOrderCheckPreparationTimeLazyQuery
>;
export type GetOrderCheckPreparationTimeQueryResult = Apollo.QueryResult<
  GetOrderCheckPreparationTimeQuery,
  GetOrderCheckPreparationTimeQueryVariables
>;
export const GetOrderDataDocument = gql`
  query GetOrderData($orderId: Int!) {
    order(id: $orderId) {
      id
      __typename
      status
      orderType
      displayCode
      preparationTimeMinimum
      preparationTimeMaximum
      creationDatetime
      companyId
      displayCode
      sequenceNumber
      totalValue
      payableValue
      discountPercentual
      taxPercentual
      taxValue
      discountValue
      subsidyValue
      received
      tableReference
      orderExtra {
        key
        optional
        label
        value
      }
      address {
        street
        number
        zipCode
      }
      payments {
        method
        value
        card {
          maskedNumber
          paymentMethod
          cardBrand {
            name
            code
          }
        }
      }
      items {
        parentId
        name
        unitPrice
        productType
        numericalId
        offer
        itemId
      }
      __typename
      orderType
    }
  }
`;

/**
 * __useGetOrderDataQuery__
 *
 * To run a query within a React component, call `useGetOrderDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderDataQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetOrderDataQuery(
  baseOptions: Apollo.QueryHookOptions<GetOrderDataQuery, GetOrderDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrderDataQuery, GetOrderDataQueryVariables>(
    GetOrderDataDocument,
    options
  );
}
export function useGetOrderDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOrderDataQuery, GetOrderDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrderDataQuery, GetOrderDataQueryVariables>(
    GetOrderDataDocument,
    options
  );
}
export type GetOrderDataQueryHookResult = ReturnType<typeof useGetOrderDataQuery>;
export type GetOrderDataLazyQueryHookResult = ReturnType<typeof useGetOrderDataLazyQuery>;
export type GetOrderDataQueryResult = Apollo.QueryResult<
  GetOrderDataQuery,
  GetOrderDataQueryVariables
>;
export const GetOrderTotalDocument = gql`
  query GetOrderTotal($companyId: Int!) {
    shoppingCart(companyId: $companyId) @client {
      totalProductsPrice
      totalExtras
      orderTotal
    }
    orderCheck @client {
      extras {
        id
        key
        optional
        value
        label
      }
    }
  }
`;

/**
 * __useGetOrderTotalQuery__
 *
 * To run a query within a React component, call `useGetOrderTotalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderTotalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderTotalQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetOrderTotalQuery(
  baseOptions: Apollo.QueryHookOptions<GetOrderTotalQuery, GetOrderTotalQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrderTotalQuery, GetOrderTotalQueryVariables>(
    GetOrderTotalDocument,
    options
  );
}
export function useGetOrderTotalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOrderTotalQuery, GetOrderTotalQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrderTotalQuery, GetOrderTotalQueryVariables>(
    GetOrderTotalDocument,
    options
  );
}
export type GetOrderTotalQueryHookResult = ReturnType<typeof useGetOrderTotalQuery>;
export type GetOrderTotalLazyQueryHookResult = ReturnType<typeof useGetOrderTotalLazyQuery>;
export type GetOrderTotalQueryResult = Apollo.QueryResult<
  GetOrderTotalQuery,
  GetOrderTotalQueryVariables
>;
export const GetOrdersDocument = gql`
  query GetOrders {
    orders @client {
      id
      status
      orderType
      companyName
      company
      creationDatetime
      displayCode
      items {
        product
        name
        quantity
      }
    }
  }
`;

/**
 * __useGetOrdersQuery__
 *
 * To run a query within a React component, call `useGetOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrdersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
}
export function useGetOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
}
export type GetOrdersQueryHookResult = ReturnType<typeof useGetOrdersQuery>;
export type GetOrdersLazyQueryHookResult = ReturnType<typeof useGetOrdersLazyQuery>;
export type GetOrdersQueryResult = Apollo.QueryResult<GetOrdersQuery, GetOrdersQueryVariables>;
export const GetProductDetailsDocument = gql`
  query GetProductDetails($productId: Int!, $companyId: Int!) {
    productDetails(productId: $productId, companyId: $companyId) {
      ...product
      images {
        context
        url
      }
      subproducts {
        ...product
        subproducts {
          ...product
          subproducts {
            ...product
            subproducts {
              ...product
              subproducts {
                ...product
                subproducts {
                  ...product
                  subproducts {
                    ...product
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${ProductFragmentDoc}
`;

/**
 * __useGetProductDetailsQuery__
 *
 * To run a query within a React component, call `useGetProductDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductDetailsQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetProductDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<GetProductDetailsQuery, GetProductDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductDetailsQuery, GetProductDetailsQueryVariables>(
    GetProductDetailsDocument,
    options
  );
}
export function useGetProductDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProductDetailsQuery, GetProductDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductDetailsQuery, GetProductDetailsQueryVariables>(
    GetProductDetailsDocument,
    options
  );
}
export type GetProductDetailsQueryHookResult = ReturnType<typeof useGetProductDetailsQuery>;
export type GetProductDetailsLazyQueryHookResult = ReturnType<typeof useGetProductDetailsLazyQuery>;
export type GetProductDetailsQueryResult = Apollo.QueryResult<
  GetProductDetailsQuery,
  GetProductDetailsQueryVariables
>;
export const GetSelectedOrderTypeDocument = gql`
  query GetSelectedOrderType {
    selectedOrderType @client {
      ...selectedOrderType
    }
  }
  ${SelectedOrderTypeFragmentDoc}
`;

/**
 * __useGetSelectedOrderTypeQuery__
 *
 * To run a query within a React component, call `useGetSelectedOrderTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelectedOrderTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelectedOrderTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSelectedOrderTypeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSelectedOrderTypeQuery,
    GetSelectedOrderTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSelectedOrderTypeQuery, GetSelectedOrderTypeQueryVariables>(
    GetSelectedOrderTypeDocument,
    options
  );
}
export function useGetSelectedOrderTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSelectedOrderTypeQuery,
    GetSelectedOrderTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSelectedOrderTypeQuery, GetSelectedOrderTypeQueryVariables>(
    GetSelectedOrderTypeDocument,
    options
  );
}
export type GetSelectedOrderTypeQueryHookResult = ReturnType<typeof useGetSelectedOrderTypeQuery>;
export type GetSelectedOrderTypeLazyQueryHookResult = ReturnType<
  typeof useGetSelectedOrderTypeLazyQuery
>;
export type GetSelectedOrderTypeQueryResult = Apollo.QueryResult<
  GetSelectedOrderTypeQuery,
  GetSelectedOrderTypeQueryVariables
>;
export const GetSelectedPaymentMethodFromCacheDocument = gql`
  query GetSelectedPaymentMethodFromCache {
    selectedPaymentMethod @client {
      __typename
      ...remoteCard
      ...localCard
      ...wallet
      ...offlinePayment
    }
  }
  ${RemoteCardFragmentDoc}
  ${LocalCardFragmentDoc}
  ${WalletFragmentDoc}
  ${OfflinePaymentFragmentDoc}
`;

/**
 * __useGetSelectedPaymentMethodFromCacheQuery__
 *
 * To run a query within a React component, call `useGetSelectedPaymentMethodFromCacheQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelectedPaymentMethodFromCacheQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelectedPaymentMethodFromCacheQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSelectedPaymentMethodFromCacheQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSelectedPaymentMethodFromCacheQuery,
    GetSelectedPaymentMethodFromCacheQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSelectedPaymentMethodFromCacheQuery,
    GetSelectedPaymentMethodFromCacheQueryVariables
  >(GetSelectedPaymentMethodFromCacheDocument, options);
}
export function useGetSelectedPaymentMethodFromCacheLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSelectedPaymentMethodFromCacheQuery,
    GetSelectedPaymentMethodFromCacheQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSelectedPaymentMethodFromCacheQuery,
    GetSelectedPaymentMethodFromCacheQueryVariables
  >(GetSelectedPaymentMethodFromCacheDocument, options);
}
export type GetSelectedPaymentMethodFromCacheQueryHookResult = ReturnType<
  typeof useGetSelectedPaymentMethodFromCacheQuery
>;
export type GetSelectedPaymentMethodFromCacheLazyQueryHookResult = ReturnType<
  typeof useGetSelectedPaymentMethodFromCacheLazyQuery
>;
export type GetSelectedPaymentMethodFromCacheQueryResult = Apollo.QueryResult<
  GetSelectedPaymentMethodFromCacheQuery,
  GetSelectedPaymentMethodFromCacheQueryVariables
>;
export const SelectedTabDocument = gql`
  query SelectedTab {
    selectedTab @client
  }
`;

/**
 * __useSelectedTabQuery__
 *
 * To run a query within a React component, call `useSelectedTabQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectedTabQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectedTabQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelectedTabQuery(
  baseOptions?: Apollo.QueryHookOptions<SelectedTabQuery, SelectedTabQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SelectedTabQuery, SelectedTabQueryVariables>(SelectedTabDocument, options);
}
export function useSelectedTabLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SelectedTabQuery, SelectedTabQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SelectedTabQuery, SelectedTabQueryVariables>(
    SelectedTabDocument,
    options
  );
}
export type SelectedTabQueryHookResult = ReturnType<typeof useSelectedTabQuery>;
export type SelectedTabLazyQueryHookResult = ReturnType<typeof useSelectedTabLazyQuery>;
export type SelectedTabQueryResult = Apollo.QueryResult<
  SelectedTabQuery,
  SelectedTabQueryVariables
>;
export const GetShoppingCartProductsDocument = gql`
  query GetShoppingCartProducts($companyId: Int!) {
    shoppingCartProducts(companyId: $companyId) @client {
      id
      name
      numericalId
      totalPrice
      subproductsDescription
    }
  }
`;

/**
 * __useGetShoppingCartProductsQuery__
 *
 * To run a query within a React component, call `useGetShoppingCartProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShoppingCartProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShoppingCartProductsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetShoppingCartProductsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetShoppingCartProductsQuery,
    GetShoppingCartProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetShoppingCartProductsQuery, GetShoppingCartProductsQueryVariables>(
    GetShoppingCartProductsDocument,
    options
  );
}
export function useGetShoppingCartProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetShoppingCartProductsQuery,
    GetShoppingCartProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetShoppingCartProductsQuery, GetShoppingCartProductsQueryVariables>(
    GetShoppingCartProductsDocument,
    options
  );
}
export type GetShoppingCartProductsQueryHookResult = ReturnType<
  typeof useGetShoppingCartProductsQuery
>;
export type GetShoppingCartProductsLazyQueryHookResult = ReturnType<
  typeof useGetShoppingCartProductsLazyQuery
>;
export type GetShoppingCartProductsQueryResult = Apollo.QueryResult<
  GetShoppingCartProductsQuery,
  GetShoppingCartProductsQueryVariables
>;
export const GetShoppingCartDocument = gql`
  query GetShoppingCart($companyId: Int!) {
    shoppingCart(companyId: $companyId) @client {
      ...shoppingCart
    }
  }
  ${ShoppingCartFragmentDoc}
`;

/**
 * __useGetShoppingCartQuery__
 *
 * To run a query within a React component, call `useGetShoppingCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShoppingCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShoppingCartQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetShoppingCartQuery(
  baseOptions: Apollo.QueryHookOptions<GetShoppingCartQuery, GetShoppingCartQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetShoppingCartQuery, GetShoppingCartQueryVariables>(
    GetShoppingCartDocument,
    options
  );
}
export function useGetShoppingCartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetShoppingCartQuery, GetShoppingCartQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetShoppingCartQuery, GetShoppingCartQueryVariables>(
    GetShoppingCartDocument,
    options
  );
}
export type GetShoppingCartQueryHookResult = ReturnType<typeof useGetShoppingCartQuery>;
export type GetShoppingCartLazyQueryHookResult = ReturnType<typeof useGetShoppingCartLazyQuery>;
export type GetShoppingCartQueryResult = Apollo.QueryResult<
  GetShoppingCartQuery,
  GetShoppingCartQueryVariables
>;
export const GetUserAddressDocument = gql`
  query GetUserAddress {
    userAddress @client {
      ...userAddress
    }
  }
  ${UserAddressFragmentDoc}
`;

/**
 * __useGetUserAddressQuery__
 *
 * To run a query within a React component, call `useGetUserAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAddressQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserAddressQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserAddressQuery, GetUserAddressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserAddressQuery, GetUserAddressQueryVariables>(
    GetUserAddressDocument,
    options
  );
}
export function useGetUserAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserAddressQuery, GetUserAddressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserAddressQuery, GetUserAddressQueryVariables>(
    GetUserAddressDocument,
    options
  );
}
export type GetUserAddressQueryHookResult = ReturnType<typeof useGetUserAddressQuery>;
export type GetUserAddressLazyQueryHookResult = ReturnType<typeof useGetUserAddressLazyQuery>;
export type GetUserAddressQueryResult = Apollo.QueryResult<
  GetUserAddressQuery,
  GetUserAddressQueryVariables
>;
export const IncreaseChosenProductQuantityDocument = gql`
  mutation IncreaseChosenProductQuantity($input: ChangeProductQuantity!) {
    increaseChosenProductQuantity(input: $input) @client
  }
`;
export type IncreaseChosenProductQuantityMutationFn = Apollo.MutationFunction<
  IncreaseChosenProductQuantityMutation,
  IncreaseChosenProductQuantityMutationVariables
>;

/**
 * __useIncreaseChosenProductQuantityMutation__
 *
 * To run a mutation, you first call `useIncreaseChosenProductQuantityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncreaseChosenProductQuantityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [increaseChosenProductQuantityMutation, { data, loading, error }] = useIncreaseChosenProductQuantityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIncreaseChosenProductQuantityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IncreaseChosenProductQuantityMutation,
    IncreaseChosenProductQuantityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IncreaseChosenProductQuantityMutation,
    IncreaseChosenProductQuantityMutationVariables
  >(IncreaseChosenProductQuantityDocument, options);
}
export type IncreaseChosenProductQuantityMutationHookResult = ReturnType<
  typeof useIncreaseChosenProductQuantityMutation
>;
export type IncreaseChosenProductQuantityMutationResult =
  Apollo.MutationResult<IncreaseChosenProductQuantityMutation>;
export type IncreaseChosenProductQuantityMutationOptions = Apollo.BaseMutationOptions<
  IncreaseChosenProductQuantityMutation,
  IncreaseChosenProductQuantityMutationVariables
>;
export const IsAuthenticatedDocument = gql`
  query IsAuthenticated {
    isAuthenticated @client
    authExecuted @client
  }
`;

/**
 * __useIsAuthenticatedQuery__
 *
 * To run a query within a React component, call `useIsAuthenticatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsAuthenticatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsAuthenticatedQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsAuthenticatedQuery(
  baseOptions?: Apollo.QueryHookOptions<IsAuthenticatedQuery, IsAuthenticatedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IsAuthenticatedQuery, IsAuthenticatedQueryVariables>(
    IsAuthenticatedDocument,
    options
  );
}
export function useIsAuthenticatedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IsAuthenticatedQuery, IsAuthenticatedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IsAuthenticatedQuery, IsAuthenticatedQueryVariables>(
    IsAuthenticatedDocument,
    options
  );
}
export type IsAuthenticatedQueryHookResult = ReturnType<typeof useIsAuthenticatedQuery>;
export type IsAuthenticatedLazyQueryHookResult = ReturnType<typeof useIsAuthenticatedLazyQuery>;
export type IsAuthenticatedQueryResult = Apollo.QueryResult<
  IsAuthenticatedQuery,
  IsAuthenticatedQueryVariables
>;
export const IsEmbeddedDocument = gql`
  query IsEmbedded {
    isEmbedded @client
  }
`;

/**
 * __useIsEmbeddedQuery__
 *
 * To run a query within a React component, call `useIsEmbeddedQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsEmbeddedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsEmbeddedQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsEmbeddedQuery(
  baseOptions?: Apollo.QueryHookOptions<IsEmbeddedQuery, IsEmbeddedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IsEmbeddedQuery, IsEmbeddedQueryVariables>(IsEmbeddedDocument, options);
}
export function useIsEmbeddedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IsEmbeddedQuery, IsEmbeddedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IsEmbeddedQuery, IsEmbeddedQueryVariables>(
    IsEmbeddedDocument,
    options
  );
}
export type IsEmbeddedQueryHookResult = ReturnType<typeof useIsEmbeddedQuery>;
export type IsEmbeddedLazyQueryHookResult = ReturnType<typeof useIsEmbeddedLazyQuery>;
export type IsEmbeddedQueryResult = Apollo.QueryResult<IsEmbeddedQuery, IsEmbeddedQueryVariables>;
export const IsExecutingPaymentCallDocument = gql`
  query IsExecutingPaymentCall {
    isExecutingPaymentCall @client
  }
`;

/**
 * __useIsExecutingPaymentCallQuery__
 *
 * To run a query within a React component, call `useIsExecutingPaymentCallQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsExecutingPaymentCallQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsExecutingPaymentCallQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsExecutingPaymentCallQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IsExecutingPaymentCallQuery,
    IsExecutingPaymentCallQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IsExecutingPaymentCallQuery, IsExecutingPaymentCallQueryVariables>(
    IsExecutingPaymentCallDocument,
    options
  );
}
export function useIsExecutingPaymentCallLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IsExecutingPaymentCallQuery,
    IsExecutingPaymentCallQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IsExecutingPaymentCallQuery, IsExecutingPaymentCallQueryVariables>(
    IsExecutingPaymentCallDocument,
    options
  );
}
export type IsExecutingPaymentCallQueryHookResult = ReturnType<
  typeof useIsExecutingPaymentCallQuery
>;
export type IsExecutingPaymentCallLazyQueryHookResult = ReturnType<
  typeof useIsExecutingPaymentCallLazyQuery
>;
export type IsExecutingPaymentCallQueryResult = Apollo.QueryResult<
  IsExecutingPaymentCallQuery,
  IsExecutingPaymentCallQueryVariables
>;
export const IsGmapsReadyDocument = gql`
  query IsGmapsReady {
    isGmapsReady @client
  }
`;

/**
 * __useIsGmapsReadyQuery__
 *
 * To run a query within a React component, call `useIsGmapsReadyQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsGmapsReadyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsGmapsReadyQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsGmapsReadyQuery(
  baseOptions?: Apollo.QueryHookOptions<IsGmapsReadyQuery, IsGmapsReadyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IsGmapsReadyQuery, IsGmapsReadyQueryVariables>(
    IsGmapsReadyDocument,
    options
  );
}
export function useIsGmapsReadyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IsGmapsReadyQuery, IsGmapsReadyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IsGmapsReadyQuery, IsGmapsReadyQueryVariables>(
    IsGmapsReadyDocument,
    options
  );
}
export type IsGmapsReadyQueryHookResult = ReturnType<typeof useIsGmapsReadyQuery>;
export type IsGmapsReadyLazyQueryHookResult = ReturnType<typeof useIsGmapsReadyLazyQuery>;
export type IsGmapsReadyQueryResult = Apollo.QueryResult<
  IsGmapsReadyQuery,
  IsGmapsReadyQueryVariables
>;
export const LoadSelectedOrderTypeFromBrowserCacheDocument = gql`
  mutation LoadSelectedOrderTypeFromBrowserCache {
    loadSelectedOrderTypeFromBrowserCache @client
  }
`;
export type LoadSelectedOrderTypeFromBrowserCacheMutationFn = Apollo.MutationFunction<
  LoadSelectedOrderTypeFromBrowserCacheMutation,
  LoadSelectedOrderTypeFromBrowserCacheMutationVariables
>;

/**
 * __useLoadSelectedOrderTypeFromBrowserCacheMutation__
 *
 * To run a mutation, you first call `useLoadSelectedOrderTypeFromBrowserCacheMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoadSelectedOrderTypeFromBrowserCacheMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loadSelectedOrderTypeFromBrowserCacheMutation, { data, loading, error }] = useLoadSelectedOrderTypeFromBrowserCacheMutation({
 *   variables: {
 *   },
 * });
 */
export function useLoadSelectedOrderTypeFromBrowserCacheMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoadSelectedOrderTypeFromBrowserCacheMutation,
    LoadSelectedOrderTypeFromBrowserCacheMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LoadSelectedOrderTypeFromBrowserCacheMutation,
    LoadSelectedOrderTypeFromBrowserCacheMutationVariables
  >(LoadSelectedOrderTypeFromBrowserCacheDocument, options);
}
export type LoadSelectedOrderTypeFromBrowserCacheMutationHookResult = ReturnType<
  typeof useLoadSelectedOrderTypeFromBrowserCacheMutation
>;
export type LoadSelectedOrderTypeFromBrowserCacheMutationResult =
  Apollo.MutationResult<LoadSelectedOrderTypeFromBrowserCacheMutation>;
export type LoadSelectedOrderTypeFromBrowserCacheMutationOptions = Apollo.BaseMutationOptions<
  LoadSelectedOrderTypeFromBrowserCacheMutation,
  LoadSelectedOrderTypeFromBrowserCacheMutationVariables
>;
export const LoadShoppingCartsFromBrowserCacheDocument = gql`
  mutation LoadShoppingCartsFromBrowserCache($cacheName: String) {
    loadShoppingCartsFromBrowserCache(cacheName: $cacheName) @client
  }
`;
export type LoadShoppingCartsFromBrowserCacheMutationFn = Apollo.MutationFunction<
  LoadShoppingCartsFromBrowserCacheMutation,
  LoadShoppingCartsFromBrowserCacheMutationVariables
>;

/**
 * __useLoadShoppingCartsFromBrowserCacheMutation__
 *
 * To run a mutation, you first call `useLoadShoppingCartsFromBrowserCacheMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoadShoppingCartsFromBrowserCacheMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loadShoppingCartsFromBrowserCacheMutation, { data, loading, error }] = useLoadShoppingCartsFromBrowserCacheMutation({
 *   variables: {
 *      cacheName: // value for 'cacheName'
 *   },
 * });
 */
export function useLoadShoppingCartsFromBrowserCacheMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoadShoppingCartsFromBrowserCacheMutation,
    LoadShoppingCartsFromBrowserCacheMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LoadShoppingCartsFromBrowserCacheMutation,
    LoadShoppingCartsFromBrowserCacheMutationVariables
  >(LoadShoppingCartsFromBrowserCacheDocument, options);
}
export type LoadShoppingCartsFromBrowserCacheMutationHookResult = ReturnType<
  typeof useLoadShoppingCartsFromBrowserCacheMutation
>;
export type LoadShoppingCartsFromBrowserCacheMutationResult =
  Apollo.MutationResult<LoadShoppingCartsFromBrowserCacheMutation>;
export type LoadShoppingCartsFromBrowserCacheMutationOptions = Apollo.BaseMutationOptions<
  LoadShoppingCartsFromBrowserCacheMutation,
  LoadShoppingCartsFromBrowserCacheMutationVariables
>;
export const LoadUserAddressFromBrowserCacheDocument = gql`
  mutation LoadUserAddressFromBrowserCache($latitude: Float, $longitude: Float) {
    loadUserAddressFromBrowserCache(latitude: $latitude, longitude: $longitude) @client
  }
`;
export type LoadUserAddressFromBrowserCacheMutationFn = Apollo.MutationFunction<
  LoadUserAddressFromBrowserCacheMutation,
  LoadUserAddressFromBrowserCacheMutationVariables
>;

/**
 * __useLoadUserAddressFromBrowserCacheMutation__
 *
 * To run a mutation, you first call `useLoadUserAddressFromBrowserCacheMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoadUserAddressFromBrowserCacheMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loadUserAddressFromBrowserCacheMutation, { data, loading, error }] = useLoadUserAddressFromBrowserCacheMutation({
 *   variables: {
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *   },
 * });
 */
export function useLoadUserAddressFromBrowserCacheMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoadUserAddressFromBrowserCacheMutation,
    LoadUserAddressFromBrowserCacheMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LoadUserAddressFromBrowserCacheMutation,
    LoadUserAddressFromBrowserCacheMutationVariables
  >(LoadUserAddressFromBrowserCacheDocument, options);
}
export type LoadUserAddressFromBrowserCacheMutationHookResult = ReturnType<
  typeof useLoadUserAddressFromBrowserCacheMutation
>;
export type LoadUserAddressFromBrowserCacheMutationResult =
  Apollo.MutationResult<LoadUserAddressFromBrowserCacheMutation>;
export type LoadUserAddressFromBrowserCacheMutationOptions = Apollo.BaseMutationOptions<
  LoadUserAddressFromBrowserCacheMutation,
  LoadUserAddressFromBrowserCacheMutationVariables
>;
export const LoginSocialDocument = gql`
  mutation loginSocial($input: LoginSocialInput!) {
    loginSocial(input: $input) @client {
      success
      errorMsg
    }
  }
`;
export type LoginSocialMutationFn = Apollo.MutationFunction<
  LoginSocialMutation,
  LoginSocialMutationVariables
>;

/**
 * __useLoginSocialMutation__
 *
 * To run a mutation, you first call `useLoginSocialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginSocialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginSocialMutation, { data, loading, error }] = useLoginSocialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginSocialMutation(
  baseOptions?: Apollo.MutationHookOptions<LoginSocialMutation, LoginSocialMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginSocialMutation, LoginSocialMutationVariables>(
    LoginSocialDocument,
    options
  );
}
export type LoginSocialMutationHookResult = ReturnType<typeof useLoginSocialMutation>;
export type LoginSocialMutationResult = Apollo.MutationResult<LoginSocialMutation>;
export type LoginSocialMutationOptions = Apollo.BaseMutationOptions<
  LoginSocialMutation,
  LoginSocialMutationVariables
>;
export const LoginDocument = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) @client {
      success
      errorMsg
    }
  }
`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const LogoutDocument = gql`
  mutation Logout {
    logout @client
  }
`;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<
  LogoutMutation,
  LogoutMutationVariables
>;
export const MagicLinkCreateDocument = gql`
  mutation magicLinkCreate($input: MagicLinkCreateInput!) {
    magicLinkCreate(input: $input) @client {
      success
      alreadyExist
      errorMsg
    }
  }
`;
export type MagicLinkCreateMutationFn = Apollo.MutationFunction<
  MagicLinkCreateMutation,
  MagicLinkCreateMutationVariables
>;

/**
 * __useMagicLinkCreateMutation__
 *
 * To run a mutation, you first call `useMagicLinkCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMagicLinkCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [magicLinkCreateMutation, { data, loading, error }] = useMagicLinkCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMagicLinkCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MagicLinkCreateMutation,
    MagicLinkCreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MagicLinkCreateMutation, MagicLinkCreateMutationVariables>(
    MagicLinkCreateDocument,
    options
  );
}
export type MagicLinkCreateMutationHookResult = ReturnType<typeof useMagicLinkCreateMutation>;
export type MagicLinkCreateMutationResult = Apollo.MutationResult<MagicLinkCreateMutation>;
export type MagicLinkCreateMutationOptions = Apollo.BaseMutationOptions<
  MagicLinkCreateMutation,
  MagicLinkCreateMutationVariables
>;
export const MagicLinkLoginDocument = gql`
  mutation magicLinkLogin($input: MagicLinkLoginInput!) {
    magicLinkLogin(input: $input) @client {
      success
      errorMsg
    }
  }
`;
export type MagicLinkLoginMutationFn = Apollo.MutationFunction<
  MagicLinkLoginMutation,
  MagicLinkLoginMutationVariables
>;

/**
 * __useMagicLinkLoginMutation__
 *
 * To run a mutation, you first call `useMagicLinkLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMagicLinkLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [magicLinkLoginMutation, { data, loading, error }] = useMagicLinkLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMagicLinkLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<MagicLinkLoginMutation, MagicLinkLoginMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MagicLinkLoginMutation, MagicLinkLoginMutationVariables>(
    MagicLinkLoginDocument,
    options
  );
}
export type MagicLinkLoginMutationHookResult = ReturnType<typeof useMagicLinkLoginMutation>;
export type MagicLinkLoginMutationResult = Apollo.MutationResult<MagicLinkLoginMutation>;
export type MagicLinkLoginMutationOptions = Apollo.BaseMutationOptions<
  MagicLinkLoginMutation,
  MagicLinkLoginMutationVariables
>;
export const MiniAppLoginDocument = gql`
  mutation MiniAppLogin($input: MiniAppLoginInput!) {
    miniAppLogin(input: $input) @client {
      success
      errorMsg
    }
  }
`;
export type MiniAppLoginMutationFn = Apollo.MutationFunction<
  MiniAppLoginMutation,
  MiniAppLoginMutationVariables
>;

/**
 * __useMiniAppLoginMutation__
 *
 * To run a mutation, you first call `useMiniAppLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMiniAppLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [miniAppLoginMutation, { data, loading, error }] = useMiniAppLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMiniAppLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<MiniAppLoginMutation, MiniAppLoginMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MiniAppLoginMutation, MiniAppLoginMutationVariables>(
    MiniAppLoginDocument,
    options
  );
}
export type MiniAppLoginMutationHookResult = ReturnType<typeof useMiniAppLoginMutation>;
export type MiniAppLoginMutationResult = Apollo.MutationResult<MiniAppLoginMutation>;
export type MiniAppLoginMutationOptions = Apollo.BaseMutationOptions<
  MiniAppLoginMutation,
  MiniAppLoginMutationVariables
>;
export const NotifyDocument = gql`
  query Notify {
    notify @client {
      __typename
      status
      active
      message
    }
  }
`;

/**
 * __useNotifyQuery__
 *
 * To run a query within a React component, call `useNotifyQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotifyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotifyQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotifyQuery(
  baseOptions?: Apollo.QueryHookOptions<NotifyQuery, NotifyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NotifyQuery, NotifyQueryVariables>(NotifyDocument, options);
}
export function useNotifyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NotifyQuery, NotifyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NotifyQuery, NotifyQueryVariables>(NotifyDocument, options);
}
export type NotifyQueryHookResult = ReturnType<typeof useNotifyQuery>;
export type NotifyLazyQueryHookResult = ReturnType<typeof useNotifyLazyQuery>;
export type NotifyQueryResult = Apollo.QueryResult<NotifyQuery, NotifyQueryVariables>;
export const OrderCheckRequestDocument = gql`
  mutation OrderCheckRequest($companyId: Int!, $orderTypes: [String]) {
    orderCheckRequest(companyId: $companyId, orderTypes: $orderTypes) @client {
      success
      errorMsg
    }
  }
`;
export type OrderCheckRequestMutationFn = Apollo.MutationFunction<
  OrderCheckRequestMutation,
  OrderCheckRequestMutationVariables
>;

/**
 * __useOrderCheckRequestMutation__
 *
 * To run a mutation, you first call `useOrderCheckRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderCheckRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderCheckRequestMutation, { data, loading, error }] = useOrderCheckRequestMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      orderTypes: // value for 'orderTypes'
 *   },
 * });
 */
export function useOrderCheckRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderCheckRequestMutation,
    OrderCheckRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OrderCheckRequestMutation, OrderCheckRequestMutationVariables>(
    OrderCheckRequestDocument,
    options
  );
}
export type OrderCheckRequestMutationHookResult = ReturnType<typeof useOrderCheckRequestMutation>;
export type OrderCheckRequestMutationResult = Apollo.MutationResult<OrderCheckRequestMutation>;
export type OrderCheckRequestMutationOptions = Apollo.BaseMutationOptions<
  OrderCheckRequestMutation,
  OrderCheckRequestMutationVariables
>;
export const OrderPostRequestDocument = gql`
  mutation OrderPostRequest($input: OrderPostInput!) {
    orderPostRequest(input: $input) @client {
      success
      orderId
      errorMsg
    }
  }
`;
export type OrderPostRequestMutationFn = Apollo.MutationFunction<
  OrderPostRequestMutation,
  OrderPostRequestMutationVariables
>;

/**
 * __useOrderPostRequestMutation__
 *
 * To run a mutation, you first call `useOrderPostRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderPostRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderPostRequestMutation, { data, loading, error }] = useOrderPostRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrderPostRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderPostRequestMutation,
    OrderPostRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OrderPostRequestMutation, OrderPostRequestMutationVariables>(
    OrderPostRequestDocument,
    options
  );
}
export type OrderPostRequestMutationHookResult = ReturnType<typeof useOrderPostRequestMutation>;
export type OrderPostRequestMutationResult = Apollo.MutationResult<OrderPostRequestMutation>;
export type OrderPostRequestMutationOptions = Apollo.BaseMutationOptions<
  OrderPostRequestMutation,
  OrderPostRequestMutationVariables
>;
export const GetOrderStatusDocument = gql`
  query GetOrderStatus($input: OrderStatusInput!) {
    orderStatus(input: $input) @client {
      id
      __typename
      status
      numericalId
      shouldRequestCardNumber
      creationDatetime
      errorMsg
    }
  }
`;

/**
 * __useGetOrderStatusQuery__
 *
 * To run a query within a React component, call `useGetOrderStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderStatusQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOrderStatusQuery(
  baseOptions: Apollo.QueryHookOptions<GetOrderStatusQuery, GetOrderStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrderStatusQuery, GetOrderStatusQueryVariables>(
    GetOrderStatusDocument,
    options
  );
}
export function useGetOrderStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOrderStatusQuery, GetOrderStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrderStatusQuery, GetOrderStatusQueryVariables>(
    GetOrderStatusDocument,
    options
  );
}
export type GetOrderStatusQueryHookResult = ReturnType<typeof useGetOrderStatusQuery>;
export type GetOrderStatusLazyQueryHookResult = ReturnType<typeof useGetOrderStatusLazyQuery>;
export type GetOrderStatusQueryResult = Apollo.QueryResult<
  GetOrderStatusQuery,
  GetOrderStatusQueryVariables
>;
export const PartnerLoginDocument = gql`
  mutation PartnerLogin($token: String!) {
    partnerLogin(token: $token) @client {
      success
      errorMsg
    }
  }
`;
export type PartnerLoginMutationFn = Apollo.MutationFunction<
  PartnerLoginMutation,
  PartnerLoginMutationVariables
>;

/**
 * __usePartnerLoginMutation__
 *
 * To run a mutation, you first call `usePartnerLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePartnerLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [partnerLoginMutation, { data, loading, error }] = usePartnerLoginMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function usePartnerLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<PartnerLoginMutation, PartnerLoginMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PartnerLoginMutation, PartnerLoginMutationVariables>(
    PartnerLoginDocument,
    options
  );
}
export type PartnerLoginMutationHookResult = ReturnType<typeof usePartnerLoginMutation>;
export type PartnerLoginMutationResult = Apollo.MutationResult<PartnerLoginMutation>;
export type PartnerLoginMutationOptions = Apollo.BaseMutationOptions<
  PartnerLoginMutation,
  PartnerLoginMutationVariables
>;
export const PreOrderPostValidationDocument = gql`
  mutation PreOrderPostValidation($companyId: Int!) {
    preOrderPostValidation(companyId: $companyId) @client {
      success
      errorMsg
    }
  }
`;
export type PreOrderPostValidationMutationFn = Apollo.MutationFunction<
  PreOrderPostValidationMutation,
  PreOrderPostValidationMutationVariables
>;

/**
 * __usePreOrderPostValidationMutation__
 *
 * To run a mutation, you first call `usePreOrderPostValidationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreOrderPostValidationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [preOrderPostValidationMutation, { data, loading, error }] = usePreOrderPostValidationMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function usePreOrderPostValidationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PreOrderPostValidationMutation,
    PreOrderPostValidationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PreOrderPostValidationMutation,
    PreOrderPostValidationMutationVariables
  >(PreOrderPostValidationDocument, options);
}
export type PreOrderPostValidationMutationHookResult = ReturnType<
  typeof usePreOrderPostValidationMutation
>;
export type PreOrderPostValidationMutationResult =
  Apollo.MutationResult<PreOrderPostValidationMutation>;
export type PreOrderPostValidationMutationOptions = Apollo.BaseMutationOptions<
  PreOrderPostValidationMutation,
  PreOrderPostValidationMutationVariables
>;
export const ProductDetailsDialogDocument = gql`
  query ProductDetailsDialog {
    productDetailsDialog @client {
      __typename
      active
      product
    }
  }
`;

/**
 * __useProductDetailsDialogQuery__
 *
 * To run a query within a React component, call `useProductDetailsDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductDetailsDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductDetailsDialogQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductDetailsDialogQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProductDetailsDialogQuery,
    ProductDetailsDialogQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductDetailsDialogQuery, ProductDetailsDialogQueryVariables>(
    ProductDetailsDialogDocument,
    options
  );
}
export function useProductDetailsDialogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductDetailsDialogQuery,
    ProductDetailsDialogQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductDetailsDialogQuery, ProductDetailsDialogQueryVariables>(
    ProductDetailsDialogDocument,
    options
  );
}
export type ProductDetailsDialogQueryHookResult = ReturnType<typeof useProductDetailsDialogQuery>;
export type ProductDetailsDialogLazyQueryHookResult = ReturnType<
  typeof useProductDetailsDialogLazyQuery
>;
export type ProductDetailsDialogQueryResult = Apollo.QueryResult<
  ProductDetailsDialogQuery,
  ProductDetailsDialogQueryVariables
>;
export const ProductFilterDocument = gql`
  query ProductFilter {
    productFilter @client {
      __typename
      active
      query
    }
  }
`;

/**
 * __useProductFilterQuery__
 *
 * To run a query within a React component, call `useProductFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductFilterQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductFilterQuery(
  baseOptions?: Apollo.QueryHookOptions<ProductFilterQuery, ProductFilterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductFilterQuery, ProductFilterQueryVariables>(
    ProductFilterDocument,
    options
  );
}
export function useProductFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductFilterQuery, ProductFilterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductFilterQuery, ProductFilterQueryVariables>(
    ProductFilterDocument,
    options
  );
}
export type ProductFilterQueryHookResult = ReturnType<typeof useProductFilterQuery>;
export type ProductFilterLazyQueryHookResult = ReturnType<typeof useProductFilterLazyQuery>;
export type ProductFilterQueryResult = Apollo.QueryResult<
  ProductFilterQuery,
  ProductFilterQueryVariables
>;
export const RemoveFromCartDocument = gql`
  mutation RemoveFromCart($companyId: Int!, $productCacheId: String!) {
    removeFromCart(companyId: $companyId, productCacheId: $productCacheId) @client
  }
`;
export type RemoveFromCartMutationFn = Apollo.MutationFunction<
  RemoveFromCartMutation,
  RemoveFromCartMutationVariables
>;

/**
 * __useRemoveFromCartMutation__
 *
 * To run a mutation, you first call `useRemoveFromCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFromCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFromCartMutation, { data, loading, error }] = useRemoveFromCartMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      productCacheId: // value for 'productCacheId'
 *   },
 * });
 */
export function useRemoveFromCartMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveFromCartMutation, RemoveFromCartMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveFromCartMutation, RemoveFromCartMutationVariables>(
    RemoveFromCartDocument,
    options
  );
}
export type RemoveFromCartMutationHookResult = ReturnType<typeof useRemoveFromCartMutation>;
export type RemoveFromCartMutationResult = Apollo.MutationResult<RemoveFromCartMutation>;
export type RemoveFromCartMutationOptions = Apollo.BaseMutationOptions<
  RemoveFromCartMutation,
  RemoveFromCartMutationVariables
>;
export const RemoveLocalCardDocument = gql`
  mutation RemoveLocalCard {
    removeLocalCard @client
  }
`;
export type RemoveLocalCardMutationFn = Apollo.MutationFunction<
  RemoveLocalCardMutation,
  RemoveLocalCardMutationVariables
>;

/**
 * __useRemoveLocalCardMutation__
 *
 * To run a mutation, you first call `useRemoveLocalCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLocalCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLocalCardMutation, { data, loading, error }] = useRemoveLocalCardMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveLocalCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveLocalCardMutation,
    RemoveLocalCardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveLocalCardMutation, RemoveLocalCardMutationVariables>(
    RemoveLocalCardDocument,
    options
  );
}
export type RemoveLocalCardMutationHookResult = ReturnType<typeof useRemoveLocalCardMutation>;
export type RemoveLocalCardMutationResult = Apollo.MutationResult<RemoveLocalCardMutation>;
export type RemoveLocalCardMutationOptions = Apollo.BaseMutationOptions<
  RemoveLocalCardMutation,
  RemoveLocalCardMutationVariables
>;
export const RemoveRemoteCardDocument = gql`
  mutation RemoveRemoteCard($cardId: Int!) {
    removeRemoteCard(cardId: $cardId) @client
  }
`;
export type RemoveRemoteCardMutationFn = Apollo.MutationFunction<
  RemoveRemoteCardMutation,
  RemoveRemoteCardMutationVariables
>;

/**
 * __useRemoveRemoteCardMutation__
 *
 * To run a mutation, you first call `useRemoveRemoteCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRemoteCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRemoteCardMutation, { data, loading, error }] = useRemoveRemoteCardMutation({
 *   variables: {
 *      cardId: // value for 'cardId'
 *   },
 * });
 */
export function useRemoveRemoteCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveRemoteCardMutation,
    RemoveRemoteCardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveRemoteCardMutation, RemoveRemoteCardMutationVariables>(
    RemoveRemoteCardDocument,
    options
  );
}
export type RemoveRemoteCardMutationHookResult = ReturnType<typeof useRemoveRemoteCardMutation>;
export type RemoveRemoteCardMutationResult = Apollo.MutationResult<RemoveRemoteCardMutation>;
export type RemoveRemoteCardMutationOptions = Apollo.BaseMutationOptions<
  RemoveRemoteCardMutation,
  RemoveRemoteCardMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation ResetPassword($email: String!) {
    resetPassword(email: $email) @client {
      success
      errorMsg
    }
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(
    ResetPasswordDocument,
    options
  );
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const ResetShoppingCartDocument = gql`
  mutation ResetShoppingCart($companyId: Int!) {
    resetShoppingCart(companyId: $companyId) @client
  }
`;
export type ResetShoppingCartMutationFn = Apollo.MutationFunction<
  ResetShoppingCartMutation,
  ResetShoppingCartMutationVariables
>;

/**
 * __useResetShoppingCartMutation__
 *
 * To run a mutation, you first call `useResetShoppingCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetShoppingCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetShoppingCartMutation, { data, loading, error }] = useResetShoppingCartMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useResetShoppingCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetShoppingCartMutation,
    ResetShoppingCartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetShoppingCartMutation, ResetShoppingCartMutationVariables>(
    ResetShoppingCartDocument,
    options
  );
}
export type ResetShoppingCartMutationHookResult = ReturnType<typeof useResetShoppingCartMutation>;
export type ResetShoppingCartMutationResult = Apollo.MutationResult<ResetShoppingCartMutation>;
export type ResetShoppingCartMutationOptions = Apollo.BaseMutationOptions<
  ResetShoppingCartMutation,
  ResetShoppingCartMutationVariables
>;
export const SaveShoppingCartToBrowserCacheDocument = gql`
  mutation SaveShoppingCartToBrowserCache($companyId: Int!) {
    saveShoppingCartToBrowserCache(companyId: $companyId) @client
  }
`;
export type SaveShoppingCartToBrowserCacheMutationFn = Apollo.MutationFunction<
  SaveShoppingCartToBrowserCacheMutation,
  SaveShoppingCartToBrowserCacheMutationVariables
>;

/**
 * __useSaveShoppingCartToBrowserCacheMutation__
 *
 * To run a mutation, you first call `useSaveShoppingCartToBrowserCacheMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveShoppingCartToBrowserCacheMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveShoppingCartToBrowserCacheMutation, { data, loading, error }] = useSaveShoppingCartToBrowserCacheMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useSaveShoppingCartToBrowserCacheMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveShoppingCartToBrowserCacheMutation,
    SaveShoppingCartToBrowserCacheMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveShoppingCartToBrowserCacheMutation,
    SaveShoppingCartToBrowserCacheMutationVariables
  >(SaveShoppingCartToBrowserCacheDocument, options);
}
export type SaveShoppingCartToBrowserCacheMutationHookResult = ReturnType<
  typeof useSaveShoppingCartToBrowserCacheMutation
>;
export type SaveShoppingCartToBrowserCacheMutationResult =
  Apollo.MutationResult<SaveShoppingCartToBrowserCacheMutation>;
export type SaveShoppingCartToBrowserCacheMutationOptions = Apollo.BaseMutationOptions<
  SaveShoppingCartToBrowserCacheMutation,
  SaveShoppingCartToBrowserCacheMutationVariables
>;
export const SelectCardDocument = gql`
  mutation SelectCard($remoteCard: RemoteCardInput, $localCard: LocalCardInput) {
    selectCard(remoteCard: $remoteCard, localCard: $localCard) @client
  }
`;
export type SelectCardMutationFn = Apollo.MutationFunction<
  SelectCardMutation,
  SelectCardMutationVariables
>;

/**
 * __useSelectCardMutation__
 *
 * To run a mutation, you first call `useSelectCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectCardMutation, { data, loading, error }] = useSelectCardMutation({
 *   variables: {
 *      remoteCard: // value for 'remoteCard'
 *      localCard: // value for 'localCard'
 *   },
 * });
 */
export function useSelectCardMutation(
  baseOptions?: Apollo.MutationHookOptions<SelectCardMutation, SelectCardMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SelectCardMutation, SelectCardMutationVariables>(
    SelectCardDocument,
    options
  );
}
export type SelectCardMutationHookResult = ReturnType<typeof useSelectCardMutation>;
export type SelectCardMutationResult = Apollo.MutationResult<SelectCardMutation>;
export type SelectCardMutationOptions = Apollo.BaseMutationOptions<
  SelectCardMutation,
  SelectCardMutationVariables
>;
export const SelectOrderTypeDocument = gql`
  mutation SelectOrderType($input: SelectedOrderTypeInput!) {
    selectedOrderType(input: $input) @client {
      ...selectedOrderType
    }
  }
  ${SelectedOrderTypeFragmentDoc}
`;
export type SelectOrderTypeMutationFn = Apollo.MutationFunction<
  SelectOrderTypeMutation,
  SelectOrderTypeMutationVariables
>;

/**
 * __useSelectOrderTypeMutation__
 *
 * To run a mutation, you first call `useSelectOrderTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectOrderTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectOrderTypeMutation, { data, loading, error }] = useSelectOrderTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSelectOrderTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SelectOrderTypeMutation,
    SelectOrderTypeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SelectOrderTypeMutation, SelectOrderTypeMutationVariables>(
    SelectOrderTypeDocument,
    options
  );
}
export type SelectOrderTypeMutationHookResult = ReturnType<typeof useSelectOrderTypeMutation>;
export type SelectOrderTypeMutationResult = Apollo.MutationResult<SelectOrderTypeMutation>;
export type SelectOrderTypeMutationOptions = Apollo.BaseMutationOptions<
  SelectOrderTypeMutation,
  SelectOrderTypeMutationVariables
>;
export const SelectWalletDocument = gql`
  mutation SelectWallet($cardBrand: String!) {
    selectWallet(cardBrand: $cardBrand) @client
  }
`;
export type SelectWalletMutationFn = Apollo.MutationFunction<
  SelectWalletMutation,
  SelectWalletMutationVariables
>;

/**
 * __useSelectWalletMutation__
 *
 * To run a mutation, you first call `useSelectWalletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectWalletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectWalletMutation, { data, loading, error }] = useSelectWalletMutation({
 *   variables: {
 *      cardBrand: // value for 'cardBrand'
 *   },
 * });
 */
export function useSelectWalletMutation(
  baseOptions?: Apollo.MutationHookOptions<SelectWalletMutation, SelectWalletMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SelectWalletMutation, SelectWalletMutationVariables>(
    SelectWalletDocument,
    options
  );
}
export type SelectWalletMutationHookResult = ReturnType<typeof useSelectWalletMutation>;
export type SelectWalletMutationResult = Apollo.MutationResult<SelectWalletMutation>;
export type SelectWalletMutationOptions = Apollo.BaseMutationOptions<
  SelectWalletMutation,
  SelectWalletMutationVariables
>;
export const SetGmapsReadyDocument = gql`
  mutation SetGmapsReady($ready: Boolean!) {
    setGmapsReady(ready: $ready) @client
  }
`;
export type SetGmapsReadyMutationFn = Apollo.MutationFunction<
  SetGmapsReadyMutation,
  SetGmapsReadyMutationVariables
>;

/**
 * __useSetGmapsReadyMutation__
 *
 * To run a mutation, you first call `useSetGmapsReadyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetGmapsReadyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setGmapsReadyMutation, { data, loading, error }] = useSetGmapsReadyMutation({
 *   variables: {
 *      ready: // value for 'ready'
 *   },
 * });
 */
export function useSetGmapsReadyMutation(
  baseOptions?: Apollo.MutationHookOptions<SetGmapsReadyMutation, SetGmapsReadyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetGmapsReadyMutation, SetGmapsReadyMutationVariables>(
    SetGmapsReadyDocument,
    options
  );
}
export type SetGmapsReadyMutationHookResult = ReturnType<typeof useSetGmapsReadyMutation>;
export type SetGmapsReadyMutationResult = Apollo.MutationResult<SetGmapsReadyMutation>;
export type SetGmapsReadyMutationOptions = Apollo.BaseMutationOptions<
  SetGmapsReadyMutation,
  SetGmapsReadyMutationVariables
>;
export const SetScrollToProductDocument = gql`
  mutation SetScrollToProduct($productId: String!, $value: Boolean!) {
    setScrollToProduct(productId: $productId, value: $value) @client
  }
`;
export type SetScrollToProductMutationFn = Apollo.MutationFunction<
  SetScrollToProductMutation,
  SetScrollToProductMutationVariables
>;

/**
 * __useSetScrollToProductMutation__
 *
 * To run a mutation, you first call `useSetScrollToProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetScrollToProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setScrollToProductMutation, { data, loading, error }] = useSetScrollToProductMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetScrollToProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetScrollToProductMutation,
    SetScrollToProductMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetScrollToProductMutation, SetScrollToProductMutationVariables>(
    SetScrollToProductDocument,
    options
  );
}
export type SetScrollToProductMutationHookResult = ReturnType<typeof useSetScrollToProductMutation>;
export type SetScrollToProductMutationResult = Apollo.MutationResult<SetScrollToProductMutation>;
export type SetScrollToProductMutationOptions = Apollo.BaseMutationOptions<
  SetScrollToProductMutation,
  SetScrollToProductMutationVariables
>;
export const SetUserAddressDocument = gql`
  mutation SetUserAddress($input: UserAddressInput!) {
    userAddress(input: $input) @client {
      ...userAddress
    }
  }
  ${UserAddressFragmentDoc}
`;
export type SetUserAddressMutationFn = Apollo.MutationFunction<
  SetUserAddressMutation,
  SetUserAddressMutationVariables
>;

/**
 * __useSetUserAddressMutation__
 *
 * To run a mutation, you first call `useSetUserAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserAddressMutation, { data, loading, error }] = useSetUserAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetUserAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<SetUserAddressMutation, SetUserAddressMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetUserAddressMutation, SetUserAddressMutationVariables>(
    SetUserAddressDocument,
    options
  );
}
export type SetUserAddressMutationHookResult = ReturnType<typeof useSetUserAddressMutation>;
export type SetUserAddressMutationResult = Apollo.MutationResult<SetUserAddressMutation>;
export type SetUserAddressMutationOptions = Apollo.BaseMutationOptions<
  SetUserAddressMutation,
  SetUserAddressMutationVariables
>;
export const SetDeviceMetadataDocument = gql`
  mutation SetDeviceMetadata($input: DeviceMetadataInput!) {
    deviceMetadata(input: $input) @client
  }
`;
export type SetDeviceMetadataMutationFn = Apollo.MutationFunction<
  SetDeviceMetadataMutation,
  SetDeviceMetadataMutationVariables
>;

/**
 * __useSetDeviceMetadataMutation__
 *
 * To run a mutation, you first call `useSetDeviceMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDeviceMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDeviceMetadataMutation, { data, loading, error }] = useSetDeviceMetadataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetDeviceMetadataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetDeviceMetadataMutation,
    SetDeviceMetadataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetDeviceMetadataMutation, SetDeviceMetadataMutationVariables>(
    SetDeviceMetadataDocument,
    options
  );
}
export type SetDeviceMetadataMutationHookResult = ReturnType<typeof useSetDeviceMetadataMutation>;
export type SetDeviceMetadataMutationResult = Apollo.MutationResult<SetDeviceMetadataMutation>;
export type SetDeviceMetadataMutationOptions = Apollo.BaseMutationOptions<
  SetDeviceMetadataMutation,
  SetDeviceMetadataMutationVariables
>;
export const SignUpDocument = gql`
  mutation signUp($input: SignUpInput!) {
    signUp(input: $input) @client {
      success
      errorMsg
    }
  }
`;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpMutation(
  baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
}
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<
  SignUpMutation,
  SignUpMutationVariables
>;
export const UpdateCustomerDataDocument = gql`
  mutation UpdateCustomerData($input: CustomerDataInput!) {
    updateCustomerData(input: $input) @client {
      ...customerData
    }
  }
  ${CustomerDataFragmentDoc}
`;
export type UpdateCustomerDataMutationFn = Apollo.MutationFunction<
  UpdateCustomerDataMutation,
  UpdateCustomerDataMutationVariables
>;

/**
 * __useUpdateCustomerDataMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerDataMutation, { data, loading, error }] = useUpdateCustomerDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCustomerDataMutation,
    UpdateCustomerDataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCustomerDataMutation, UpdateCustomerDataMutationVariables>(
    UpdateCustomerDataDocument,
    options
  );
}
export type UpdateCustomerDataMutationHookResult = ReturnType<typeof useUpdateCustomerDataMutation>;
export type UpdateCustomerDataMutationResult = Apollo.MutationResult<UpdateCustomerDataMutation>;
export type UpdateCustomerDataMutationOptions = Apollo.BaseMutationOptions<
  UpdateCustomerDataMutation,
  UpdateCustomerDataMutationVariables
>;
export const ValidateProductDocument = gql`
  mutation ValidateProduct($productCacheId: String!) {
    validateProduct(productCacheId: $productCacheId) @client
  }
`;
export type ValidateProductMutationFn = Apollo.MutationFunction<
  ValidateProductMutation,
  ValidateProductMutationVariables
>;

/**
 * __useValidateProductMutation__
 *
 * To run a mutation, you first call `useValidateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateProductMutation, { data, loading, error }] = useValidateProductMutation({
 *   variables: {
 *      productCacheId: // value for 'productCacheId'
 *   },
 * });
 */
export function useValidateProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ValidateProductMutation,
    ValidateProductMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ValidateProductMutation, ValidateProductMutationVariables>(
    ValidateProductDocument,
    options
  );
}
export type ValidateProductMutationHookResult = ReturnType<typeof useValidateProductMutation>;
export type ValidateProductMutationResult = Apollo.MutationResult<ValidateProductMutation>;
export type ValidateProductMutationOptions = Apollo.BaseMutationOptions<
  ValidateProductMutation,
  ValidateProductMutationVariables
>;
