import React, { useCallback, useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { RouteComponentProps, withRouter } from 'react-router';
import { Grid, Typography, Avatar, Fade, Box } from '@material-ui/core';

import {
  Company,
  OnlineStatus,
  OrderTypeParameter,
  CompanyHighlight,
  ShippingPrice,
} from '../../../common/generated/graphql';
import CompanyPreparationTime from '../../../common/components/company-preparation-time/company-preparation-time';
import { parseImage } from '../../../common/helpers/parse-image';
import { getStatusText } from '../../../common/helpers/get-status-text.helper';
import { useStyles } from './aggregator-company-list-item.style';
import Icon from '../../../common/components/icon/icon';
import AggregatorsChipHighlight from '../aggregators-chip-highlight/aggregators-chip-highlight';
import { getOrderTypeConfig } from '../../helpers/get-order-type-config.helper';
import { getHighlightMessage } from '../../../common/helpers/get-highlight-message';
import ListItemDeliveryInfo from '../list-item-delivery-info/list-item-delivery-info';
import { EventConfirmationModalContext } from '../../contexts/eventConfirmationModal';
interface Props
  extends RouteComponentProps<{
    aggregatorId: string;
    numericalId: string;
  }> {
  company: Company;
  groupName?: string | null;
  isDelivery?: boolean;
  isEvent?: boolean;
}

// @todo gerar nova graphql-scheama
export default withRouter(AggregatorCompanyListItem);

function AggregatorCompanyListItem(props: Props) {
  const classes = useStyles();
  const eventConfirmationModal = useContext(EventConfirmationModalContext);
  const [deliveryValue, setDeliveryValue] = useState<ShippingPrice | undefined>();

  const aggregatorId = props.match.params.aggregatorId;
  const numericalId = props.match.params.numericalId;
  const brandId = props.company.brand!.numericalId!;
  const companyId = props.company.numericalId!;
  const deliveryData = props.company.parameter?.orderTypes?.find((item) =>
    item?.additionalChargeParameter?.find((additional) => additional?.additionalType === 'DELIVERY')
  );
  const additionalDelivery = deliveryData?.additionalChargeParameter?.find(
    (additional) => additional?.additionalType === 'DELIVERY'
  );

  let deliveyTypeConfig: OrderTypeParameter | null | undefined;

  const statusText = getStatusText(props.company.onlineStatus);

  let highlightText = getHighlightMessage(props?.company?.highlight as CompanyHighlight);
  const onlineCompany = props.company.onlineStatus === OnlineStatus.Online;

  if (props.isDelivery) {
    deliveyTypeConfig = getOrderTypeConfig({
      orderTypes: props.company?.parameter?.orderTypes,
      type: 'delivery',
    });
  }

  const setDeliveryStorage = useCallback(
    (shipping?: ShippingPrice) => {
      if (shipping?.value && shipping?.label) {
        const deliveryStruct = shipping
          ? {
              type: 'delivery',
              id: companyId,
              key: 'additional',
              optional: false,
              label: shipping.label,
              value: shipping.value,
            }
          : undefined;

        window.localStorage.setItem('selectedDelivery', JSON.stringify(deliveryStruct));
      }
    },
    [companyId]
  );

  const onCompanyClicked = useCallback(
    (value?: ShippingPrice) => {
      setDeliveryStorage(value);

      props.history.push(`/${aggregatorId}/${brandId}/${companyId}/company`, {
        agregatorDetailId: numericalId,
        from: props.history.location.pathname,
      });
    },
    [props.history, aggregatorId, brandId, companyId, numericalId, setDeliveryStorage]
  );

  const handleCompanyComfirm = (value?: ShippingPrice) => {
    setDeliveryStorage(value);
    eventConfirmationModal.setCallbackConfirm(() => onCompanyClicked);
    eventConfirmationModal.onShowModal();
  };

  useEffect(() => {
    if (props.company.isFullService && props.company.shippingPrice?.value) {
      setDeliveryValue(props.company.shippingPrice);
    } else if (additionalDelivery?.additionalValue) {
      const shippingPrice: ShippingPrice = {
        value: additionalDelivery?.additionalValue,
      };

      setDeliveryValue(shippingPrice);
    }
  }, [additionalDelivery, props.company.isFullService, props.company.shippingPrice]);

  return (
    <Fade in={true} timeout={500}>
      <Box>
        <Grid
          id='company-item'
          container
          wrap='nowrap'
          className={classes.root}
          onClick={
            props.isEvent
              ? () => handleCompanyComfirm(deliveryValue)
              : () => onCompanyClicked(deliveryValue)
          }
        >
          <Grid item>
            <Avatar
              id='company-logo'
              src={parseImage(props.company.brand!.images, 'brand-aggregator-company-list')}
              alt={props?.company?.name ?? 'loja'}
              className={clsx(classes.listItemAvatar, {
                [classes.fadedImage]: !onlineCompany,
              })}
            >
              {props?.company?.displayName?.trimLeft()?.charAt(0)}
            </Avatar>
          </Grid>
          <Grid item container zeroMinWidth className={classes.cardContent}>
            <Typography
              noWrap
              variant='h6'
              className={clsx(classes.cardText, classes.listItemTextTitle, {
                [classes.listItemTextGray]: !onlineCompany,
              })}
            >
              {props.company.displayName}
            </Typography>
            <Grid item container>
              <Grid item container className={classes.ContentInfo}>
                <Icon name={onlineCompany ? 'openDoor' : 'closedDoor'} />
                <Typography
                  id='status-text'
                  component='span'
                  variant='body2'
                  className={clsx(classes.cardText, classes.listItemTextOpen, {
                    [classes.listItemTextClose]: !onlineCompany,
                  })}
                >
                  {statusText}
                </Typography>
                <CompanyPreparationTime
                  preparationTime={props.company.preparationTime}
                  additionalPreparationTime={deliveyTypeConfig?.additionalPreparationTime}
                  isDelivery={props.isDelivery}
                />
              </Grid>
              {props.isDelivery && <ListItemDeliveryInfo additionalDeliveryValue={deliveryValue} />}
            </Grid>
            <Grid item container zeroMinWidth>
              <AggregatorsChipHighlight
                status={props.company.onlineStatus}
                timeRules={props.company.openingTimes}
                highlightText={highlightText}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Fade>
  );
}
