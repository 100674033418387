import { SharedConfig } from '../../../common/model/config';

export const productionSharedConfig: SharedConfig = {
  timeout: 30000,
  pollInterval: 15000,
  cacheDurationMinutes: 120,
  sentryDSN: 'https://da8204d4bbf84ec494494610eaf28e73@sentry.monitor.onyo.com/18',
  maximumDistanceKilometers: 20,
  companyPaginationStep: 6,
  numberColumnsBreakPointsAggregator: {
    xs: 12,
    md: 6,
    lg: 4,
  },
};
